import React from 'react';
import {
      AvailabelHoteContainer,
      AvailabelHoteTitle,
      AvailabelHotelContent,
      AvailabelHotelItem
} from '../../hotel/components/AvailabelHotel/styles';
import { Col } from 'react-bootstrap';
import { FlightContent, FlightIcon, FlightItem } from './styles';

const flights = [
      {
            id: '1',
            title: 'Delhi to Mumbai (DEL-BOM)',
            date: 'Tue 23 Jan 2024'
      },
      {
            id: '1',
            title: 'Delhi to Mumbai (DEL-BOM)',
            date: 'Tue 23 Jan 2024'
      },
      {
            id: '1',
            title: 'Delhi to Mumbai (DEL-BOM)',
            date: 'Tue 23 Jan 2024'
      },
      {
            id: '1',
            title: 'Delhi to Mumbai (DEL-BOM)',
            date: 'Tue 23 Jan 2024'
      },
      {
            id: '1',
            title: 'Delhi to Mumbai (DEL-BOM)',
            date: 'Tue 23 Jan 2024'
      },
      {
            id: '1',
            title: 'Delhi to Mumbai (DEL-BOM)',
            date: 'Tue 23 Jan 2024'
      }
];

const AvailabelFlights = () => {
      return (
            <>
                  <AvailabelHoteContainer>
                        <AvailabelHoteTitle>
                              <h2>Top Flight Routes</h2>
                        </AvailabelHoteTitle>
                        <AvailabelHotelContent>
                              <AvailabelHotelItem>
                                    {flights.map((item, index) => (
                                          <Col
                                                lg={6}
                                                key={index}
                                                className="gap-5"
                                          >
                                                <FlightItem>
                                                      <FlightContent>
                                                            <h3>
                                                                  {item.title}
                                                            </h3>
                                                            <p>{item.date}</p>
                                                      </FlightContent>
                                                      <FlightIcon>
                                                            <span className="material-symbols-outlined">
                                                                  airplane_ticket
                                                            </span>
                                                      </FlightIcon>
                                                </FlightItem>
                                          </Col>
                                    ))}
                              </AvailabelHotelItem>
                        </AvailabelHotelContent>
                  </AvailabelHoteContainer>
            </>
      );
};

export default AvailabelFlights;
