import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { PopupIcon, PopupIconItem, PopupTitle } from './styles';
import { Link } from 'react-router-dom/dist';

const PopupComponent = () => {
      const [showPopup, setShowPopup] = useState(false);

      // useEffect(() => {
      //       const delayTime = 12000; // 1-2 minutes in milliseconds
      //       const timeoutId = setTimeout(() => {
      //             setShowPopup(true);
      //       }, delayTime);

      //       return () => clearTimeout(timeoutId);
      // }, []); // Empty dependency array ensures the effect runs only once on mount

      const closePopup = () => {
            setShowPopup(false);
      };

      return (
            <>
                  <Modal show={showPopup} onHide={closePopup}>
                        <Modal.Body>
                              <PopupTitle>Get Connected with us on:</PopupTitle>
                              <PopupIcon className="d-flex gap-4 align-items-center mt-4">
                                    <PopupIconItem>
                                          <Link to="tel:+91 98212 13350"></Link>
                                          <img
                                                src="images/img/testimonial/whatsapp.webp"
                                                alt=""
                                                width={'100'}
                                                height={'100%'}
                                          />
                                    </PopupIconItem>
                                    <PopupIconItem>
                                          <Link to="mailto:info@aircasa.in ">
                                                <img
                                                      src="/images/img/testimonial/gmail.png"
                                                      alt=""
                                                      width={'150'}
                                                      height={'100%'}
                                                />
                                          </Link>
                                    </PopupIconItem>
                              </PopupIcon>
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default PopupComponent;
