import React from 'react';
import { SocialMediaContainer } from './styles';
import SocialMediaLink from './SocialMediaLink';
import FacebookIcon from '../../icons/SocialMediaIcons/FacebookIcon';
import InstagramIcon from '../../icons/SocialMediaIcons/InstagramIcon';
import TwitterIcon from '../../icons/SocialMediaIcons/TwitterIcon';
import LinkedinIcon from '../../icons/SocialMediaIcons/LinkedinIcon';
import WhatsappIcon from '../../icons/SocialMediaIcons/WhatsappIcon';

const SocialMedia = ({ color, generalInformation }) => {
      return (
            <>
                  <SocialMediaContainer className="d-flex align-items-center gap-3">
                        <SocialMediaLink
                              color={color || ''}
                              link={generalInformation.facebook_link}
                              icon={<FacebookIcon />}
                        />
                        <SocialMediaLink
                              color={color || ''}
                              link={generalInformation.instagram_link}
                              icon={<InstagramIcon />}
                        />
                        <SocialMediaLink
                              color={color || ''}
                              link={generalInformation.twitter_link}
                              icon={<TwitterIcon />}
                        />
                        <SocialMediaLink
                              link={generalInformation.linkedin}
                              icon={<LinkedinIcon />}
                        />
                        <SocialMediaLink
                              link={generalInformation.whatsapp}
                              icon={<WhatsappIcon />}
                        />
                  </SocialMediaContainer>
            </>
      );
};

export default SocialMedia;
