import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

export const FaqAccordionContainer = styled.div``;

export const FaqAccordionTitle = styled.h4`
      margin-bottom: 20px;
`;
export const FaqAccordionContent = styled(Accordion)`
      .accordion-item {
            background-color: ${(props) => props.theme.cloudColor};
            margin-bottom: 20px;
            border: 0;

            &:last-child {
                  margin-bottom: 0;
            }
      }

      .accordion-button:not(.collapsed) {
            box-shadow: none;
      }

      .accordion-button {
            background-color: ${(props) => props.theme.cloudColor};
      }
      .accordion-button::after {
            flex-shrink: 0;
            width: 0px;
            height: 0px;
            margin-left: auto;
            content: '';
            background-color: #1d1d1d;
            background-repeat: no-repeat;
            background-size: var(--bs-accordion-btn-icon-width);
            transition: var(--bs-accordion-btn-icon-transition);
      }
      .accordion-button::before {
            position: absolute;
            top: 25px;
            right: 15px;
            flex-shrink: 0;
            width: 0px;
            height: 0px;
            margin-left: auto;
            content: '';
            background-color: #1d1d1d;
            background-repeat: no-repeat;
            background-size: var(--bs-accordion-btn-icon-width);
            transition: var(--bs-accordion-btn-icon-transition);
      }
      .accordion-button:not(.collapsed)::after {
            background-image: var(--bs-accordion-btn-active-icon);
            transform: rotate(90deg);
            height: 14px;
            background-color: ${(props) => props.theme.primary};
      }
      .accordion-button:not(.collapsed)::before {
            background-color: ${(props) => props.theme.primary};
      }
      .accordion-button:not(.collapsed) {
            box-shadow: none;
            color: ${(props) => props.theme.primary};
      }
      .accordion-button:focus {
            z-index: 3;
            border-color: transparent;
            outline: 0;
            box-shadow: none;
      }

      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
            margin-right: 10px;
      }
`;

export const FaqAccordionItem = styled.div`
      margin-bottom: 40px;
`;

export const WidgetContainer = styled.div`
      top: 90px;
`;
