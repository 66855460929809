import React from 'react';
import {
      WidgetBlogItem,
      WidgetBlogItemImage,
      WidgetBlogItemInfo
} from './style';
import { Link } from 'react-router-dom';

const WidgetBlogBox = ({ blog }) => {
      return (
            <>
                  <WidgetBlogItem>
                        <WidgetBlogItemImage>
                              <Link to={`/blogs/${blog.slug}`}>
                                    <img src={blog.image} alt={blog.title} />
                              </Link>
                        </WidgetBlogItemImage>
                        <WidgetBlogItemInfo>
                              <Link to={`/blogs/${blog.slug}`}>
                                    {blog.title}
                              </Link>
                              <span>{blog.date}</span>
                        </WidgetBlogItemInfo>
                  </WidgetBlogItem>
            </>
      );
};

export default WidgetBlogBox;
