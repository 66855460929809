import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../../components/common/BreadcrumbBox';
import { BlogContainer } from '../../blogs/styles';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router';
import { DestinationContainer } from '../../destination_details/styles';
import {
      FiveImageLayout,
      PackageLayout,
      ThreeImageLayout
} from '../../../components/frontend/experiences';

const ExperienceShow = () => {
      const { experienceId } = useParams();
      const [loading, setLoading] = useState(false);
      const [experience, setExperience] = useState([]);
      const [collections, setCollections] = useState([]);
      const [packages, setPackages] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/experiences/${experienceId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setExperience(response.data.experience);
                              setCollections(
                                    response.data.experience.collections
                              );
                              setPackages(response.data.experience.packages);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [experienceId]);

      return (
            <>
                  <BreadcrumbBox
                        title={experience.title}
                        image={experience.banner_image}
                        loading={loading}
                  />

                  <DestinationContainer>
                        <Container>
                              {!loading
                                    ? collections?.map((item, index) =>
                                            item?.type ===
                                            'Three Image Layout' ? (
                                                  <ThreeImageLayout
                                                        item={item}
                                                        key={index}
                                                  />
                                            ) : (
                                                  <FiveImageLayout
                                                        item={item}
                                                        key={index}
                                                  />
                                            )
                                      )
                                    : null}
                        </Container>
                  </DestinationContainer>
                  {!loading && packages && (
                        <BlogContainer>
                              <Container>
                                    <PackageLayout items={packages} />
                              </Container>
                        </BlogContainer>
                  )}
            </>
      );
};

export default ExperienceShow;
