import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
 @font-face {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    src: url('/font/dt-icons.woff2') format('woff2');
  }
  
* {
      padding: 0;
       margin: 0;
      box-sizing: border-box;
      font-family: 'Mansalva', sans-serif;
      font-family: 'Poppins', sans-serif;
      font-family: 'Jost', Sans-serif;
      font-family: 'Dancing Script', cursive;
      font-family: 'Playfair Display', serif;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
:root {
      
      --logo__color: #1d1d1b;
      --secondary__color: #eaaa02;
      --primary__font: "Helvetica Neue", Helvetica, Arial, sans-serif;
      --secondary__font:  'Mansalva', sans-serif;
      --dancing__font: 'Dancing Script', cursive;
      --playfair__font: 'Playfair Display', serif;;
      --swiper-navigation-size: 31px !important;
}
a {
    text-decoration: none !important;
}
`;
