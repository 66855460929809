import React from 'react';
import {
      DropDownContainer,
      DropDownImage,
      DropDownList,
      DropdownItem,
      DropdownSubCategory,
} from './styles';
import {  Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Destination from '../Destination';

const DropDownBox = ({ categories, loading }) => {
      return (
            <>
                  <DropDownContainer>
                        <DropDownList>
                              <Row>
                                    {categories?.length > 0 &&
                                          categories.map((item, index) => (
                                                <DropdownItem
                                                      lg={
                                                            item.title ===
                                                            'International'
                                                                  ? 8
                                                                  : 4
                                                      }
                                                      key={index}
                                                      border={
                                                            item.title ===
                                                            'International'
                                                                  ? '1px dashed'
                                                                  : ''
                                                      }
                                                      padding={
                                                            item.title ===
                                                            'International'
                                                                  ? '24px'
                                                                  : ''
                                                      }
                                                >
                                                      <DropdownSubCategory>
                                                            <Link
                                                                  to={`/destinations/${item.slug}/category`}
                                                            >
                                                                  {item.image && (
                                                                        <DropDownImage>
                                                                              <img
                                                                                    src={
                                                                                          item.image
                                                                                    }
                                                                                    alt={
                                                                                          item.title
                                                                                    }
                                                                              />
                                                                        </DropDownImage>
                                                                  )}
                                                                  {item.title}
                                                            </Link>
                                                      </DropdownSubCategory>
                                                      <Destination
                                                            item={item}
                                                      />
                                                </DropdownItem>
                                          ))}
                              </Row>
                        </DropDownList>
                  </DropDownContainer>
            </>
      );
};

export default DropDownBox;
