import React, { useEffect, useState } from 'react';
import { AbroadContainer } from './styles';
import { Container, Row } from 'react-bootstrap';
import SubTitle from '../HomeTitle/SubTitle';
import MainTitle from '../HomeTitle/MainTitle';
import ApplicationItem from '../DomesticDestinationBox/components/ApplicationItem';

import { SwiperSlide, Swiper } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import MoreButton from '../../../common/MoreButton';
import axios from 'axios';

const AbroadSection = ({ mainTitle }) => {
      const [loading, setLoading] = useState(false);
      const [destinations, setDestinations] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/destinations/international/category`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDestinations(response.data.destinations);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <AbroadContainer className="text-center">
                  <Container>
                        <SubTitle
                              subtitle={`${
                                    mainTitle
                                          ? 'Abroad Destination'
                                          : 'Our Destination'
                              } `}
                              center="center"
                              right="-12px"
                              left="-12px"
                              size={`${mainTitle ? '32px' : ''}`}
                        />
                        {!mainTitle && (
                              <MainTitle
                                    title={'Abroad Destination'}
                                    center="100%"
                                    left="-12px"
                              />
                        )}
                        <Row className="mt-5">
                              <Swiper
                                    spaceBetween={30}
                                    slidesPerView={1}
                                    navigation={true}
                                    autoplay={{
                                          delay: 2500,
                                          disableOnInteraction: false
                                    }}
                                    breakpoints={{
                                          640: {
                                                slidesPerView: 1,
                                                spaceBetween: 20
                                          },
                                          768: {
                                                slidesPerView: 3,
                                                spaceBetween: 20
                                          },
                                          1024: {
                                                slidesPerView: 4,
                                                spaceBetween: 30
                                          }
                                    }}
                                    modules={[Autoplay, Navigation]}
                                    className="mySwiper"
                              >
                                    {destinations?.length &&
                                          destinations
                                                .slice(0, 8)
                                                ?.map((application, index) => (
                                                      <SwiperSlide key={index}>
                                                            <ApplicationItem
                                                                  title={
                                                                        application.title
                                                                  }
                                                                  image={
                                                                        application.image
                                                                  }
                                                                  link={`/destinations/${application.slug}`}
                                                            />
                                                      </SwiperSlide>
                                                ))}
                                    <MoreButton
                                          title={'Explore More'}
                                          slug="/destinations/international/category"
                                    />
                              </Swiper>
                        </Row>
                  </Container>
            </AbroadContainer>
      );
};

export default AbroadSection;
