import React, { useContext, useEffect, useState } from 'react';
import {
      NavLogo,
      NavigationContainer,
      NavigationLogo,
      NavigationMenu
} from './styles';
import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';
import MobileMenu from './MobileMenu';
import DropDownBox from '../../../../components/frontend/layout/header/DropDown';
import axios from 'axios';
import Experience from '../../../../components/frontend/layout/header/Expereince';
import LogoContext from '../../../../stores/LogoContext';

const Navigation = ({ isSticky }) => {
      const [categories, setCategories] = useState([]);
      const [loading, setLoading] = useState(false);

      const logoCtx = useContext(LogoContext);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/destinations`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <NavigationContainer
                        className={`${isSticky ? 'sticky' : ''} `}
                  >
                        <NavigationLogo height={isSticky ? '150px' : '280px'}>
                              <Link to="/">
                                    <img
                                          src={
                                                isSticky
                                                      ? logoCtx.setting.logo
                                                      : logoCtx.setting
                                                              .white_logo
                                          }
                                          alt="Aircasa Official Logo"
                                    />
                              </Link>
                        </NavigationLogo>
                        <NavLogo width={'120px'} height="30px">
                              <Link to="/">
                                    <img
                                          src={logoCtx.setting.color_gif}
                                          alt="Aircasa Official Logo"
                                    />
                              </Link>
                        </NavLogo>
                        <nav>
                              <NavigationMenu
                                    className="list-inline ms-0 ps-0"
                                    color={isSticky || ''}
                              >
                                    <MenuItem title="Home" link="/" />
                                    <MenuItem
                                          title="Destination"
                                          link="/destinations"
                                    >
                                          <DropDownBox
                                                loading={loading}
                                                categories={categories}
                                          />
                                    </MenuItem>
                                    <MenuItem
                                          title="Experiences"
                                          link="/experiences"
                                    >
                                          {logoCtx.experiences?.length > 0 && (
                                                <Experience
                                                      experiences={
                                                            logoCtx.experiences
                                                      }
                                                />
                                          )}
                                    </MenuItem>
                                    <MenuItem title="Hotels" link="/hotels" />
                                    <MenuItem title="Flights" link="/flights" />
                                    <MenuItem
                                          title="My Booking"
                                          link="/my-booking"
                                    />
                              </NavigationMenu>
                        </nav>
                        <MobileMenu
                              experiences={logoCtx.experiences}
                              loading={loading}
                              categories={categories}
                        />
                  </NavigationContainer>
            </>
      );
};

export default Navigation;
