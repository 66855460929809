import React from 'react';
import { EnquiryFormContainer } from '../../../common/EnquiryForm/styles';
import { Form } from 'react-bootstrap';
import CommonButton from '../../../common/CommonButton';

const EnquiryForm = ({
      setName,
      setEmail,
      setMessage,
      setMobileNumber,
      loading,
      handleForm
}) => {
      return (
            <>
                  <EnquiryFormContainer
                        className="mt-0 mb-0 border-0"
                        style={{ boxShadow: 'none' }}
                        status={true}
                        padding="10px"
                  >
                        <Form onSubmit={handleForm}>
                              <Form.Group className="mb-3">
                                    <Form.Label>Name </Form.Label>
                                    <Form.Control
                                          type="text"
                                          onChange={(event) =>
                                                setName(event.target.value)
                                          }
                                    />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                    <Form.Label>Mobile </Form.Label>
                                    <Form.Control
                                          type="text"
                                          onChange={(event) =>
                                                setMobileNumber(
                                                      event.target.value
                                                )
                                          }
                                    />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                    <Form.Label>Email </Form.Label>
                                    <Form.Control
                                          type="email"
                                          onChange={(event) =>
                                                setEmail(event.target.value)
                                          }
                                    />
                              </Form.Group>
                              <Form.Group className="mb-3">
                                    <Form.Label>
                                          Any Query (optional)
                                    </Form.Label>
                                    <Form.Control
                                          as="textarea"
                                          rows={4}
                                          onChange={(event) =>
                                                setMessage(event.target.value)
                                          }
                                    />
                              </Form.Group>
                              <CommonButton
                                    title={'Submit'}
                                    loading={loading}
                                    button={true}
                              />
                        </Form>
                  </EnquiryFormContainer>
            </>
      );
};

export default EnquiryForm;
