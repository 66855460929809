export const lightTheme = {
      body: '#212529',
      primary: '#224082',
      secondary: '#F29727',
      white: '#FFFFFF',
      black: '#000000',
      background: '#F4F3F8',
      headerBackground: '#161921',
      titleColor: '#161921',
      paragraphColor: '#687179',
      fontFamily: "'Poppins', sans-serif",
      ratingColor: '#FFC83E',
      cloudColor: '#EBF7FF'
};

export const DarkTheme = {
      body: '#000000',
      text: '#FCF6F4',
      fontFamily: "'Poppins', sans-serif",
      textRgba: '252, 246, 244',
      bodyRgba: '0,0,0'
};
