// App.js
import React, { useState } from 'react';
import DataList from './DataList';

const DataShow = () => {
  const [dataList, setDataList] = useState(['Data 1', 'Data 2', 'Data 3']);
  const [newData, setNewData] = useState('');

  const handleAddData = () => {
    if (newData.trim() !== '') {
      setDataList([...dataList, newData]);
      setNewData(''); // Clear the input after adding data
    }
  };

  return (
    <div>
      <input
        type="text"
        value={newData}
        onChange={(e) => setNewData(e.target.value)}
      />
      <button onClick={handleAddData}>Add Data</button>

      <DataList dataList={dataList} />
    </div>
  );
};

export default DataShow;
