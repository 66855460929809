import React, { useState } from 'react';
import { HotelContainer, HotelSection } from './styles';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { AvailabelHotel, HotelChains } from './components';
import EnquiryBanner from '../../components/common/EnquiryBanner';

import $ from 'jquery';
import axios from 'axios';
import toast from 'react-hot-toast';

const HotelPage = () => {
      const [loading, setLoading] = useState(false);
      const [from, setFrom] = useState('');
      const [destination, setDestination] = useState('');
      const [checkin, setCheckin] = useState('');
      const [checkout, setCheckout] = useState('');
      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [mobileNumber, setMobileNumber] = useState('');
      const [message, setMessage] = useState('');
      const [show, setShow] = useState(false);

      const [travelers, setTravelers] = useState([
            {
                  room: '1',
                  children: '0',
                  adult: '1',
                  childrenAges: []
            }
      ]);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  destination: destination,
                  checkin: checkin,
                  checkout: checkout,
                  name: name,
                  email: email,
                  mobile_number: mobileNumber,
                  message: message,
                  travelers: travelers,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/hotels`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setDestination('');
                              setFrom('');
                              setCheckin('');
                              setCheckout('');
                              setTravelers([
                                    {
                                          room: '1',
                                          children: '0',
                                          adult: '1',
                                          childrenAges: []
                                    }
                              ]);
                              setTimeout(() => {
                                    setShow(false);
                              }, 1500);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.travelers) {
                                    toast.error(response.data.message.travelers);
                              }
                              if (response.data.message.destination) {
                                    toast.error(
                                          response.data.message.destination
                                    );
                              }
                              if (response.data.message.checkin) {
                                    toast.error(response.data.message.checkin);
                              }
                              if (response.data.message.checkout) {
                                    toast.error(response.data.message.checkout);
                              }
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.mobile_number) {
                                    toast.error(
                                          response.data.message.mobile_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <HotelContainer>
                        <BreadcrumbBox planWithExpert="show" title="Hotels" />
                        <HotelSection>
                              <EnquiryBanner
                                    setFrom={setFrom}
                                    setDestination={setDestination}
                                    setCheckin={setCheckin}
                                    setCheckout={setCheckout}
                                    setName={setName}
                                    setEmail={setEmail}
                                    setMobileNumber={setMobileNumber}
                                    setMessage={setMessage}
                                    loading={loading}
                                    handleForm={handleForm}
                                    show={show}
                                    setShow={setShow}
                                    fromValue={from}
                                    checkin={checkin}
                                    checkout={checkout}
                                    destination={destination}
                                    travelers={travelers}
                                    setTravelers={setTravelers}
                                    description="We are partners with most hotel brands to offer the best deals for your group or individual hotel bookings. Send us your query!"
                              />
                              <AvailabelHotel />
                        </HotelSection>
                        <HotelChains title="Our Top Hotel Chains" />
                  </HotelContainer>
            </>
      );
};

export default HotelPage;
