import styled from 'styled-components';

export const FormButtonContainer = styled.button`
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 25rem;
      width: 100%;
      transition: all 0.3s ease-in-out;

      &:hover {
            color: ${(props) => props.theme.white};
            background-color: ${(props) => props.theme.primary};
      }
`;
