import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BlogItem = styled.div``;

export const BlogItemImage = styled.div`
      height: 304px;
      width: 100%;
      border-radius: 20px;

      img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
      }

      @media screen and (max-width: 600px) {
            height: 190px;
      }
`;

export const BlogDetail = styled.div`
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 20px;
      margin-bottom: 8px;

      @media screen and (max-width: 600px) {
            margin-top: 12px;
            margin-bottom: 4px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                  display: none;
            }
      }
`;

export const BlogDetailCategory = styled.div`
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      padding: 2px 10px;
      border-radius: 5px;
      font-size: 13px;
      text-transform: uppercase;

      @media screen and (max-width: 600px) {
            min-width: fit-content;
      }
`;

export const BlogDetailItem = styled.div`
      color: #999999;
      i {
            padding-right: 8px;
      }

      span {
            color: ${(props) => props.theme.primary};
      }

      @media screen and (max-width: 600px) {
            min-width: fit-content;
      }
`;

export const BlogInfo = styled.div``;

export const BlogInfoTitle = styled.h3`
      font-size: 22px;
      font-weight: 500;
      line-height: 1.25;

      a {
            color: ${(props) => props.theme.black};

            &:hover {
                  color: ${(props) => props.theme.primary};
            }
      }

      @media screen and (max-width: 600px) {
            font-size: 18px;
      }
`;

export const BlogInfoSummary = styled.p`
      color: #666;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      @media screen and (max-width: 600px) {
            font-size: 14px;
            margin-bottom: 8px;
      }
`;

export const BlogInfoLink = styled(Link)`
      color: ${(props) => props.theme.black};
      font-weight: 500;
      transition: all 0.3s ease-in;
      display: flex;
      align-items: center;

      &:hover {
            color: ${(props) => props.theme.primary};
      }

      span {
            padding-left: 8px;
            font-weight: 500;
            font-size: 16px;
            color: ${(props) => props.theme.primary};
      }
`;
