import styled from 'styled-components';

export const ButtonClose = styled.button`
      position: absolute;
      top: -15px;
      right: 0;
      z-index: 9;
      border: 2px solid ${(props) => props.theme.primary};
      opacity: 1;
      border-radius: 50%;
      padding: 10px;
      color: ${(props) => props.theme.primary};
      --bs-btn-close-bg: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23224082%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>');
`;
