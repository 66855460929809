import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { PackageContainer } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import { PackageItem } from '../../components/frontend/home/PackageBox/components';
import axios from 'axios';

const Packages = () => {
      const [loading, setLoading] = useState(false);
      const [packages, setPackages] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/packages`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPackages(response.data.packages);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BreadcrumbBox title={'Our Packages'} />
                  <PackageContainer className="py-5">
                        <Container>
                              <Row className="g-4">
                                    {!loading &&
                                          packages?.length > 0 &&
                                          packages?.map((item, index) => (
                                                <Col lg={3} key={index}>
                                                      <PackageItem
                                                            item={item}
                                                      />
                                                </Col>
                                          ))}
                              </Row>
                        </Container>
                  </PackageContainer>
            </>
      );
};

export default Packages;
