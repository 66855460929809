import styled from 'styled-components';

export const DestinationContainer = styled.div`
      background-color: #e2e8f0;
      padding: ${(props) => props.padding || '50px 0px'};
`;

export const DestinationTitle = styled.div`
      color: ${(props) => props.theme.primary};
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 8px;
      padding-bottom: 20px;
      border-bottom: 1px dashed rgba(33, 37, 41, 0.75);
`;

export const DestinationSummary = styled.div`
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      /* margin-top: 20px; */
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      div:nth-child(2) {
            margin-top: 20px;
      }
`;
