import styled from 'styled-components';

export const SubTitleWrapper = styled.div`
      display: flex;
      align-items: center;
      justify-content: ${(props) => (props.center ? 'center' : '')};
      gap: 15px;

      span {
            font-family: var(--dancing__font);
            color: ${(props) => props.theme.primary};
            font-size: ${(props) => props.size || '20px'};
      }
      margin-bottom: 20px;

      @media screen and (max-width: 600px) {
            margin-bottom: 5px;
      }
`;
export const Dot = styled.span`
      display: inline-block;
      width: 8px;
      height: 2px;
      background-color: inherit;
      border-radius: 99px;
      position: absolute;
      top: 0;
      right: ${(props) => (props.right ? '-12px' : '')};
      left: ${(props) => (props.left ? '-12px' : '')};
      &:before {
            content: '';
            height: inherit;
            width: inherit;
            background-color: inherit;
            border-radius: inherit;
            display: inline-block;
            position: absolute;
            top: 0;
            right: ${(props) => (props.right ? 'inherit' : ';')};
            left: ${(props) => (props.left ? 'inherit' : ';')};
      }
`;
export const Shape = styled.span`
      display: inline-block;
      height: 2px;
      width: 40px;
      background-color: ${(props) => props.theme.primary};
      border-radius: 99px;
      position: relative;
`;

export const MainTitleWrapper = styled.div`
      h2 {
            text-transform: capitalize;
            font-size: 32px !important;
            color: ${(props) => props.theme.titleColor};
            font-family: var(--playfair__font);
            width: ${(props) => (props.center ? '100%' : '75%')};
            font-weight: 600;
            line-height: ${(props) => (props.height ? '1.4' : '1.6')};
            margin-bottom: 20px;
      }

      @media screen and (max-width: 600px) {
            h2 {
                  font-size: 28px !important;
                  line-height: 1.4;
                  margin-bottom: 0px;
            }
      }
`;
