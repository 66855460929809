import React, { useEffect } from 'react';
import {
      SelectedItemsBody,
      SelectedItemsContainer,
      SelectedItemsTitle
} from './styles';
import SelectedItem from '../SelectedItem';
import {
      AirplaneLanding,
      AirplaneTakeOff,
      CalenderDestinationIcon,
      CoupleIcon,
      MoonIcon
} from '../../../../icons';

const SelectedItems = ({
      setGroupSize,
      setMonth,
      setDuration,
      setDeparting,
      setSelectedDate,
      setDestination,
      setSelectedMonth,
      groupSize,
      duration,
      month,
      departing,
      destination,
      currentYear
}) => {
      const removeDestination = () => {
            setDestination('');
            setGroupSize('');
            setMonth('');
            setDuration('');
            setDeparting('');
            setSelectedDate('');
            setSelectedMonth('');
      };

      const removeMonth = () => {
            setGroupSize('');
            setMonth('');
            setDuration('');
            setDeparting('');
            setSelectedDate('');
            setSelectedMonth('');
      };

      const removeDeparting = () => {
            setGroupSize('');
            setDuration('');
            setDeparting('');
      };

      const removeDuration = () => {
            setGroupSize('');
            setDuration('');
      };

      const removeGroupSize = () => {
            setGroupSize('');
      };

      return (
            <>
                  <SelectedItemsContainer>
                        <SelectedItemsTitle>
                              NOW PLANNING YOUR HOLIDAY TO
                        </SelectedItemsTitle>
                        <SelectedItemsBody>
                              <SelectedItem
                                    handleChange={removeDestination}
                                    icon={<AirplaneLanding />}
                                    title={destination}
                              />
                              <SelectedItem
                                    handleChange={removeMonth}
                                    icon={<CalenderDestinationIcon />}
                                    title={`${month} ${month && currentYear}`}
                              />
                              <SelectedItem
                                    icon={<AirplaneTakeOff />}
                                    handleChange={removeDeparting}
                                    title={departing}g
                              />
                              <SelectedItem
                                    icon={<MoonIcon />}
                                    title={duration}
                                    handleChange={removeDuration}
                              />
                              <SelectedItem
                                    icon={<CoupleIcon />}
                                    title={groupSize}
                                    handleChange={removeGroupSize}
                              />
                        </SelectedItemsBody>
                  </SelectedItemsContainer>
            </>
      );
};

export default SelectedItems;
