import styled from 'styled-components';

export const ContactDetail = styled.div`
      padding: 20px;
      border: 1px solid #ebf7ff;
      display: flex;
      align-items: center;
      justify-content: start;
      height: 100%;
      gap: 14px;
`;

export const ContactDetailIcon = styled.div`
      background-color: #eaf7ff;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
            font-size: 24px;
      }
`;

export const ContactDetailInfo = styled.div`
      width: calc(100% - 60px);
`;

export const ContactDetailTitle = styled.h3`
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 4px;
`;

export const ContactDetailParagraph = styled.p``;
