import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PackageItemImage = styled.div`
      overflow: hidden;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;

      .card-img-top {
            transition: all 0.3s ease-in;

            &:hover {
                  transform: scale(1.1);
                  border-radius: 10px;
            }
      }
`;

export const PackageItemContainer = styled(Link)`
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: 5px;
      height: 100%;
      display: inline-block;
      transition: box-shadow 0.3s ease-in;

      &:hover {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }

      .card {
            border: 0;
      }

      .card-body {
            transition: all 0.3s ease;
            position: relative;
            padding: 20px 20px 0;
      }
      .card-title {
            transition: all 0.3s ease;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;

            &:hover {
                  color: ${(props) => props.theme.primary} !important;
            }
      }
`;
export const PackageDetail = styled.div`
      padding: 0 20px 20px;
`;
export const PackageLocation = styled.div`
      font-size: 13px;
      display: flex;
      align-items: center;
      gap: 8px;
      text-transform: capitalize;

      svg {
            width: 18px;
            height: 18px;
            fill: ${(props) => props.theme.primary};
      }
`;
export const PackageRating = styled.div``;
export const PackageWrapper = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px dashed rgba(33, 37, 41, 0.75);
      padding-top: 10px;
`;
export const PackagePrice = styled.p`
      span {
            font-weight: 500;
            font-size: 18px;
            color: ${(props) => props.theme.black};
      }
`;

export const PackageCategory = styled.div`
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 1;
      display: inline-block;
      padding: 8px;
      background-color: ${(props) => props.theme.primary};
      color: #fff;
      border-radius: 5px;
      font-size: 14px;
      line-height: 1;
`;
export const PackageTime = styled.div`
      display: inline-flex;
      border: 1px solid ${(props) => props.theme.primary};
      background: ${(props) => props.theme.white};
      position: absolute;
      left: 20px;
      top: -22px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      border-radius: 5px;
      line-height: 1;
      z-index: 1;
      padding: 7px;
      font-size: 15px;
      margin-top: 5px;
      color: ${(props) => props.theme.primary};
      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
      }
`;
