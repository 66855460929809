import React from 'react';
import {
      DestinationMonthContainer,
      DestinationMonthItem,
      DestinationMonthTitle
} from './style';
import { Fade } from 'react-reveal';

const DestinationMonth = ({ months, handleChange }) => {
      return (
            <>
                  <Fade>
                        <DestinationMonthTitle>
                              Which month are you travelling?
                        </DestinationMonthTitle>
                        <DestinationMonthContainer>
                              {months?.map((item, index) => (
                                    <DestinationMonthItem
                                          key={index}
                                          onClick={() => handleChange(item)}
                                    >
                                          {item.title}
                                    </DestinationMonthItem>
                              ))}
                        </DestinationMonthContainer>
                  </Fade>
            </>
      );
};

export default DestinationMonth;
