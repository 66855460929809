import styled from 'styled-components';
import { MoreButtonContainer } from '../../../common/MoreButton/styles';

export const DomesticContainer = styled.div`
   background: #f5f5f5;
   padding: 60px 0px;
   .swiper {
      padding-bottom: 70px;
   }
   padding: 60px 0px;
   .swiper-pagination {
      bottom: 0px !important;
   }
   .swiper-pagination-bullet-active {
      opacity: var(--swiper-pagination-bullet-opacity, 1);
      background: ${(props) => props.theme.primary};
   }
   ${MoreButtonContainer} {
      position: absolute;
      left: 0;
      bottom: 0;
   }
   .swiper-button-prev {
      left: unset;
      right: 63px;
   }
   .swiper-button-prev,
   .swiper-button-next {
      top: unset;
      padding: 18px;
      border: 1px solid #c6c1c1;
      bottom: 1px;
      color: ${(props) => props.theme.primary};
      border-radius: 50%;
      &:after {
         font-size: 16px;
         font-weight: bold;
      }
   }
   @media screen and (max-width: 700px) {
      .row {
         padding: 0px 10px;
      }
   }
`;
