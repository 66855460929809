import React, { useState } from 'react';
import { EnquiryFormContainer, EnquiryFormHeaing } from './styles';
import { Form } from 'react-bootstrap';
import CommonButton from '../CommonButton';

import $ from 'jquery';
import axios from 'axios';
import toast from 'react-hot-toast';

const EnquriryForm = ({ title, slug }) => {
      const [loading, setLoading] = useState('');
      const [leavingFrom, setLeavingFrom] = useState('');
      const [leavingTo, setLeavingTo] = useState('');
      const [noOfTraveller, setNoOfTraveller] = useState('');
      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [mobileNumber, setMobileNumber] = useState('');
      const [query, setQuery] = useState('');

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  leaving_from: leavingFrom,
                  leaving_on: leavingTo,
                  name: name,
                  email: email,
                  mobile_number: mobileNumber,
                  query: query,
                  no_of_traveller: noOfTraveller
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/packages/${slug}`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              setTimeout(() => {
                                    $('form').trigger('reset');
                              }, 1500);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.leaving_from) {
                                    toast.error(
                                          response.data.message.leaving_from
                                    );
                              }
                              if (response.data.message.leaving_on) {
                                    toast.error(
                                          response.data.message.leaving_on
                                    );
                              }
                              if (response.data.message.no_of_traveller) {
                                    toast.error(
                                          response.data.message.no_of_traveller
                                    );
                              }
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.mobile_number) {
                                    toast.error(
                                          response.data.message.mobile_number
                                    );
                              }
                              if (response.data.message.query) {
                                    toast.error(response.data.message.query);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <EnquiryFormContainer>
                        <div style={{ zIndex: '10', position: 'relative' }}>
                              <EnquiryFormHeaing className="d-flex align-items-center gap-3">
                                    <i className="bx bxs-edit-alt"></i>
                                    <h3>Enquiry Form</h3>
                              </EnquiryFormHeaing>
                              <Form onSubmit={handleForm}>
                                    <Form.Group className="mb-3">
                                          <Form.Label>Desitnation </Form.Label>
                                          <Form.Control
                                                type="text"
                                                value={title}
                                                disabled
                                          />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                          <Form.Label>Leaving From </Form.Label>
                                          <Form.Control
                                                type="text"
                                                onChange={(event) =>
                                                      setLeavingFrom(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                          <Form.Label>Leaving On </Form.Label>
                                          <Form.Control
                                                type="text"
                                                onChange={(event) =>
                                                      setLeavingTo(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                          <Form.Label>
                                                No. of Travellers{' '}
                                          </Form.Label>
                                          <Form.Control
                                                type="text"
                                                onChange={(event) =>
                                                      setNoOfTraveller(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                          <Form.Label>Name </Form.Label>
                                          <Form.Control
                                                type="text"
                                                onChange={(event) =>
                                                      setName(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                          <Form.Label>
                                                Mobile (Whatsapp No.){' '}
                                          </Form.Label>
                                          <Form.Control
                                                type="text"
                                                onChange={(event) =>
                                                      setMobileNumber(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                          <Form.Label>Email </Form.Label>
                                          <Form.Control
                                                type="text"
                                                onChange={(event) =>
                                                      setEmail(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                          <Form.Label>
                                                Any Query (optional)
                                          </Form.Label>
                                          <Form.Control
                                                as="textarea"
                                                rows={4}
                                                onChange={(event) =>
                                                      setQuery(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    </Form.Group>
                                    <CommonButton
                                          title={'Submit'}
                                          link={''}
                                          button={true}
                                          loading={loading}
                                    />
                              </Form>
                        </div>
                  </EnquiryFormContainer>
            </>
      );
};

export default EnquriryForm;
