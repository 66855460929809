import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

export const PopularDestinationContainer = styled.section`
      background: #f5f5f5;
      padding: 80px 0px 60px;

      @media screen and (max-width: 600px) {
            margin: 0;
      }
`;

export const PopularDestinationHeading = styled.div``;

export const GroupTourTag = styled.div`
      position: absolute;
      top: 15px;
      left: 15px;
      color: #ffffff;
      padding: 5px 8px;
      background: #00000040;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 7px;
      font-size: 14px;
`;

export const GroupDays = styled.div`
      position: absolute;
      z-index: 10;
      bottom: 15px;
      left: 15px;
      color: #ffffff;
      padding: 5px 8px;
      background: #00000040;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 7px;
      font-size: 14px;
`;
export const GroupContent = styled.div`
      margin-top: 15px;
      text-align: left;

      h4 {
            font-size: 20px;
            font-weight: 500;
      }

      span {
            font-size: 15px;
            font-weight: 600;
      }

      p.location {
            span {
                  font-size: 18px;
            }
      }

      p {
            del {
                  color: #9f9f9f;
                  font-weight: 300;
            }
      }

      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      }
`;

export const PopularDestinationSlider = styled(Swiper)`
      width: 100%;
      position: relative;

      .swiper-button-next,
      .swiper-button-prev {
            background-color: #ffffff00;
            color: #22242980;
            border-style: solid;
            border-width: 2px 2px 2px 2px;
            border-color: #22242980;
            border-radius: 50%;
            height: 40px;
            width: 40px;

            &::after {
                  font-size: 14px;
                  color: #1d1d1b;
            }
      }

      .swiper-button-next {
            left: 80px;
      }

      .swiper-button-next,
      .swiper-button-prev {
            top: auto;
            bottom: 0;
      }
`;

export const PopularDestinationContent = styled.div`
      padding-bottom: 60px;
      h4 {
            font-size: 18px;
      }
`;

export const PopularDestinationTitle = styled.div`
      span {
            font-size: 16px;
            font-weight: 600;
            color: ${(props) => props.theme.primary};
            text-transform: uppercase;
            display: block;
            margin-bottom: 15px;
      }
      font-size: 36px;
      line-height: 1.278;
      margin-bottom: 1.5rem;

      p {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: #666;
            font-family: 'Rajdhani', sans-serif;
      }
`;

export const PopularDestinationDesc = styled.p``;

export const PopularDestinationThumb = styled(Swiper)`
      height: 100%;
      box-sizing: border-box;
      padding: 10px 0;

      .swiper-wrapper {
            padding: 30px 0px;
            /* padding-bottom: 115px; */
      }

      .swiper-slide {
            opacity: 0.4;

            img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
            }
      }

      .swiper-slide-thumb-active {
            opacity: 1;
      }

      @media screen and (max-width: 600px) {
            .swiper-wrapper {
                  padding: 0px;
            }
      }
`;

export const PopularDestinationImage = styled.div`
      position: relative;
      &:after {
            position: absolute;
            top: 0%;
            left: 0%;
            z-index: 10;
            height: 100%;
            width: 100%;
            content: '';
            background: #1619213b;
            opacity: 0.65;
            z-index: -1;
            border-radius: 10px;
      }
`;

export const PopularDestinationImageContent = styled.div`
      position: absolute;
      top: 0%;
      left: 0%;
      z-index: 10;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
`;

export const PopularDestinationImageTitle = styled.div``;

export const PopularDestinationImageIcon = styled.div`
      margin-bottom: 25px;
      position: relative;
      z-index: 3;
      &:after {
            content: '';
            height: 8px;
            width: 16px;
            background-color: rgba(247, 247, 247, 0.6);
            position: absolute;
            border-radius: 50%;
            opacity: 0.5;
            z-index: -1;
            background-color: rgba(255, 255, 255, 0.3);
            height: 16px;
            width: 36px;
            left: -5px;
            bottom: -6px;
      }
      &:before {
            content: '';
            height: 8px;
            width: 16px;
            background-color: rgba(247, 247, 247, 0.6);
            position: absolute;
            bottom: -1px;
            left: 5px;
            border-radius: 50%;
            opacity: 0.5;
            z-index: -1;
      }
      svg {
            fill: ${(props) => props.theme.white};
      }
`;

export const PopularDestinationImageLink = styled.div``;

export const PopularDestinationSwiperSlider = styled(SwiperSlide)`
      position: relative;
      -webkit-transition: 0.3s linear;
      transition: 0.3s linear;
      text-align: center;
      cursor: pointer;
      /* height: 375px; */
      opacity: 1 !important;

      img {
            border-radius: 10px;
            height: 375px !important;
            overflow: hidden;
            position: relative;
            z-index: 2;
      }

      &.swiper-slide-thumb-active {
            opacity: 1;
            -webkit-box-flex: 307px;
            -ms-flex: 307px;
            flex: 307px;
            ${PopularDestinationImageContent} {
                  display: flex;
            }
      }

      @media screen and (max-width: 600px) {
            &.swiper-slide {
                  min-width: 120px;
            }

            &.swiper-slide-thumb-active {
                  -webkit-box-flex: 260px;
                  -ms-flex: 260px;
                  flex: 260px;
                  min-width: 260px !important;
            }
      }
`;
