import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const BlogSection = styled.section`
      padding: 80px 0px;
      overflow: hidden;
`;
export const BlogHeading = styled.div``;
export const BlogContainer = styled(Container)`
`;
