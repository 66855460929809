import React, { useState } from 'react';
import {
      PackageSliderContainer,
      PackageSliderSlide,
      PackageSliderThumb,
      PackageSliderWrapper,
      PackageSliderWrapperThumb
} from './styles';

// Import Swiper React components

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

const PackageDetailSlider = ({ loading, image, images, title }) => {
      const [thumbsSwiper, setThumbsSwiper] = useState(null);

      return (
            <>
                  <PackageSliderContainer>
                        <PackageSliderWrapper
                              style={{
                                    '--swiper-navigation-color': '#fff',
                                    '--swiper-pagination-color': '#fff'
                              }}
                              spaceBetween={10}
                              navigation={true}
                              thumbs={{ swiper: thumbsSwiper }}
                              modules={[FreeMode, Navigation, Thumbs]}
                              className="mySwiper2"
                        >
                              {!loading && image && (
                                    <PackageSliderSlide>
                                          <img src={image} alt={title} />
                                    </PackageSliderSlide>
                              )}
                              {!loading &&
                                    images &&
                                    images?.map((item, index) => (
                                          <PackageSliderSlide key={index}>
                                                <img
                                                      src={item.image}
                                                      alt={item.title}
                                                />
                                          </PackageSliderSlide>
                                    ))}
                        </PackageSliderWrapper>
                        <PackageSliderWrapperThumb
                              onSwiper={setThumbsSwiper}
                              spaceBetween={10}
                              slidesPerView={4}
                              freeMode={true}
                              watchSlidesProgress={true}
                              modules={[FreeMode, Navigation, Thumbs]}
                              className="mySwiper"
                        >
                              {!loading && image && (
                                    <PackageSliderThumb>
                                          <img src={image} alt={title} />
                                    </PackageSliderThumb>
                              )}
                              {!loading &&
                                    images &&
                                    images?.map((item, index) => (
                                          <PackageSliderThumb key={index}>
                                                <img
                                                      src={item.image}
                                                      alt={item.title}
                                                />
                                          </PackageSliderThumb>
                                    ))}
                        </PackageSliderWrapperThumb>
                  </PackageSliderContainer>
            </>
      );
};

export default PackageDetailSlider;
