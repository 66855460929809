import React from 'react';
import { MainTitleWrapper } from './styles';

const MainTitle = ({ title, center, height }) => {
      return (
            <>
                  <MainTitleWrapper center={center} height={height}>
                        <h2>{title}</h2>
                  </MainTitleWrapper>
            </>
      );
};

export default MainTitle;
