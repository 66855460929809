import React, { useEffect, useState } from 'react';
import {
      GroupContent,
      GroupDays,
      GroupTourTag,
      PopularDestinationContainer,
      PopularDestinationHeading,
      PopularDestinationImage,
      PopularDestinationImageContent,
      PopularDestinationImageLink,
      PopularDestinationSwiperSlider,
      PopularDestinationThumb
} from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import MainTitle from '../../../../components/frontend/home/HomeTitle/MainTitle';
import SubTitle from '../../../../components/frontend/home/HomeTitle/SubTitle';
import { destinations } from '../../../../data/destinationList';
import axios from 'axios';

const PopularDestination = () => {
      const [loading, setLoading] = useState(false);
      const [packages, setPackages] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/group_packages`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPackages(response.data.packages);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {!loading ? (
                        packages?.length > 0 ? (
                              <PopularDestinationContainer>
                                    <Container>
                                          <PopularDestinationHeading className=" text-center align-items-center">
                                                <MainTitle
                                                      title="Upcoming Group tours"
                                                      className="w-100"
                                                      center="center"
                                                />
                                                <SubTitle
                                                      subtitle="Top Destinations"
                                                      left="-12px"
                                                      right="-12px"
                                                      center="100%"
                                                />
                                          </PopularDestinationHeading>
                                          <Row>
                                                <Col lg={12}>
                                                      <PopularDestinationThumb
                                                            slidesPerView={1}
                                                            spaceBetween={10}
                                                            pagination={{
                                                                  clickable: true
                                                            }}
                                                            breakpoints={{
                                                                  640: {
                                                                        slidesPerView: 1,
                                                                        spaceBetween: 20
                                                                  },
                                                                  768: {
                                                                        slidesPerView: 2,
                                                                        spaceBetween: 20
                                                                  },
                                                                  1024: {
                                                                        slidesPerView: 4,
                                                                        spaceBetween: 30
                                                                  }
                                                            }}
                                                      >
                                                            {destinations?.map(
                                                                  (
                                                                        destination,
                                                                        index
                                                                  ) => (
                                                                        <PopularDestinationSwiperSlider
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <PopularDestinationImage>
                                                                                    <img
                                                                                          src={
                                                                                                destination.image
                                                                                          }
                                                                                          alt={
                                                                                                destination.title
                                                                                          }
                                                                                    />
                                                                                    <GroupDays>
                                                                                          3
                                                                                          days
                                                                                          /
                                                                                          5
                                                                                          nights
                                                                                    </GroupDays>
                                                                              </PopularDestinationImage>
                                                                              <PopularDestinationImageContent>
                                                                                    <GroupTourTag>
                                                                                          <i className="bx bxs-group"></i>
                                                                                          Group
                                                                                          Tours{' '}
                                                                                    </GroupTourTag>{' '}
                                                                                    <PopularDestinationImageLink>
                                                                                          {
                                                                                                destination.link
                                                                                          }
                                                                                    </PopularDestinationImageLink>
                                                                              </PopularDestinationImageContent>
                                                                              <GroupContent>
                                                                                    <p className="mb-1 d-flex align-items-center gap-2 location">
                                                                                          <span className="material-symbols-outlined">
                                                                                                location_on
                                                                                          </span>
                                                                                          {
                                                                                                destination.location
                                                                                          }
                                                                                    </p>
                                                                                    <h4>
                                                                                          {
                                                                                                destination.title
                                                                                          }
                                                                                    </h4>
                                                                                    <span className="">
                                                                                          Starting
                                                                                          from
                                                                                    </span>
                                                                                    <p className="d-flex align-items-center gap-2 mt-1">
                                                                                          {destination.offer_price ? (
                                                                                                <>
                                                                                                      <del className="d-flex align-items-center">
                                                                                                            &#8377;{' '}
                                                                                                            {
                                                                                                                  destination.price
                                                                                                            }
                                                                                                      </del>
                                                                                                      <span
                                                                                                            className="d-flex align-items-center"
                                                                                                            style={{
                                                                                                                  fontWeight:
                                                                                                                        ''
                                                                                                            }}
                                                                                                      >
                                                                                                            &#8377;{' '}
                                                                                                            {
                                                                                                                  destination.offer_price
                                                                                                            }
                                                                                                      </span>
                                                                                                </>
                                                                                          ) : (
                                                                                                <span
                                                                                                      className="d-flex align-items-center"
                                                                                                      style={{
                                                                                                            fontWeight:
                                                                                                                  ''
                                                                                                      }}
                                                                                                >
                                                                                                      &#8377;{' '}
                                                                                                      {
                                                                                                            destination.price
                                                                                                      }
                                                                                                </span>
                                                                                          )}
                                                                                    </p>
                                                                              </GroupContent>
                                                                        </PopularDestinationSwiperSlider>
                                                                  )
                                                            )}
                                                      </PopularDestinationThumb>
                                                </Col>
                                          </Row>
                                    </Container>
                              </PopularDestinationContainer>
                        ) : null
                  ) : null}
            </>
      );
};

export default PopularDestination;
