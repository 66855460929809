// DataList.js
import React from 'react';

const DataList = ({ dataList }) => {
  return (
    <div>
      <h2>Data List</h2>
      <ul>
        {dataList.map((data, index) => (
          <li key={index}>{data}</li>
        ))}
      </ul>
    </div>
  );
};

export default DataList;
