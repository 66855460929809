import styled, { css } from 'styled-components';
import { SwiperSlide, Swiper } from 'swiper/react';

export const PackageSliderContainer = styled.div``;
export const PackageSliderSlide = styled(SwiperSlide)`
      img {
            border-radius: 10px;
            width: 100%;
      }
`;

export const ImageRounded = css`
      border-radius: 10px;
      width: 100%;
`;
export const PackageSliderThumb = styled(SwiperSlide)`
      height: 100%;

      img {
            width: 100%;
            height: 100%;
      }
`;

export const PackageSliderWrapper = styled(Swiper)`
      margin-bottom: 20px;
`;

export const PackageSliderWrapperThumb = styled(Swiper)`
      img {
            ${ImageRounded}
      }
`;
