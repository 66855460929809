import React, { useContext, useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import {
      ContactAnimation,
      ContactContainer,
      ContactContent,
      ContactForm,
      ContactHeader,
      ContactSocialMedia,
      ContactSubTitle
} from './style';
import { Col, Container, Row } from 'react-bootstrap';
import { FormButton, FormInput, FormTextarea } from '../../components/forms';
import SocialMediaItem from '../../components/frontend/contact/SocialMediaItem';
import ContactDetailItem from '../../components/frontend/contact/ContactDetailItem';
import TwitterIcon from '../../icons/SocialMediaIcons/TwitterIcon';

import $ from 'jquery';
import axios from 'axios';
import toast from 'react-hot-toast';
import LogoContext from '../../stores/LogoContext';

const ContactPage = () => {
      const [translateY, setTranslateY] = useState(-18.41456);
      const logoCtx = useContext(LogoContext);

      useEffect(() => {
            const handleScroll = () => {
                  const scrollPosition = window.scrollY;
                  const newTranslateY = -scrollPosition * 0.5;
                  setTranslateY(newTranslateY);
            };

            window.addEventListener('scroll', handleScroll);
            return () => {
                  window.removeEventListener('scroll', handleScroll);
            };
      }, []);

      const [loading, setLoading] = useState(false);

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [message, setMessage] = useState('');
      const [subject, setSubject] = useState('');

      const handleForm = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  name: name,
                  email: email,
                  phone_number: phoneNumber,
                  message: message,
                  subject: subject
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/contacts`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTimeout(() => {
                                    $('form').trigger('reset');
                                    toast.success(response.data.message);
                              }, 1500);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                              if (response.data.message.subject) {
                                    toast.error(response.data.message.subject);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <BreadcrumbBox title="Contact Us" />

                  <ContactContainer>
                        <ContactAnimation
                              style={{ '--translateY': `${translateY}px` }}
                        />
                        <Container>
                              <Row className="align-items-center">
                                    <Col lg={5}>
                                          <ContactContent>
                                                <ContactSubTitle>
                                                      Talk with our team
                                                </ContactSubTitle>
                                                <ContactHeader>
                                                      Any Question? <br /> Feel
                                                      Free to <br /> Contact
                                                </ContactHeader>
                                                <p className="text-muted">
                                                      Make every journey becomes
                                                      an extraordinary
                                                      adventure. At AirCasa, we
                                                      are passionate about
                                                      curating travel
                                                      experiences that transcend
                                                      the ordinary, offering a
                                                      seamless blend of comfort
                                                      and exploration.
                                                </p>
                                                <ContactSocialMedia>
                                                      <ul>
                                                            <SocialMediaItem
                                                                  link={
                                                                        logoCtx
                                                                              .generalInformation
                                                                              .facebook_link
                                                                  }
                                                                  icon="bx bxl-facebook"
                                                            />
                                                            <SocialMediaItem
                                                                  link={
                                                                        logoCtx
                                                                              .generalInformation
                                                                              .instagram_link
                                                                  }
                                                                  icon="bx bxl-instagram"
                                                            />
                                                            <SocialMediaItem
                                                                  link={
                                                                        logoCtx
                                                                              .generalInformation
                                                                              .twitter_link
                                                                  }
                                                                  icons={
                                                                        <TwitterIcon />
                                                                  }
                                                            />
                                                            <SocialMediaItem
                                                                  link={
                                                                        logoCtx
                                                                              .generalInformation
                                                                              .linkedin
                                                                  }
                                                                  icon="bx bxl-linkedin"
                                                            />
                                                            <SocialMediaItem
                                                                  link={
                                                                        logoCtx
                                                                              .generalInformation
                                                                              .whatsapp
                                                                  }
                                                                  icon="bx bxl-whatsapp"
                                                            />
                                                      </ul>
                                                </ContactSocialMedia>
                                          </ContactContent>
                                    </Col>
                                    <Col lg={7}>
                                          <ContactForm onSubmit={handleForm}>
                                                <Row className="g-4">
                                                      <FormInput
                                                            placeholder="Your Name"
                                                            handleChange={
                                                                  setName
                                                            }
                                                      />
                                                      <FormInput
                                                            placeholder="Email Address"
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />
                                                      <FormInput
                                                            placeholder="Phone Number"
                                                            handleChange={
                                                                  setPhoneNumber
                                                            }
                                                      />
                                                      <FormInput
                                                            placeholder="Subject"
                                                            handleChange={
                                                                  setSubject
                                                            }
                                                      />
                                                      <FormTextarea
                                                            placeholder="Message"
                                                            classes={12}
                                                            handleChange={
                                                                  setMessage
                                                            }
                                                      />
                                                      <FormButton
                                                            classes={12}
                                                            title="Send Message"
                                                            loading={loading}
                                                      />
                                                </Row>
                                          </ContactForm>
                                    </Col>

                                    <Col lg={12}>
                                          <Row className="mt-5 pt-5 g-4">
                                                <ContactDetailItem
                                                      title="Address"
                                                      icon="bx bx-map"
                                                      description={
                                                            logoCtx
                                                                  .generalInformation
                                                                  .address
                                                      }
                                                />
                                                <ContactDetailItem
                                                      title="Phone Number"
                                                      icon="bx bx-phone-call"
                                                      description={
                                                            logoCtx
                                                                  .generalInformation
                                                                  .phone_number
                                                      }
                                                />
                                                <ContactDetailItem
                                                      title="Email "
                                                      icon="bx bx-envelope"
                                                      description={
                                                            logoCtx
                                                                  .generalInformation
                                                                  .email
                                                      }
                                                />
                                          </Row>
                                    </Col>
                              </Row>
                        </Container>
                  </ContactContainer>
                  {/* <ContactMap /> */}
            </>
      );
};

export default ContactPage;
