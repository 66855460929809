import React from 'react';
import { Container } from 'react-bootstrap';
import {
      BreadcrumbContainer,
      BreadcrumbContent,
      BreadcrumbOutlay
} from './styles';
import { Link } from 'react-router-dom';
import BookWithExpert from '../BookWithExpert';

const BreadcrumbBox = ({
      title,
      link,
      linkTitle,
      children,
      image,
      loading,
      planWithExpert
}) => {
      const backgroundImage =
            !loading && image
                  ? `url("${image}")`
                  : 'url("/images/img/breadcrumb/aircasa-banner.png")';
      return (
            <>
                  <BreadcrumbContainer
                        style={{
                              backgroundImage: backgroundImage
                        }}
                  >
                        <BreadcrumbOutlay />
                        <Container>
                              <BreadcrumbContent>
                                    <div>
                                          {/* <h1 className="page-title">
                                                {title}
                                          </h1> */}
                                          {linkTitle && (
                                                <div className="ltn__breadcrumb-list">
                                                      <ol className="breadcrumb">
                                                            <li className="breadcrumb-item">
                                                                  <i className="bx bx-home"></i>{' '}
                                                                  <Link to="/">
                                                                        Home
                                                                  </Link>
                                                            </li>
                                                            {children &&
                                                                  children}
                                                            {linkTitle && (
                                                                  <li className="breadcrumb-item">
                                                                        <Link
                                                                              to={
                                                                                    link
                                                                              }
                                                                        >
                                                                              {
                                                                                    linkTitle
                                                                              }
                                                                        </Link>
                                                                  </li>
                                                            )}
                                                            <li
                                                                  className="breadcrumb-item active"
                                                                  aria-current="page"
                                                            >
                                                                  {title}
                                                            </li>
                                                      </ol>
                                                </div>
                                          )}
                                    </div>
                                    {planWithExpert === 'show' ? (
                                          ''
                                    ) : (
                                          <BookWithExpert />
                                    )}
                              </BreadcrumbContent>
                        </Container>
                  </BreadcrumbContainer>
            </>
      );
};

export default BreadcrumbBox;
