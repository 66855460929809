import React from 'react';
import {
      FeaturesContainer,
      FeaturesContent,
      FeaturesDetail,
      FeaturesHeading,
      FeaturesIcon,
      FeaturesImage,
      FeaturesItem,
      FeaturesWrapper
} from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import SubTitle from '../../../../components/frontend/home/HomeTitle/SubTitle';
import MainTitle from '../../../../components/frontend/home/HomeTitle/MainTitle';
import CalanderIcon from '../../../../icons/CalanderIcon/CalanderIcon';

const FeaturesBox = () => {
      return (
            <>
                  <FeaturesContainer>
                        <Container>
                              <Row>
                                    <Col lg={5}>
                                          <FeaturesImage>
                                                <img
                                                      src="/images/img/features/side-image.jpg"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </FeaturesImage>
                                    </Col>
                                    <Col lg={7}>
                                          <FeaturesContent>
                                                <FeaturesHeading>
                                                      <SubTitle
                                                            subtitle={
                                                                  'Why Choose Us'
                                                            }
                                                            right="-12px"
                                                      ></SubTitle>
                                                      <MainTitle
                                                            title={
                                                                  'Get The Best Travel Experience'
                                                            }
                                                      />
                                                      <p>
                                                            Professionally
                                                            engineer world-class
                                                            leadership skills
                                                            after end-to-end
                                                            growth strategies.
                                                            Assertively create
                                                            innovative schemas
                                                            through installed
                                                            base potentialities.
                                                      </p>
                                                </FeaturesHeading>
                                                <FeaturesWrapper>
                                                      <Row className="g-4">
                                                            <Col lg={6}>
                                                                  <FeaturesItem className="d-flex align-items-center gap-2">
                                                                        <FeaturesIcon>
                                                                              <CalanderIcon />
                                                                        </FeaturesIcon>
                                                                        <FeaturesDetail>
                                                                              <h3>
                                                                                    Set
                                                                                    Travel
                                                                                    Plan
                                                                              </h3>
                                                                              <p>
                                                                                    Distinctively
                                                                                    impact
                                                                                    client-centered
                                                                                    ideas
                                                                                    via
                                                                                    future-proof
                                                                                    paradigms.
                                                                              </p>
                                                                        </FeaturesDetail>
                                                                  </FeaturesItem>
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <FeaturesItem className="d-flex align-items-center gap-2">
                                                                        <FeaturesIcon>
                                                                              <CalanderIcon />
                                                                        </FeaturesIcon>
                                                                        <FeaturesDetail>
                                                                              <h3>
                                                                                    Set
                                                                                    Travel
                                                                                    Plan
                                                                              </h3>
                                                                              <p>
                                                                                    Distinctively
                                                                                    impact
                                                                                    client-centered
                                                                                    ideas
                                                                                    via
                                                                                    future-proof
                                                                                    paradigms.
                                                                              </p>
                                                                        </FeaturesDetail>
                                                                  </FeaturesItem>
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <FeaturesItem className="d-flex align-items-center gap-2">
                                                                        <FeaturesIcon>
                                                                              <CalanderIcon />
                                                                        </FeaturesIcon>
                                                                        <FeaturesDetail>
                                                                              <h3>
                                                                                    Set
                                                                                    Travel
                                                                                    Plan
                                                                              </h3>
                                                                              <p>
                                                                                    Distinctively
                                                                                    impact
                                                                                    client-centered
                                                                                    ideas
                                                                                    via
                                                                                    future-proof
                                                                                    paradigms.
                                                                              </p>
                                                                        </FeaturesDetail>
                                                                  </FeaturesItem>
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <FeaturesItem className="d-flex align-items-center gap-2">
                                                                        <FeaturesIcon>
                                                                              <CalanderIcon />
                                                                        </FeaturesIcon>
                                                                        <FeaturesDetail>
                                                                              <h3>
                                                                                    Set
                                                                                    Travel
                                                                                    Plan
                                                                              </h3>
                                                                              <p>
                                                                                    Distinctively
                                                                                    impact
                                                                                    client-centered
                                                                                    ideas
                                                                                    via
                                                                                    future-proof
                                                                                    paradigms.
                                                                              </p>
                                                                        </FeaturesDetail>
                                                                  </FeaturesItem>
                                                            </Col>
                                                      </Row>
                                                </FeaturesWrapper>
                                          </FeaturesContent>
                                    </Col>
                              </Row>
                        </Container>
                  </FeaturesContainer>
            </>
      );
};

export default FeaturesBox;
