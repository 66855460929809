import React, { useContext, useEffect, useState } from 'react';
import {
      FooterAbout,
      FooterBody,
      FooterContact,
      FooterContainer,
      FooterContent,
      FooterCredential,
      FooterCredentialContainer,
      FooterHeading,
      FooterItem
} from './styles';
import { Row } from 'react-bootstrap';
import SocialMedia from '../../../components/SocialMedia';
import FooterMenuItem from './components/FooterMenuItem';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LogoContext from '../../../stores/LogoContext';

const Footer = () => {
      const generalCtx = useContext(LogoContext);

      return (
            <>
                  <FooterContainer>
                        <FooterBody>
                              <Row className="g-5">
                                    <FooterItem lg={4}>
                                          <FooterHeading>
                                                <h3>
                                                      <img
                                                            src={
                                                                  generalCtx
                                                                        .setting
                                                                        .logo
                                                            }
                                                            alt="Aircasa white logo"
                                                      />
                                                </h3>
                                          </FooterHeading>
                                          <FooterAbout>
                                                <Link
                                                      to={'about-us'}
                                                      className="h-100"
                                                >
                                                      About Us
                                                </Link>
                                          </FooterAbout>
                                          <FooterContent margin="1.5rem">
                                                <p>Follow us on social media</p>
                                                <SocialMedia
                                                      generalInformation={
                                                            generalCtx.generalInformation
                                                      }
                                                />
                                          </FooterContent>
                                    </FooterItem>
                                    <FooterItem>
                                          <FooterHeading>
                                                <h4>Quick Links</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <ul>
                                                      <FooterMenuItem
                                                            link="/faqs"
                                                            title="FAQs"
                                                      />
                                                      <FooterMenuItem
                                                            link="/blogs"
                                                            title="Blogs and News"
                                                      />
                                                      <FooterMenuItem
                                                            link="/privacy-policy"
                                                            title="Company Policy"
                                                      />
                                                      <FooterMenuItem
                                                            link="/contact-us"
                                                            title="Contact Us"
                                                      />
                                                      <FooterMenuItem
                                                            link="mailto: hr@aircasa.in’"
                                                            title="Career"
                                                      />
                                                </ul>
                                          </FooterContent>
                                    </FooterItem>
                                    <FooterItem>
                                          <FooterHeading>
                                                <h4>Our Services</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <ul>
                                                      <FooterMenuItem
                                                            link="/packages"
                                                            title="Holiday packages"
                                                      />
                                                      {/* <FooterMenuItem title="Heaters" /> */}
                                                      <FooterMenuItem
                                                            link="/hotels"
                                                            title="Hotels"
                                                      />
                                                      <FooterMenuItem
                                                            link="/flights"
                                                            title="Flights"
                                                      />
                                                      <FooterMenuItem title="Visa" />
                                                      <FooterMenuItem title="Travel insurance" />
                                                      <FooterMenuItem title="Travel loans" />
                                                </ul>
                                          </FooterContent>
                                    </FooterItem>
                                    <FooterItem className="mobile__footer">
                                          <FooterHeading>
                                                <h4>Contact Us</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <FooterContact>
                                                      {
                                                            generalCtx
                                                                  .generalInformation
                                                                  .address
                                                      }
                                                </FooterContact>
                                                <FooterContact>
                                                      <Link
                                                            to={`tel:${generalCtx.generalInformation.phone_number}`}
                                                      >
                                                            {
                                                                  generalCtx
                                                                        .generalInformation
                                                                        .phone_number
                                                            }
                                                      </Link>
                                                </FooterContact>
                                                <FooterContact>
                                                      <Link
                                                            to={`mailto:${generalCtx.generalInformation.email}`}
                                                      >
                                                            {
                                                                  generalCtx
                                                                        .generalInformation
                                                                        .email
                                                            }
                                                      </Link>
                                                </FooterContact>
                                          </FooterContent>
                                    </FooterItem>
                              </Row>
                        </FooterBody>
                  </FooterContainer>
                  <FooterCredential>
                        <FooterCredentialContainer>
                              <div className="footer__copyright">
                                    Copyright © 2024 by{' '}
                                    <span>Aircasa Inc.</span>
                              </div>
                              <div className="footer__credit">
                                    Designed & Developed By:{' '}
                                    <Link
                                          to="https://hidako.com"
                                          target="_blank"
                                    >
                                          Hidako Technlology.
                                    </Link>
                              </div>
                        </FooterCredentialContainer>
                  </FooterCredential>
            </>
      );
};

export default Footer;
