import React from 'react';
import { DestinationMonthTitle } from '../DestinationMonth/style';
import { FormButton, FormInput } from '../../../forms';
import { Col, Form, Row } from 'react-bootstrap';

const PlanForm = ({
      setName,
      setEmail,
      setMobileNumber,
      loading,
      handleForm,
      name,
      email,
      mobileNumber
}) => {
      return (
            <>
                  <DestinationMonthTitle>
                        Enter your details to personalise your trip
                  </DestinationMonthTitle>
                  <Col lg={6}>
                        <Form onSubmit={handleForm}>
                              <Row className="g-3">
                                    <FormInput
                                          classes={12}
                                          placeholder="Name"
                                          handleChange={setName}
                                          value={name}
                                    />
                                    <FormInput
                                          classes={12}
                                          placeholder="Mobile Number"
                                          handleChange={setMobileNumber}
                                          value={mobileNumber}
                                    />
                                    <FormInput
                                          classes={12}
                                          placeholder="E-Mail ID"
                                          handleChange={setEmail}
                                          value={email}
                                    />
                                    <FormButton
                                          title="Submit"
                                          loading={loading}
                                    />
                              </Row>
                        </Form>
                  </Col>
            </>
      );
};

export default PlanForm;
