import React from 'react';
import { PackageAccordionContainer, PackageAccordionHeading } from './styles';
import { Accordion } from 'react-bootstrap';

const PackageAccordion = ({ item }) => {
      return (
            <>
                  <PackageAccordionContainer>
                        <hr />
                        <div
                              dangerouslySetInnerHTML={{
                                    __html: item.description
                              }}
                        ></div>
                        <PackageAccordionHeading className="mt-4 mb-4">
                              Itinerary
                        </PackageAccordionHeading>
                        <Accordion className="border-none">
                              {item?.itineraries?.length > 0 &&
                                    item?.itineraries?.map(
                                          (data, index) =>
                                                data.title !== null && (
                                                      <Accordion.Item
                                                            eventKey={index}
                                                      >
                                                            <Accordion.Header>
                                                                  {data.title}
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: data.description
                                                                        }}
                                                                  ></div>
                                                            </Accordion.Body>
                                                      </Accordion.Item>
                                                )
                                    )}
                        </Accordion>
                  </PackageAccordionContainer>
            </>
      );
};

export default PackageAccordion;
