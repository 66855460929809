import styled from 'styled-components';

export const ImagelayoutContainer = styled.div`
      margin: 50px 0;

      &:first-child {
            margin-top: 0;
      }
`;

export const ImageLayout = styled.div`
      height: ${(props) => props.height};

      img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
      }

      @media screen and (max-width: 600px) {
            height: 190px;
            margin-bottom: ${(props) => props.margin};
      }
`;
