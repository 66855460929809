import React from 'react';
import { PackagePricingContainer } from './styles';

const PackagePricing = ({ price, priceType }) => {
      return (
            <>
                  <PackagePricingContainer>
                        <span>Starting From</span>
                        <br />
                        <h5>INR ₹ {price}</h5>
                        <p>{priceType}</p>
                  </PackagePricingContainer>
            </>
      );
};

export default PackagePricing;
