import styled from 'styled-components';

export const FlightItem = styled.div`
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      /* border: 1px solid #efefef; */
      border-radius: 5px;
      padding: 18px 25px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
      background-color: ${(props) => props.theme.white};

      h3 {
            font-size: 16px;
            font-weight: 600;
            color: ${(props) => props.theme.primary};
      }
      p {
            font-size: 12px;
            color: #737373;
            margin-bottom: 0;
      }
`;
export const FlightContent = styled.div``;
export const FlightIcon = styled.div`
      span {
            font-size: 35px;
            color: ${(props) => props.theme.primary};
      }
      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' 0, 'opsz' 24;
      }
`;
