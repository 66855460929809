import React from 'react';
import { PrivacyNavItem, PrivacyNavWrapper } from './styles';
import { Link, useLocation } from 'react-router-dom';

const PrivacyNavbar = () => {
      const navigations = [
            {
                  id: 1,
                  title: 'Terms of Service',
                  link: '/terms-of-service'
            },
            {
                  id: 2,
                  title: 'Privacy Policy',
                  link: '/privacy-policy'
            },
            {
                  id: 3,
                  title: 'Referral Policy',
                  link: '/referral-policy'
            }
      ];
      const location = useLocation();
      const currentLocation = location.pathname;

      return (
            <>
                  <PrivacyNavWrapper>
                        {navigations?.map((nav, index) => (
                              <PrivacyNavItem key={index}>
                                    {currentLocation === nav.link ? (
                                          <Link
                                                to={nav.link}
                                                className="active"
                                          >
                                                <span>{nav.title}</span>
                                          </Link>
                                    ) : (
                                          <Link to={nav.link}>{nav.title}</Link>
                                    )}
                              </PrivacyNavItem>
                        ))}
                  </PrivacyNavWrapper>
            </>
      );
};

export default PrivacyNavbar;
