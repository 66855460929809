import React, { useEffect, useRef, useState } from 'react';
import {
      AdultChildSection,
      AdultChildTitle,
      AdultSection,
      ChildSection,
      FormAdditional,
      FormAdditionalCounter,
      FormAdditionalCounterIcon,
      FormAdditionalCounterInput,
      FormAdditionalHeader,
      FormAdditionalTitle,
      FormInfo,
      FormWrapper
} from '../styles';
import { Col, Form, Row } from 'react-bootstrap';

const TravelerBox = ({ data, setData, type }) => {
      const [show, setShow] = useState(false);
      const divRef = useRef();
      const handleClick = () => {
            setShow(true);
      };
      const handleOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                  setShow(false);
            }
      };

      useEffect(() => {
            document.addEventListener('click', handleOutside);

            return () => {
                  document.removeEventListener('click', handleOutside);
            };
      }, []);

      const values = [...data];

      const [roomCount, setRoomCount] = useState(1);
      const totalChildren = data.reduce(
            (acc, item) => acc + parseInt(item.children),
            0
      );
      const totalRooms = data.length;
      const totalAdults = data.reduce(
            (acc, item) => acc + parseInt(item.adult),
            0
      );

      const incrementRoom = () => {
            if (roomCount < 5) {
                  setData([
                        ...data,
                        {
                              room: '1',
                              children: '0',
                              adult: '1',
                              childrenAges: []
                        }
                  ]);

                  setRoomCount(roomCount + 1);
            }
      };

      const decrementRoom = () => {
            if (roomCount > 1) {
                  const newData = [...data];
                  newData.pop();
                  setData(newData);
                  setRoomCount(roomCount - 1);
            }
      };

      const handleIncrementChildren = (index) => {
            const newData = [...data];
            if (newData[index].children < 4) {
                  newData[index].children = (
                        parseInt(newData[index].children) + 1
                  ).toString();
                  newData[index].childrenAges.push('');
                  setData(newData);
            }
      };
      const handleDecrementChildren = (index) => {
            const newData = [...data];
            if (newData[index].children > 0) {
                  newData[index].children = (
                        parseInt(newData[index].children) - 1
                  ).toString();
                  newData[index].childrenAges.pop();
                  setData(newData);
            }
      };

      const handleIncrementAdult = (index) => {
            const newData = [...data];
            if (newData[index].adult < 10) {
                  newData[index].adult = (
                        parseInt(newData[index].adult) + 1
                  ).toString();
                  setData(newData);
            }
      };
      const handleDecrementAdult = (index) => {
            const newData = [...data];
            if (newData[index].adult > 1) {
                  newData[index].adult = (
                        parseInt(newData[index].adult) - 1
                  ).toString();
                  setData(newData);
            }
      };

      const handleChildAgeChange = (index, childIndex, value) => {
            const newData = [...data];
            newData[index].childrenAges[childIndex] = value;
            setData(newData);
      };

      return (
            <>
                  <FormWrapper onClick={handleClick} ref={divRef}>
                        <Form.Control type={type ? 'hidden' : 'text'} />
                        <FormInfo>
                              <span
                                    style={{
                                          fontSize: '13px'
                                    }}
                              >
                                    {totalRooms} room, {totalAdults} adults,{' '}
                                    {totalChildren} children
                              </span>
                        </FormInfo>
                        <FormAdditional className={show ? 'show' : ''}>
                              <FormAdditionalHeader className="px-3 py-2 d-flex align-items-center justify-content-between">
                                    <FormAdditionalTitle>
                                          Rooms
                                    </FormAdditionalTitle>
                                    <FormAdditionalCounter className="  ">
                                          <FormAdditionalCounterIcon
                                                className="align-items-center justify-content-center substract"
                                                onClick={() => decrementRoom()}
                                          >
                                                <span>-</span>
                                          </FormAdditionalCounterIcon>
                                          <FormAdditionalCounterInput className="align-items-center justify-content-center">
                                                <input
                                                      type="number"
                                                      value={roomCount}
                                                      min="1"
                                                      max="5"
                                                      step="1"
                                                      readOnly={true}
                                                      data-role="none"
                                                />
                                          </FormAdditionalCounterInput>
                                          <FormAdditionalCounterIcon
                                                onClick={incrementRoom}
                                                className="align-items-center justify-content-center add"
                                          >
                                                <span>+</span>
                                          </FormAdditionalCounterIcon>
                                    </FormAdditionalCounter>
                              </FormAdditionalHeader>
                              {values.map((item, index) => (
                                    <>
                                          <AdultChildSection
                                                key={index}
                                                className="d-flex align-items-center justify-content-between px-3 py-2"
                                          >
                                                <AdultSection>
                                                      <AdultChildTitle>
                                                            Adults (12+)
                                                      </AdultChildTitle>
                                                      <FormAdditionalCounter className="  ">
                                                            <FormAdditionalCounterIcon
                                                                  className="align-items-center justify-content-center substract"
                                                                  onClick={() =>
                                                                        handleDecrementAdult(
                                                                              index
                                                                        )
                                                                  }
                                                            >
                                                                  <span>-</span>
                                                            </FormAdditionalCounterIcon>
                                                            <FormAdditionalCounterInput className="align-items-center justify-content-center">
                                                                  <input
                                                                        type="number"
                                                                        value={
                                                                              item.adult
                                                                        }
                                                                        min="1"
                                                                        max="5"
                                                                        step="1"
                                                                        readOnly={
                                                                              true
                                                                        }
                                                                        data-role="none"
                                                                  />
                                                            </FormAdditionalCounterInput>
                                                            <FormAdditionalCounterIcon
                                                                  className="align-items-center justify-content-center add"
                                                                  onClick={() =>
                                                                        handleIncrementAdult(
                                                                              index
                                                                        )
                                                                  }
                                                            >
                                                                  <span>+</span>
                                                            </FormAdditionalCounterIcon>
                                                      </FormAdditionalCounter>
                                                </AdultSection>
                                                <ChildSection>
                                                      <AdultChildTitle>
                                                            Childern
                                                      </AdultChildTitle>
                                                      <FormAdditionalCounter className="  ">
                                                            <FormAdditionalCounterIcon
                                                                  className="align-items-center justify-content-center substract"
                                                                  onClick={() =>
                                                                        handleDecrementChildren(
                                                                              index
                                                                        )
                                                                  }
                                                            >
                                                                  <span>-</span>
                                                            </FormAdditionalCounterIcon>
                                                            <FormAdditionalCounterInput className="align-items-center justify-content-center">
                                                                  <input
                                                                        type="number"
                                                                        value={
                                                                              item.children
                                                                        }
                                                                        min="1"
                                                                        max="5"
                                                                        step="1"
                                                                        readOnly={
                                                                              true
                                                                        }
                                                                        data-role="none"
                                                                  />
                                                            </FormAdditionalCounterInput>
                                                            <FormAdditionalCounterIcon
                                                                  className="align-items-center justify-content-center add"
                                                                  onClick={() =>
                                                                        handleIncrementChildren(
                                                                              index
                                                                        )
                                                                  }
                                                            >
                                                                  <span>+</span>
                                                            </FormAdditionalCounterIcon>
                                                      </FormAdditionalCounter>
                                                </ChildSection>
                                          </AdultChildSection>
                                          <Row className=" px-3 mb-3">
                                                {item.childrenAges.map(
                                                      (age, childIndex) => (
                                                            <Col lg={6}>
                                                                  <Form.Select
                                                                        key={
                                                                              childIndex
                                                                        }
                                                                        defaultValue={
                                                                              age
                                                                        }
                                                                        className="p-1"
                                                                        style={{
                                                                              fontSize: '12px'
                                                                        }}
                                                                        onChange={(
                                                                              e
                                                                        ) =>
                                                                              handleChildAgeChange(
                                                                                    index,
                                                                                    childIndex,
                                                                                    e
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  >
                                                                        <option value="">
                                                                              Child
                                                                              Age
                                                                        </option>
                                                                        <option value="< 2 yrs">
                                                                              {
                                                                                    '<'
                                                                              }{' '}
                                                                              2
                                                                              yrs
                                                                        </option>
                                                                        <option value="2 yrs">
                                                                              2
                                                                              yrs
                                                                        </option>
                                                                        <option value="3 yrs">
                                                                              3
                                                                              yrs
                                                                        </option>
                                                                        <option value="4 yrs">
                                                                              4
                                                                              yrs
                                                                        </option>
                                                                        <option value="5 yrs">
                                                                              5
                                                                              yrs
                                                                        </option>
                                                                        <option value="6 yrs">
                                                                              6
                                                                              yrs
                                                                        </option>
                                                                        <option value="7 yrs">
                                                                              7
                                                                              yrs
                                                                        </option>
                                                                        <option value="8 yrs">
                                                                              8
                                                                              yrs
                                                                        </option>
                                                                        <option value="9 yrs">
                                                                              9
                                                                              yrs
                                                                        </option>
                                                                        <option value="10 yrs">
                                                                              10
                                                                              yrs
                                                                        </option>

                                                                        <option value="11 yrs">
                                                                              11
                                                                              yrs
                                                                        </option>
                                                                  </Form.Select>
                                                            </Col>
                                                      )
                                                )}
                                          </Row>
                                    </>
                              ))}
                        </FormAdditional>
                  </FormWrapper>
            </>
      );
};

export default TravelerBox;
