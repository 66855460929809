import React, { useEffect, useState } from 'react';
import { HeaderContainer, HeaderTop } from './styles';
import { Container } from 'react-bootstrap';
import Navigation from './Navigation';

const Header = () => {
      const [isSticky, setIsSticky] = useState(false);

      useEffect(() => {
            const handleScroll = () => {
                  const scrollTop = window.pageYOffset;
                  setIsSticky(scrollTop > 0);
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                  window.removeEventListener('scroll', handleScroll);
            };
      }, []);

      return (
            <>
                  <HeaderContainer>
                        <header>
                              <Navigation isSticky={isSticky} />
                        </header>
                  </HeaderContainer>
            </>
      );
};

export default Header;
