import { Link } from 'react-router-dom';
import styled from 'styled-components';

// offer button css
export const OfferButtonWrapper = styled.div``;
export const OfferButtonLink = styled(Link)`
      width: 100%;
      height: 100%;
      font-size: 14px;
      font-weight: 700;
      text-transform: capitalize;
      line-height: 20px;
      color: ${(props) => (props.titleColor ? props.theme.primary : '#fff')};
      background-color: ${(props) => (props.color ? props.color : '')};
      border-radius: 5px 5px 5px 5px;
      padding: 15px 30px;
      margin-top: 20px;
      &:hover {
            background-color: ${(props) => props.theme.primary};
            transition: background 0.3s linear;
            color: ${(props) => (props.titleColor ? '#fff' : '#fff')};
      }
`;
export const OfferItemContainer = styled.div`
      position: relative;
`;
export const OfferDetail = styled.div`
      min-height: 300px;
      text-align: left;
      padding: 60px 50px 45px;
      h2 {
            font-size: 30px;
            line-height: 30px;
            margin-bottom: 10px;
      }
      p {
            margin-bottom: 30px;
            font-size: 16px;
            font-weight: 500;
      }
`;
export const OfferImage = styled.div`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.4s;
      z-index: -1;
      img {
            border-radius: 30px;
      }
`;
