import React from 'react';

const RatingIcon = () => {
      return (
            <>
                  <i className="bx bxs-star"></i>
            </>
      );
};

export default RatingIcon;
