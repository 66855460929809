import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const ContactContainer = styled.div`
   padding: 5rem 0;
   position: relative;
`;

export const ContactContent = styled.div`
   p {
      font-size: 18px;
   }
`;

export const ContactSubTitle = styled.div`
   font-size: 24px;
   font-family: var(--dancing__font);
   color: ${(props) => props.theme.primary};
`;

export const ContactHeader = styled.h4`
   font-size: 40px;

   @media screen and (max-width: 600px) {
      font-size: 32px;
   }
`;

export const ContactForm = styled(Form)`
   .form-control {
      box-shadow: none;
      background-color: #ebf7ff;
      border: 0;
      padding: 12px 20px;
   }

   button {
      background: linear-gradient(
         to right,
         ${(props) => props.theme.primary},
         ${(props) => props.theme.primary}
      );
      color: ${(props) => props.theme.white};
      padding: 10px 20px;
      border: 0;
      border-radius: 5px;
      text-transform: uppercase;
      transition: all 0.3s ease-in;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

      &:hover {
         background: linear-gradient(to right, #224082, #336699);
         box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      }
   }
`;

export const ContactSocialMedia = styled.div`
   ul {
      padding: 0;
      margin: 0;

      li {
         display: inline-block;
         margin-right: 10px;
      }
   }

   @media screen and (max-width: 600px) {
      margin-bottom: 30px;
   }
`;

export const ContactAnimation = styled.div`
   background-image: url(https://demo2.pavothemes.com/triply/wp-content/uploads/2020/11/contact-img-1.png);
   z-index: -1;
   background-position: top left;
   background-repeat: no-repeat;
   background-size: auto;
   position: absolute;
   top: 0;
   left: 0;

   transition-property: transform, opacity;
   transition-timing-function: cubic-bezier(0, 0.33, 0.07, 1.03);
   transition-duration: 1s;

   width: 100%;
   height: 140%;
   --translateY: -18.41456px;
   transform: translateY(var(--translateY));
`;
