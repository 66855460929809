import styled from 'styled-components';

export const PrivacyPageContainer = styled.div`
      ${'' /* padding: 20px; */}
      background-image: url('/images/img/privacy/paper-texture.jpg');
      ${'' /* background-size: cover; */}
      background-position: center;
      background-size: contain;
      padding-bottom: 80px;
`;
