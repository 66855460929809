import styled from 'styled-components';

export const SelectedItemsContainer = styled.div`
      margin-top: 1rem;
`;

export const SelectedItemsTitle = styled.div`
      color: #888;
      margin-bottom: 5px;
`;

export const SelectedItemsBody = styled.div`
      display: flex;
      gap: 12px;
`;
