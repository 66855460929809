export const destinations = [
   {
      id: '1',
      title: 'Beach Fun with Friends',
      description:
         'Simplify Globally pandemic e-business via functionalized e-business. Rapidiously covalent leadership whereas distributed results drive.',
      link: '',
      image: '/images/img/destination/dest_1_1.jpg',
      initial: '20000',
      price: '15000',
      location: 'Goa, India'
   },
   {
      id: '2',
      title: 'Costal Waves in Greece',
      description:
         'Simplify Globally pandemic e-business via functionalized e-business. Rapidiously covalent leadership whereas distributed results drive.',
      link: '',
      image: '/images/img/destination/dest_1_2.jpg',
      initial: '20000',
      price: '15000',
      location: 'Greece'
   },
   {
      id: '3',
      title: 'City Walk in Italy',
      description:
         'Simplify Globally pandemic e-business via functionalized e-business. Rapidiously covalent leadership whereas distributed results drive.',
      link: '',
      image: '/images/img/destination/dest_1_3.jpg',
      initial: '20000',
      price: '15000',
      location: 'Italy'
   },
   {
      id: '4',
      title: 'Wonderful Paris Tour',
      description:
         'Simplify Globally pandemic e-business via functionalized e-business. Rapidiously covalent leadership whereas distributed results drive.',
      link: '',
      image: '/images/img/destination/dest_1_4.jpg',
      initial: '20000',
      price: '15000',
      location: 'Paris, London'
   },
   {
      id: '4',
      title: 'Wonderful Paris Tour',
      description:
         'Simplify Globally pandemic e-business via functionalized e-business. Rapidiously covalent leadership whereas distributed results drive.',
      link: '',
      image: '/images/img/destination/dest_1_4.jpg',
      initial: '20000',
      price: '15000',
      location: 'Paris, London'
   }
];
