import styled from 'styled-components';

export const PackageDetailHeadingContainer = styled.div``;

export const PackageDetailContent = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      @media screen and (max-width: 600px) {
            gap: 30px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                  display: none;
            }
      }
`;

export const PackageDetailLocation = styled.div`
      color: #5e6d77;
      svg {
            width: 20px;
            height: 20px;
            fill: #5e6d77;
      }
`;
export const PackageDetailTitle = styled.h2`
      font-size: 28px;
`;
export const PackageDetailInfo = styled.div`
      h5 {
            font-size: 16px;
            font-weight: 500;
      }
      p {
            font-size: 14px;
      }
`;
export const PackageDetailInfoTitle = styled.div``;
export const PackageDetailIcon = styled.div``;
export const PackageDetailItem = styled.div`
      display: flex;
      align-items: center;
      gap: 1rem;

      @media screen and (max-width: 600px) {
            min-width: fit-content;
      }
`;
