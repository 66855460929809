import styled from 'styled-components';

export const FeaturesContainer = styled.div`
      background-image: url(https://gaviaspreview.com/wp/tevily/wp-content/uploads/2021/10/bg-line-bird.png);
      background-position: 75% 10px;
      background-repeat: no-repeat;
      padding: 80px 0 0;

      @media screen and (max-width: 600px) {
            padding-bottom: 50px;
      }
`;
export const FeaturesImage = styled.div``;
export const FeaturesContent = styled.div``;
export const FeaturesHeading = styled.div``;

export const FeaturesWrapper = styled.div`
      margin-top: 40px;
`;
export const FeaturesItem = styled.div``;
export const FeaturesIcon = styled.div`
      width: 80px;
      height: 80px;
      line-height: 70px;
      background-color: ${(props) => props.theme.white};
      border: 5px solid ${(props) => props.theme.primary};
      border-radius: 50%;
      font-size: 30px;
      color: ${(props) => props.theme.primary};
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
            fill: ${(props) => props.theme.primary};
            width: 30px;
            height: 30px;
      }
`;
export const FeaturesDetail = styled.div`
      width: calc(100% - 80px);
      h3 {
            font-size: 20px;
            margin-bottom: 10px;
            margin-top: -0.3em;
      }
      p {
            margin-bottom: -0.5em;
            font-size: 14px;
      }

      @media screen and (max-width: 600px) {
            h3 {
                  font-size: 18px;
                  margin-bottom: 8px;
            }
      }
`;
