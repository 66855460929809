import React from 'react';
import {
      SelectedContainer,
      SelectedItemContent,
      SelectedItemIcon,
      SelectedItemInner,
      SelectedItemOuter,
      SelectedTitle
} from './style';

const SelectedItem = ({ handleChange, title, icon }) => {
      return (
            <>
                  <SelectedContainer onClick={handleChange}>
                        {title && (
                              <SelectedItemContent>
                                    <SelectedItemIcon>{icon}</SelectedItemIcon>
                                    <SelectedTitle>{title}</SelectedTitle>
                              </SelectedItemContent>
                        )}
                        <SelectedItemOuter margin={!title && '43px'}>
                              {title && <SelectedItemInner />}
                        </SelectedItemOuter>
                  </SelectedContainer>
            </>
      );
};

export default SelectedItem;
