import React, { useState } from 'react';
import { HotelContainer, HotelSection } from '../hotel/styles';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import EnquiryBanner from '../../components/common/EnquiryBanner';
import { HotelChains } from '../hotel/components';
import AvailabelFlights from './AvailabelFlights';
import axios from 'axios';
import $ from 'jquery';
import toast from 'react-hot-toast';

const types = [
      {
            title: 'Economy'
      },
      {
            title: 'Premium Economy'
      },
      {
            title: 'Business'
      },
      {
            title: 'First Class'
      }
];

const FlightPage = () => {
      const [loading, setLoading] = useState(false);
      const [from, setFrom] = useState('');
      const [destination, setDestination] = useState('');
      const [checkin, setCheckin] = useState('');
      const [checkout, setCheckout] = useState('');
      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [mobileNumber, setMobileNumber] = useState('');
      const [message, setMessage] = useState('');
      const [show, setShow] = useState(false);

      const [flightType, setFlightType] = useState('');

      const [travelers, setTravelers] = useState([
            {
                  room: '1',
                  children: '0',
                  adult: '1',
                  childrenAges: []
            }
      ]);

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  from: from,
                  destination: destination,
                  depart_on: checkin,
                  return_on: checkout,
                  name: name,
                  email: email,
                  mobile_number: mobileNumber,
                  message: message,
                  flight_type: flightType,
                  travelers: travelers
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/flights`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setDestination('');
                              setFrom('');
                              setCheckin('');
                              setCheckout('');
                              setFlightType('');
                              setTravelers([
                                    {
                                          room: '1',
                                          children: '0',
                                          adult: '1',
                                          childrenAges: []
                                    }
                              ]);
                              setTimeout(() => {
                                    setShow(false);
                              }, 1500);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.from) {
                                    toast.error(response.data.message.from);
                              }
                              if (response.data.message.destination) {
                                    toast.error(
                                          response.data.message.destination
                                    );
                              }
                              if (response.data.message.depart_on) {
                                    toast.error(
                                          response.data.message.depart_on
                                    );
                              }
                              if (response.data.message.return_on) {
                                    toast.error(
                                          response.data.message.return_on
                                    );
                              }
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.mobile_number) {
                                    toast.error(
                                          response.data.message.mobile_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                              if (response.data.message.travelers) {
                                    toast.error(
                                          response.data.message.travelers
                                    );
                              }
                              if (response.data.message.flight_type) {
                                    toast.error(
                                          response.data.message.flight_type
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <HotelContainer>
                        <BreadcrumbBox planWithExpert="show" title="Flights" />
                        <HotelSection>
                              <EnquiryBanner
                                    from={true}
                                    to={true}
                                    setFrom={setFrom}
                                    fromValue={from}
                                    setDestination={setDestination}
                                    destination={destination}
                                    setCheckin={setCheckin}
                                    setCheckout={setCheckout}
                                    setName={setName}
                                    setEmail={setEmail}
                                    setMessage={setMessage}
                                    setMobileNumber={setMobileNumber}
                                    handleForm={handleForm}
                                    loading={loading}
                                    checkin={checkin}
                                    checkout={checkout}
                                    travelers={travelers}
                                    setTravelers={setTravelers}
                                    types={types}
                                    flightType={flightType}
                                    setFlightType={setFlightType}
                                    show={show}
                                    setShow={setShow}
                                    description="For inquiries about special pre-purchased flight fares, please send us your query."
                              />
                              <AvailabelFlights />
                        </HotelSection>
                        <HotelChains title="Our Top Flight Chains" />
                  </HotelContainer>
            </>
      );
};

export default FlightPage;
