import React from 'react';
import { ApplicationHead } from './styles';
import MainTitle from '../../HomeTitle/MainTitle';
import SubTitle from '../../HomeTitle/SubTitle';

const ApplicationHeading = () => {
      return (
            <>
                  <ApplicationHead>
                        <SubTitle
                              subtitle={'Our destination lists'}
                              right="-12px"
                        />
                        <MainTitle
                              title={
                                    'Checkout Beautiful Places Arround the World'
                              }
                              height="1.4"
                        />
                  </ApplicationHead>
            </>
      );
};

export default ApplicationHeading;
