import React, { useState } from 'react';
import { PackageSliderContainer } from '../../../frontend/packages/PackageDetail/components/PackageDetailSlider/styles';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import {
      FreeMode,
      Navigation,
      Thumbs,
      EffectFade,
      Autoplay
} from 'swiper/modules';
import { DestinationSliderSlide, DestinationSliderWrapper } from './styles';

const DestinationSlider = ({ loading, images, image }) => {
      const [thumbsSwiper, setThumbsSwiper] = useState(null);

      return (
            <>
                  <PackageSliderContainer>
                        <DestinationSliderWrapper
                              style={{
                                    '--swiper-navigation-color': '#fff',
                                    '--swiper-pagination-color': '#fff'
                              }}
                              autoplay={{
                                    delay: 8000,
                                    disableOnInteraction: false
                              }}
                              effect={'fade'}
                              spaceBetween={10}
                              thumbs={{ swiper: thumbsSwiper }}
                              modules={[
                                    FreeMode,
                                    Navigation,
                                    Thumbs,
                                    EffectFade,
                                    Autoplay
                              ]}
                              loop={true}
                              className="mySwiper2"
                        >
                              {!loading && image && (
                                    <DestinationSliderSlide>
                                          <img src={image} />
                                    </DestinationSliderSlide>
                              )}
                              {!loading &&
                                    images &&
                                    images?.map((item, index) => (
                                          <DestinationSliderSlide key={index}>
                                                <img
                                                      src={item.image}
                                                      alt={item.title}
                                                />
                                          </DestinationSliderSlide>
                                    ))}
                        </DestinationSliderWrapper>
                  </PackageSliderContainer>
            </>
      );
};

export default DestinationSlider;
