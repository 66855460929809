import styled from 'styled-components';

export const BlogDetailImage = styled.div`
      border-radius: 10px;
      margin: 20px 0;

      img {
            width: 100%;
            height: 100%;
            border-radius: 20px;
      }
`;

export const BlogDetailBody = styled.div`
      h5 {
            font-weight: 500;
      }

      p {
            color: #666666;
      }

      ul {
            color: #666666;
      }

      img {
            border-radius: 10px;
            width: 100%;
            margin-bottom: 10px;
      }
`;
