import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const MobileButton = styled(Button)`
      padding: 0;
      height: 28px;

      span {
            font-size: 28px;
      }
`;
