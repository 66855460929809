import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { createContext } from 'react';

const LogoContext = createContext({
      setting: [],
      generalInformation: [],
      experiences: []
});

export const LogoContextProvider = (props) => {
      const [loading, setLoading] = useState(false);
      const [generalInformation, setGeneralInformation] = useState([]);
      const [setting, setSetting] = useState([]);
      const [experiences, setExperiences] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/official_logo`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setSetting(response.data.settings);
                        }
                  })
                  .catch((error) => {
                        console.log(error);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/general_information`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setGeneralInformation(response.data.settings);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/experiences`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setExperiences(response.data.experiences);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const context = {
            setting: setting,
            generalInformation: generalInformation,
            experiences: experiences
      };

      return (
            <>
                  <LogoContext.Provider value={context}>
                        {props.children}
                  </LogoContext.Provider>
            </>
      );
};

export default LogoContext;
