import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuItem = ({ title, link, children, index }) => {
      return (
            <>
                  <li
                        key={index}
                        className={!children ? 'position-relative' : ''}
                  >
                        <NavLink
                              to={link ? link : '#'}
                              className={`d-flex align-items-center gap-1  ${({
                                    isActive
                              }) => (isActive ? 'activeLink' : '')}`}
                        >
                              {title}
                              {/* {children && children} */}
                        </NavLink>
                        {children}
                  </li>
            </>
      );
};

export default React.memo(MenuItem);
