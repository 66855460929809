import styled from 'styled-components';

export const EnquiryFormContainer = styled.div`
      ${'' /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16); */}
      background-position: center;
      background-size: cover;
      color: ${(props) =>
            props.status ? props.theme.black : props.theme.blank};
      border: 0;
      border-radius: 4px;
      margin-top: 20px;
      padding: ${(props) => (props.padding ? props.padding : '15px 20px')};
      margin-bottom: 10px;
      position: relative;
      &:after {
            content: ${(props) => (props.status ? props.status : '')};
            width: 100%;
            height: 100%;
            background: #0007;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 4px;
      }
      h3 {
            font-size: 18px;
            margin-bottom: 0px;
      }
      i {
            font-size: 20px;
      }
      .form-control:focus {
            box-shadow: none;
      }
      .form-select:focus {
            box-shadow: none;
      }
      input {
            font-size: 15px;
            background-color: transparent;
      }
      input {
            &:focus {
                  background: transparent;
                  border-color: ${(props) => props.theme.primary};
                  color: ${(props) => props.theme.black};
            }
      }
      select {
            font-size: 15px;
            color: #555;
      }
      fieldset {
            border: 2px solid #fff;
      }
      textarea {
            background: transparent;
            /* color: ${(props) => props.theme.white}; */
            &:focus {
                  background: transparent;
                  /* color: ${(props) => props.theme.white}; */
                  border-color: ${(props) => props.theme.primary};
            }
      }

      .form-control:disabled {
            background: transparent;
      }
      legend {
            float: unset;
            padding: 0;
            height: 11px;
            font-size: 0.75em;
            -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      }
`;
export const EnquiryFormHeaing = styled.div`
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d6d6d6;
`;
