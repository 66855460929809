import React from 'react';

const MoonIcon = () => {
      return (
            <>
                  <i className="bx bx-moon"></i>
            </>
      );
};

export default MoonIcon;
