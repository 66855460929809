import styled, { keyframes } from 'styled-components';

const preloaderWraper = keyframes`
      0% {
            transform: scale(1);
      } 50% {
            transform: scale(1.2);
      } 100% {
            transform: none;
      }
`;

const preloader = keyframes`
      0% {
            transform: translateY(180%) scale(1);
      } 50% {
            transform: translateY(0%) scale(1.2);
      } 100% {
            transform: translateY(-180%);
      }
`;

const changeColorAnimation = keyframes`
  0%, 100% {
    color: #3b5384;
  }
  50% {
    color: #F29727;
  }
`;

export const PreloaderContainer = styled.div`
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999999;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: white;
      transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
`;

export const PreloaderWrap = styled.div`
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 72px;
      border-radius: 30px;
      background-color: white;
      box-shadow: 0px 2px 24px 0px #00000014;
      animation-name: ${preloaderWraper};
      animation-iteration-count: infinite;
      animation-duration: 1.8s;
      animation-timing-function: ease;
`;

export const PreloaderIcon = styled.div`
      position: absolute;
      animation-name: ${preloader};
      animation-iteration-count: infinite;
      animation-duration: 1.8s;
      animation-timing-function: ease;
`;

export const PreloaderTitle = styled.div`
      font-size: 47px;
      font-weight: 600;
      line-height: 68px;
      letter-spacing: 0em;
      margin-top: 16px;
      /* color: #3b5384; */
      animation: ${changeColorAnimation} 1.8s ease infinite;
`;
