import styled from 'styled-components';

export const HotelChainContainer = styled.div`
      display: flex;
      gap: 1.5rem;

      @media screen and (max-width: 600px) {
            overflow-x: scroll;
            width: 100%;
      }


`;
export const HotelChainItem = styled.div`
      img {
            border-radius: 10px;
      }

      @media screen and (max-width: 600px) {
            min-width: 120px;
            height: 120px;

            img {
                  width: 100%;
                  height: 100%;
            }
      }
`;

export const HotelChainsContainer = styled.div``;
