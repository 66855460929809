import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

export const DestinationSliderWrapper = styled(Swiper)`
      /* margin-bottom: 20px; */
      height: 420px;

      @media screen and (max-width: 600px) {
            height: 240px;
            margin-bottom: 20px;
      }
`;

export const DestinationSliderSlide = styled(SwiperSlide)`
      img {
            border-radius: 10px;
            height: 100%;
            width: 100%;
      }
`;
