import styled from 'styled-components';

export const AchievementContainer = styled.div`
      background-image: url('/images/img/achievement/achievement.jpg');
      position: relative;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 80px 0px 80px 0px;
      margin: 80px 0px 0px 0px;
      &:after {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background-color: #0f2454;
            opacity: 0.6;
            z-index: 2;
      }
      .container {
            position: relative;
            z-index: 3;
      }
`;

export const AchievementIconBox = styled.div``;
export const AchievementIcon = styled.div`
      width: 100px;
      height: 100px;
      line-height: 100px;
      margin-bottom: 10px;
      color: #fff;
      border-radius: 50%;
      border: 7px solid rgba(255, 255, 255, 0.1);
      position: relative;
      overflow: hidden;
      display: inline-block;
      ${AchievementIconBox} {
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            line-height: 80px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.2);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            &:last-child {
                  -webkit-transform: translateX(120%);
                  transform: translateX(120%);
                  background: rgba(255, 255, 255, 0.2);
                  -webkit-backdrop-filter: blur(5px);
                  backdrop-filter: blur(5px);
                  color: #fff;
            }
      }
      svg {
            width: 50px;
            height: 50px;
            fill: #fff;
      }
`;
export const AchievementDetail = styled.div``;
export const AchievementTitle = styled.h6`
      font-size: 16px;
      font-weight: 600;
      color: ${(props) => props.theme.white};
    
`;
export const AchievementNumber = styled.h3`
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 36px;
      color: ${(props) => props.theme.white};
`;
export const AchievementImage = styled.div`
      position: absolute;
      width: 50%;
      right: -30%;
      top: ${(props) => (props.transformed ? '0%' : '30%')};
      z-index: 2;
      ${'' /* -webkit-transform: rotateX(180deg); */}
      transform: ${(props) => (props.transformed ? 'rotateX(180deg)' : '')};

      img {
            width: 125px;
            height: 20px;
      }
      @media only screen and (max-width: 992px) {
            display: none;
      }
`;
export const AchievementItem = styled.div`
      position: relative;
      transition: background 0.3s, border 0.3s, border-radius 0.3s,
            box-shadow 0.3s,
            transform var(--e-transform-transition-duration, 0.4s);
      &:hover {
            ${AchievementIconBox} {
                  -webkit-transform: translateX(-120%);
                  transform: translateX(-120%);
                  -webkit-transition: all 0.3s ease;
                  transition: all 0.3s ease;
                  &:last-child {
                        -webkit-transform: translateX(0%);
                        transform: translateX(0%);
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                  }
            }
      }
`;
