import React from 'react';
import { DropdownSubCategoryList, SubCategoryTitle } from '../DropDown/styles';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { AirplaneIcon } from '../../../../../icons';

const Destination = ({ item }) => {
      return (
            <>
                  <DropdownSubCategoryList>
                        {item.destinations &&
                              (item.title === 'International' ? (
                                    <Row>
                                          {item.destinations?.length > 0 &&
                                                item.destinations?.map(
                                                      (subItem, index) => (
                                                            <Col
                                                                  lg={6}
                                                                  key={index}
                                                            >
                                                                  <SubCategoryTitle
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to={`/destinations/${subItem.slug}`}
                                                                        >
                                                                              {/* {subItem.image && (
                                                                              <DropDownImage>
                                                                                    <img
                                                                                          src={
                                                                                                subItem.image
                                                                                          }
                                                                                          alt={
                                                                                                subItem.title
                                                                                          }
                                                                                    />
                                                                              </DropDownImage>
                                                                        )} */}
                                                                              <AirplaneIcon />
                                                                              {
                                                                                    subItem.title
                                                                              }
                                                                        </Link>
                                                                  </SubCategoryTitle>
                                                            </Col>
                                                      )
                                                )}
                                    </Row>
                              ) : (
                                    item.destinations.map((subItem, index) => (
                                          <SubCategoryTitle key={index}>
                                                <Link
                                                      to={`/destinations/${subItem.slug}`}
                                                >
                                                      {/* {subItem.image && (
                                                            <DropDownImage>
                                                                  <img
                                                                        src={
                                                                              subItem.image
                                                                        }
                                                                        alt={
                                                                              subItem.title
                                                                        }
                                                                  />
                                                            </DropDownImage>
                                                      )} */}
                                                      <AirplaneIcon />
                                                      {subItem.title}
                                                </Link>
                                          </SubCategoryTitle>
                                    ))
                              ))}
                  </DropdownSubCategoryList>
            </>
      );
};

export default Destination;
