import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const AvailabelHoteContainer = styled.div`
      padding: 60px 0px;
      background-color: #e2e8f0;
      width: 1170px;
      margin: auto;

      .card-title.h5 {
            font-size: 18px;
            text-transform: capitalize;
            ${'' /* text-align: center; */}
            .material-symbols-outlined {
                  font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0,
                        'opsz' 24;
            }
      }

      @media screen and (max-width: 1200px) {
            width: 100%;
            height: auto;
            padding: 30px 20px;
      }
`;
export const AvailabelHoteTitle = styled.div`
      margin-bottom: 1.5rem;
      text-align: center;

      h2 {
            font-size: 28px;
            font-weight: 500;
            color: #000;
            margin-right: 40px;
      }

      @media screen and (max-width: 600px) {
            margin-bottom: 1rem;
            h2 {
                  font-size: 20px;
                  margin-right: 0;
                  margin-bottom: 0;
            }
      }
`;
export const AvailabelHotelImage = styled.div`
      overflow: hidden;
      margin-bottom: 10px;
      border-radius: 10px;
      transition: transform 0.3s linear;
      img {
            transition: transform 0.3s linear;
      }
      &:hover {
            cursor: pointer;
            img {
                  transform: scale(1.1);
                  transition: transform 0.3s linear;
            }
      }
`;
export const AvailabelHotelContent = styled.div``;
export const AvailabelHotelItem = styled(Row)``;
