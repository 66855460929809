import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import {
      ContactAnimation,
      ContactContainer,
      ContactContent,
      ContactForm,
      ContactHeader,
      ContactSubTitle
} from '../contact/style';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { FormButton, FormInput, FormTextarea } from '../../components/forms';
import { FaqAccordion } from './components';
import CommonButton from '../../components/common/CommonButton';

import $ from 'jquery';
import axios from 'axios';
import toast from 'react-hot-toast';

const FaqPage = () => {
      const [translateY, setTranslateY] = useState(-18.41456);

      useEffect(() => {
            const handleScroll = () => {
                  const scrollPosition = window.scrollY;
                  const newTranslateY = -scrollPosition * 0.5; // Adjust multiplier as needed
                  setTranslateY(newTranslateY);
            };

            window.addEventListener('scroll', handleScroll);
            return () => {
                  window.removeEventListener('scroll', handleScroll);
            };
      }, []);
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [loading, setLoading] = useState(false);

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [message, setMessage] = useState('');
      const [subject, setSubject] = useState('');

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  name: name,
                  email: email,
                  phone_number: phoneNumber,
                  message: message,
                  subject: subject
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/contacts`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTimeout(() => {
                                    handleClose();
                                    $('form').trigger('reset');
                                    toast.success(response.data.message);
                              }, 1500);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                              if (response.data.message.subject) {
                                    toast.error(response.data.message.subject);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <BreadcrumbBox title="Faqs" />

                  <ContactContainer>
                        <ContactAnimation
                              style={{ '--translateY': `${translateY}px` }}
                        />
                        <Container>
                              <Row>
                                    <Col lg={5}>
                                          <ContactContent>
                                                <ContactSubTitle>
                                                      Talk with our team
                                                </ContactSubTitle>

                                                <ContactHeader>
                                                      Send Your Enquiry? <br />
                                                </ContactHeader>
                                                <p className="text-muted">
                                                      Connect with our team for
                                                      a discussion or
                                                      conversation regarding
                                                      your queries, concerns, or
                                                      requirements. Engage
                                                      directly with us to get
                                                      personalized assistance
                                                      and guidance.
                                                </p>
                                                <CommonButton
                                                      title={'Send Enquiry'}
                                                      func={handleShow}
                                                />

                                                <Modal
                                                      show={show}
                                                      onHide={handleClose}
                                                      size="lg"
                                                >
                                                      <Modal.Header closeButton>
                                                            <Modal.Title>
                                                                  Enquiry Form
                                                            </Modal.Title>
                                                      </Modal.Header>
                                                      <Modal.Body>
                                                            <ContactForm
                                                                  onSubmit={
                                                                        handleForm
                                                                  }
                                                            >
                                                                  <Row className="g-4">
                                                                        <FormInput
                                                                              placeholder="Your Name"
                                                                              classes={
                                                                                    12
                                                                              }
                                                                              handleChange={
                                                                                    setName
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              placeholder="Email Address"
                                                                              classes={
                                                                                    12
                                                                              }
                                                                              handleChange={
                                                                                    setEmail
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              placeholder="Phone Number"
                                                                              handleChange={
                                                                                    setPhoneNumber
                                                                              }
                                                                        />
                                                                        <FormInput
                                                                              placeholder="Subject"
                                                                              handleChange={
                                                                                    setSubject
                                                                              }
                                                                        />
                                                                        <FormTextarea
                                                                              placeholder="Your Questions"
                                                                              classes={
                                                                                    12
                                                                              }
                                                                              handleChange={
                                                                                    setMessage
                                                                              }
                                                                        />
                                                                        <FormButton
                                                                              classes={
                                                                                    12
                                                                              }
                                                                              title="Send Message"
                                                                              loading={
                                                                                    loading
                                                                              }
                                                                        />
                                                                  </Row>
                                                            </ContactForm>
                                                      </Modal.Body>
                                                </Modal>
                                          </ContactContent>
                                    </Col>
                                    <Col lg={7}>
                                          <FaqAccordion />
                                    </Col>
                              </Row>
                        </Container>
                  </ContactContainer>
            </>
      );
};

export default FaqPage;
