import React from 'react';
import {
      AvailabelHoteContainer,
      AvailabelHotelContent,
      AvailabelHotelImage,
      AvailabelHotelItem,
      AvailabelHoteTitle
} from './styles';
import { Card, Col } from 'react-bootstrap';

export const hotels = [
      {
            id: 1,
            title: 'Maldives',
            image: '/images/img/hotellocation/maldives.jpg'
      },
      {
            id: 2,
            title: 'Italy',
            image: '/images/img/hotellocation/italy.jpg'
      },
      {
            id: 3,
            title: 'United Kingdom',
            image: '/images/img/hotellocation/uk.jpg'
      },
      {
            id: 4,
            title: 'singapore',
            image: '/images/img/hotellocation/singapore.jpg'
      }
];
const AvailabelHotel = () => {
      return (
            <>
                  <AvailabelHoteContainer>
                        <AvailabelHoteTitle className="text-center mb-4">
                              <h2>Book Hotels at Popular Destinations</h2>
                        </AvailabelHoteTitle>
                        <AvailabelHotelContent>
                              <AvailabelHotelItem className="g-4">
                                    {hotels.map((item, index) => (
                                          <Col lg={3} key={index}>
                                                <AvailabelHotelImage>
                                                      <Card.Img
                                                            variant="top"
                                                            src={item.image}
                                                            alt={item.title}
                                                      />
                                                </AvailabelHotelImage>

                                                <Card.Body>
                                                      <Card.Title className=" text-center">
                                                            {item.title}
                                                      </Card.Title>
                                                </Card.Body>
                                          </Col>
                                    ))}
                              </AvailabelHotelItem>
                        </AvailabelHotelContent>
                  </AvailabelHoteContainer>
            </>
      );
};

export default AvailabelHotel;
