import React from 'react';

const FacebookIcon = () => {
      return (
            <>
                  <i className="bx bxl-facebook"></i>
            </>
      );
};

export default FacebookIcon;
