import React from 'react';

const FamilyIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        family_restroom
                  </span>
            </>
      );
};

export default FamilyIcon;
