import styled from 'styled-components';

export const OfferContainer = styled.div`
      position: relative;
      padding: 50px 0px;
      &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            background-color: #f2f2f1;
            -webkit-mask: url('/images/img/offer/download.svg');
            mask: url('/images/img/offer/download.svg');
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: cover;
            mask-size: cover;
            -webkit-mask-position: center center;
            mask-position: center center;
            z-index: -10;
      }
`;
export const OfferHeading = styled.div``;

export const OfferWrapper = styled.div`
      position: relative;
      padding-bottom: 60px;
`;
