import React, { useEffect, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MobileButton } from './styles';
import MobileMenuItem from '../MobileMenuItem';

const MobileMenu = ({ experiences, loading, categories }) => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(!show);
      const [menu, setMenu] = useState(null);

      useEffect(() => {
            if (show) {
                  setMenu(
                        <MobileMenuItem
                              setShow={setShow}
                              loading={loading}
                              categories={categories}
                              services={experiences}
                        />
                  );
            } else {
                  setMenu(null);
            }
      }, [show]);

      return (
            <>
                  <MobileButton onClick={handleShow} variant="btn">
                        {show ? (
                              <span className="material-symbols-outlined">
                                    close
                              </span>
                        ) : (
                              <span className="material-symbols-outlined">
                                    menu
                              </span>
                        )}
                  </MobileButton>

                  <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                    <Link to="/">
                                          <img
                                                src="/images/png/aircasa.png"
                                                className="img-fluid text-start"
                                                width={150}
                                                alt="Aircasa"
                                                height={150}
                                                style={{
                                                      height: '40px',
                                                      objectFit: 'contain',
                                                      width: '115px'
                                                }}
                                          ></img>
                                    </Link>
                              </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>{menu}</Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};

export default MobileMenu;
