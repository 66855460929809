import styled from 'styled-components';

export const SocialMediaContainer = styled.div`
      padding: 11px 0px;
      padding-right: 1rem;
      border-right: 1px solid ${(props) => props.theme.white};
`;
export const SocialMediaLinkItem = styled.div`
      a {
            height: 20px;
            width: 20px;
            display: block;

            svg {
                  margin-top: -4px;
                  height: 20px;
                  width: 20px;
                  fill: ${(props) =>
                        props.color ? props.color : props.theme.primary};
            }

            i {
                  color: ${(props) =>
                        props.color ? props.color : props.theme.primary};
                  font-size: 24px;
            }
      }
`;
