import React from 'react';

const PackageTimeIcon = () => {
      return (
            <>
                  <svg
                        width="32px"
                        height="32px"
                        viewBox="0 0 34 34"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                        <defs></defs>
                        <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                        >
                              <g
                                    id="Tour_Detail_1"
                                    transform="translate(-134.000000, -1005.000000)"
                                    stroke="#5191FA"
                              >
                                    <g
                                          id="tour-detail"
                                          transform="translate(0.000000, 211.000000)"
                                    >
                                          <g
                                                id="feauture"
                                                transform="translate(135.000000, 765.000000)"
                                          >
                                                <g id="Group-3">
                                                      <g
                                                            id="Group"
                                                            transform="translate(0.000000, 25.000000)"
                                                      >
                                                            <g
                                                                  id="ico_clock"
                                                                  transform="translate(0.000000, 5.000000)"
                                                            >
                                                                  <circle
                                                                        id="Oval"
                                                                        cx="16"
                                                                        cy="16"
                                                                        r="16"
                                                                  ></circle>
                                                                  <circle
                                                                        id="Oval"
                                                                        cx="16"
                                                                        cy="17.3333333"
                                                                        r="2.28571429"
                                                                  ></circle>
                                                                  <path
                                                                        d="M16,15.047619 L16,7.04761905"
                                                                        id="Shape"
                                                                  ></path>
                                                                  <path
                                                                        d="M17.6167619,18.9500952 L21.7142857,23.047619"
                                                                        id="Shape"
                                                                  ></path>
                                                            </g>
                                                      </g>
                                                </g>
                                          </g>
                                    </g>
                              </g>
                        </g>
                  </svg>
            </>
      );
};

export default PackageTimeIcon;
