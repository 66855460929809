import styled from 'styled-components';

export const DestinationItemTitle = styled.div`
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 20px;
      transition: all 0.3s ease-in;
      border: 1px solid #fff;
      cursor: pointer;
      background-color: ${(props) => props.background || '#ffffff'};
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

      &:hover {
            border: 1px solid ${(props) => props.theme.primary};
            background-color: ${(props) =>
                  props.background ? props.background : '#22408282'};
            color: ${(props) => props.theme.primary};
      }
`;
