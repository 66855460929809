import React from 'react';
import {
      AboutContainer,
      AboutContent,
      AboutDesc,
      AboutFeatures,
      AboutFeaturesContent,
      AboutFeaturesIcon,
      AboutFeaturesItem,
      AboutImage,
      AboutImageContainer,
      AboutShape,
      AboutTitle
} from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import SubTitle from '../HomeTitle/SubTitle';
import MainTitle from '../HomeTitle/MainTitle';
import { CertificateIcon } from '../../../../icons';
import CommonButton from '../../../common/CommonButton';

const AboutBox = () => {
      return (
            <>
                  <AboutContainer>
                        <Container>
                              <Row>
                                    <Col lg={6}>
                                          <AboutImageContainer>
                                                <AboutImage>
                                                      <img
                                                            src="/images/img/about/about_1_1.jpg"
                                                            alt="aircasa about image"
                                                      />
                                                </AboutImage>
                                                <AboutImage>
                                                      <img
                                                            src="/images/img/about/about_1_2.jpg"
                                                            alt="aircasa about image"
                                                      />
                                                </AboutImage>
                                                <AboutShape>
                                                      <img
                                                            src="/images/img/about/about_shape_1.svg"
                                                            alt="aircasa about image"
                                                      />
                                                </AboutShape>
                                          </AboutImageContainer>
                                    </Col>
                                    <Col lg={6}>
                                          <AboutContent>
                                                <AboutTitle>
                                                      <SubTitle
                                                            subtitle="Get About Us"
                                                            right="true"
                                                      />
                                                      <MainTitle title="We Create Journeys Worth Taking For The Traveler" />
                                                </AboutTitle>
                                                <AboutDesc className="mb-4">
                                                      Progressively impact
                                                      multidisciplinary
                                                      leadership skills via
                                                      e-business leadership
                                                      skills. Holisticly
                                                      repurpose multifunctional
                                                      data before turnkey
                                                      information. Globally
                                                      restore client-focused
                                                      potentialities before
                                                      scalable core
                                                      competencies.
                                                </AboutDesc>
                                                <AboutFeatures>
                                                      <AboutFeaturesItem>
                                                            <AboutFeaturesIcon>
                                                                  <CertificateIcon />
                                                            </AboutFeaturesIcon>
                                                            <AboutFeaturesContent>
                                                                  <h3>
                                                                        24 Years
                                                                        Experience
                                                                  </h3>
                                                                  <p>
                                                                        Holisticly
                                                                        procrastinate
                                                                        real-time
                                                                        solutions
                                                                        for
                                                                        services
                                                                  </p>
                                                            </AboutFeaturesContent>
                                                      </AboutFeaturesItem>
                                                      <AboutFeaturesItem>
                                                            <AboutFeaturesIcon>
                                                                  <CertificateIcon />
                                                            </AboutFeaturesIcon>
                                                            <AboutFeaturesContent>
                                                                  <h3>
                                                                        Best
                                                                        Travel
                                                                        Agents
                                                                  </h3>
                                                                  <p>
                                                                        Holisticly
                                                                        procrastinate
                                                                        real-time
                                                                        solutions
                                                                        for
                                                                        services
                                                                  </p>
                                                            </AboutFeaturesContent>
                                                      </AboutFeaturesItem>
                                                </AboutFeatures>
                                                {/* <CommonButton
                                                      link="about-us"
                                                      title="Discover More"
                                                /> */}
                                          </AboutContent>
                                    </Col>
                              </Row>
                        </Container>
                  </AboutContainer>
            </>
      );
};

export default AboutBox;
