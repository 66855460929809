import React from 'react';
import { PackageLayoutBody, PackageLayoutContainer } from './style';
import { EnquiryContent } from '../../../common/EnquiryBanner/styles';
import { Col, Row } from 'react-bootstrap';
import { PackageItem } from '../../home/PackageBox/components';

const PackageLayout = ({ items }) => {
      return (
            <>
                  {items?.map((item, index) => (
                        <PackageLayoutContainer
                              className={
                                    index != items?.length - 1 ? 'mb-4' : ''
                              }
                              key={index}
                        >
                              <EnquiryContent className=" mb-3">
                                    <h3>{item.title}</h3>
                              </EnquiryContent>
                              <PackageLayoutBody>
                                    <Row className="g-4">
                                          {item?.packages?.length > 0 &&
                                                item?.packages?.map(
                                                      (data, key) => (
                                                            <Col
                                                                  lg={3}
                                                                  key={key}
                                                            >
                                                                  <PackageItem
                                                                        item={
                                                                              data
                                                                        }
                                                                  />
                                                            </Col>
                                                      )
                                                )}
                                    </Row>
                              </PackageLayoutBody>
                        </PackageLayoutContainer>
                  ))}
            </>
      );
};

export default PackageLayout;
