import { CoupleIcon, FamilyIcon, FriendIcon, SoloIcon } from '../icons';

export const countries = [
      {
            id: 1,
            title: 'Delhi',
            slug: 'delhi'
      },
      {
            id: 2,
            title: 'Mumbai',
            slug: 'mumbai'
      },
      {
            id: 3,
            title: 'Bengaluru',
            slug: 'bengaluru'
      },
      {
            id: 4,
            title: 'Chennai',
            slug: 'chennai'
      },
      {
            id: 5,
            title: 'Kolkata',
            slug: 'kolkata'
      },
      {
            id: 6,
            title: 'Hyderabad',
            slug: 'hyderabad'
      },
      {
            id: 7,
            title: 'Pune',
            slug: 'pune'
      },
      {
            id: 8,
            title: 'Ahmedabad',
            slug: 'ahmedabad'
      },
      {
            id: 9,
            title: 'Jaipur',
            slug: 'jaipur'
      },
      {
            id: 10,
            title: 'Kochi',
            slug: 'kochi'
      },
      {
            id: 11,
            title: 'Goa',
            slug: 'goa'
      },
      {
            id: 12,
            title: 'Lucknow',
            slug: 'lucknow'
      },
      {
            id: 13,
            title: 'Thiruvananthapuram',
            slug: 'thiruvananthapuram'
      },
      {
            id: 14,
            title: 'Bhopal',
            slug: 'bhopal'
      },
      {
            id: 15,
            title: 'Indore',
            slug: 'indore'
      },
      {
            id: 16,
            title: 'Coimbatore',
            slug: 'coimbatore'
      },
      {
            id: 17,
            title: 'Chandigarh',
            slug: 'chandigarh'
      },
      {
            id: 18,
            title: 'Nagpur',
            slug: 'nagpur'
      },
      {
            id: 19,
            title: 'Varanasi',
            slug: 'varanasi'
      },
      {
            id: 20,
            title: 'Amritsar',
            slug: 'amritsar'
      },
      {
            id: 21,
            title: 'Guwahati',
            slug: 'guwahati'
      },
      {
            id: 22,
            title: 'Trichy',
            slug: 'trichy'
      },
      {
            id: 23,
            title: 'Mangalore',
            slug: 'mangalore'
      },
      {
            id: 24,
            title: 'Visakhapatnam',
            slug: 'visakhapatnam'
      },
      {
            id: 25,
            title: 'Raipur',
            slug: 'raipur'
      }
];

export const destinations = [
      {
            title: 'Nepal',
            slug: 'nepal'
      },
      {
            title: 'India',
            slug: 'india'
      },
      {
            title: 'Japan',
            slug: 'japan'
      }
];

export const months = [
      {
            title: 'January',
            slug: 0
      },
      {
            title: 'Febrary',
            slug: 1
      },
      {
            title: 'March',
            slug: 2
      },
      {
            title: 'April',
            slug: 3
      },
      {
            title: 'May',
            slug: 4
      },
      {
            title: 'June',
            slug: 5
      },
      {
            title: 'July',
            slug: 6
      },
      {
            title: 'August',
            slug: 7
      },
      {
            title: 'September',
            slug: 8
      },
      {
            title: 'October',
            slug: 9
      },
      {
            title: 'November',
            slug: 10
      },
      {
            title: 'December',
            slug: 11
      }
];

export const durations = [
      {
            title: '2 - 4 nights'
      },
      {
            title: '5 - 6 nights'
      },
      {
            title: '7 - 9 nights'
      },
      {
            title: '10 - 15 nights'
      }
];

export const people = [
      {
            title: 'solo',
            icon: <SoloIcon />
      },
      {
            title: 'couple',
            icon: <CoupleIcon />
      },
      {
            title: 'family',
            icon: <FamilyIcon />
      },
      {
            title: 'friends',
            icon: <FriendIcon />
      }
];
