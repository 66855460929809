import React from 'react';
import { MediaItem } from './style';

const SocialMediaItem = ({ link, icon, icons }) => {
      return (
            <>
                  <li>
                        <MediaItem to={link}>
                              {icons || <i className={icon}></i>}
                        </MediaItem>
                  </li>
            </>
      );
};

export default SocialMediaItem;
