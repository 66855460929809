import React from 'react';
import {
      DropDownContainer,
      DropDownImage,
      DropDownList,
      DropdownSubCategory
} from '../DropDown/styles';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Experience = ({ experiences }) => {
      return (
            <>
                  <DropDownContainer>
                        <DropDownList>
                              <Row className="g-3">
                                    {experiences.map((item, index) => (
                                          <Col lg={4} key={index}>
                                                <DropdownSubCategory>
                                                      <Link
                                                            to={`/experiences/${item.slug}`}
                                                      >
                                                            {item.image && (
                                                                  <DropDownImage>
                                                                        <img
                                                                              src={
                                                                                    item.image
                                                                              }
                                                                              alt={
                                                                                    item.title
                                                                              }
                                                                        />
                                                                  </DropDownImage>
                                                            )}
                                                            {item.title}
                                                      </Link>
                                                </DropdownSubCategory>
                                          </Col>
                                    ))}
                              </Row>
                        </DropDownList>
                  </DropDownContainer>
            </>
      );
};

export default Experience;
