import React, { useEffect, useState } from 'react';
import {
      BookImage,
      BookSearch,
      BookSearchContainer,
      BookTitle,
      DestinationItemContainer
} from '../style';
import { SearchIcon } from '../../../../icons';
import DestinationItem from '../DestinationItem';
import { Fade } from 'react-reveal';
import { Col, Row } from 'react-bootstrap';

const DestinationList = ({
      destinations,
      setDestination,
      title,
      heading,
      destinationSearch,
      setDestinationSearch
}) => {
      return (
            <>
                  {heading && (
                        <>
                              <BookTitle>
                                    <div>
                                          <BookImage src="/images/airicon.png" />
                                          Where do <span>you want</span> to go?
                                    </div>
                              </BookTitle>
                              <p className="text-center mb-5">
                                    Let us create a customized package based on
                                    your preferences.
                              </p>
                        </>
                  )}
                  <BookSearchContainer>
                        <SearchIcon />
                        <BookSearch
                              type="text"
                              onChange={(event) =>
                                    setDestinationSearch(event.target.value)
                              }
                        />
                  </BookSearchContainer>
                  <DestinationItemContainer>
                        <Fade>
                              <div>{title}</div>
                              <Row>
                                    {destinations?.length > 0 &&
                                          destinations?.map((item, index) => (
                                                <Col lg={3}>
                                                      <DestinationItem
                                                            title={item.title}
                                                            key={index}
                                                            handleChange={
                                                                  setDestination
                                                            }
                                                      />
                                                </Col>
                                          ))}
                                    {destinationSearch &&
                                          destinations.length === 0 && (
                                                <Col lg={3}>
                                                      <DestinationItem
                                                            title={
                                                                  destinationSearch
                                                            }
                                                            handleChange={
                                                                  setDestination
                                                            }
                                                      />
                                                </Col>
                                          )}
                              </Row>
                        </Fade>
                  </DestinationItemContainer>
            </>
      );
};

export default DestinationList;
