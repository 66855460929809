import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { useParams } from 'react-router';
import axios from 'axios';
import { BlogContainer } from '../blogs/styles';
import { Col, Container, Row } from 'react-bootstrap';
import { PackageItem } from '../../components/frontend/home/PackageBox/components';
import DestinationSlider from '../../components/sliders/DestinationSlider';
import {
      DestinationContainer,
      DestinationSummary,
      DestinationTitle
} from './styles';

const DestinationDetail = () => {
      const { destinationId } = useParams();

      const [loading, setLoading] = useState(false);
      const [destination, setDestination] = useState([]);
      const [packages, setPackages] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/destinations/${destinationId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDestination(response.data.destination);
                              setPackages(response.data.destination.packages);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [destinationId]);

      return (
            <>
                  <BreadcrumbBox title={destination.title} />
                  {destination.title && (
                        <DestinationContainer>
                              <Container>
                                    <Row className="align-items-center">
                                          <Col lg={7}>
                                                <DestinationSlider
                                                      image={destination.image}
                                                      images={
                                                            destination.images
                                                      }
                                                />
                                          </Col>
                                          <Col lg={5}>
                                                <DestinationSummary>
                                                      <DestinationTitle>
                                                            {destination.title}{' '}
                                                            Vacations Customized
                                                            for You
                                                      </DestinationTitle>
                                                      <div
                                                            dangerouslySetInnerHTML={{
                                                                  __html: destination.description
                                                            }}
                                                      ></div>
                                                </DestinationSummary>
                                          </Col>
                                    </Row>
                              </Container>
                        </DestinationContainer>
                  )}
                  {packages?.length > 0 && (
                        <BlogContainer>
                              <Container>
                                    <Row className="g-4">
                                          {packages?.map((item, index) => (
                                                <Col lg={3} key={index}>
                                                      <PackageItem
                                                            item={item}
                                                      />
                                                </Col>
                                          ))}
                                    </Row>
                              </Container>
                        </BlogContainer>
                  )}
            </>
      );
};

export default DestinationDetail;
