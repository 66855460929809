// TodoForm.js
import React, { useState } from 'react';

const TodoForm = ({ onAdd }) => {
      const [text, setText] = useState('');

      const handleSubmit = (e) => {
            e.preventDefault();
            onAdd({ text, id: Date.now() });
            setText('');
      };

      return (
            <form onSubmit={handleSubmit}>
                  <input
                        type="text"
                        placeholder="Add a new todo"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        className="form-control"
                  />
                  <button type="submit" className="btn btn-primary">
                        Add Todo
                  </button>
            </form>
      );
};

export default TodoForm;
