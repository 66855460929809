import React from 'react';
import {
      PackageCategory,
      PackageDetail,
      PackageItemContainer,
      PackageItemImage,
      PackageLocation,
      PackagePrice,
      PackageWrapper
} from './styles';
import { Card } from 'react-bootstrap';

const PackageItem = ({ item }) => {
      return (
            <>
                  <PackageItemContainer to={`/packages/${item.slug}`}>
                        <Card style={{ width: '100%' }}>
                              <PackageItemImage>
                                    <Card.Img variant="top" src={item.image} />
                              </PackageItemImage>
                              <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                              </Card.Body>
                        </Card>
                        <PackageDetail>
                              <PackageLocation className="text-muted">
                                    {`${item.location}, ${item.destination}`}
                              </PackageLocation>
                              <PackageWrapper className="mt-3 ">
                                    <PackagePrice className="mb-0 text-muted">
                                          <span>₹ {item.price} </span>
                                          {item.price_type}
                                    </PackagePrice>
                              </PackageWrapper>
                        </PackageDetail>
                  </PackageItemContainer>
            </>
      );
};

export default PackageItem;
