import React from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { MyBookingContainer, MyBookingForm, MyBookingImage } from './styles';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import NoticeWidget from '../../components/common/NoticeWidget';
import CommonButton from '../../components/common/CommonButton';

const MyBooking = () => {
      return (
            <>
                  <BreadcrumbBox title={'My Booking'} />
                  <MyBookingContainer>
                        <MyBookingImage>
                              <img
                                    src="/images/img/mybooking/airplane-2.svg"
                                    alt=""
                              />
                        </MyBookingImage>
                        <Container>
                              <Row className="justify-content-center">
                                    <Col
                                          lg={6}
                                          className="d-flex flex-column align-items-center"
                                    >
                                          <Col lg={7}>
                                                <NoticeWidget
                                                      title={
                                                            'View your booking without signing in'
                                                      }
                                                      icon={
                                                            '/images/img/mybooking/important.svg'
                                                      }
                                                      // info={
                                                      //       'You just need your TFN Reference Number and Email ID given at the time of booking to retrieve a particular booking.'
                                                      // }
                                                />
                                          </Col>

                                          <MyBookingForm className="mb-5 col-lg-7 rounded-4 ">
                                                <Form>
                                                      <Form.Group
                                                            className="mb-4"
                                                            controlId="formBasicEmail"
                                                      >
                                                            <Form.Label>
                                                                  Email Address:
                                                            </Form.Label>
                                                            <Form.Control type="text" />
                                                      </Form.Group>

                                                      <Form.Group
                                                            className="mb-4"
                                                            controlId="formBasicPassword"
                                                      >
                                                            <Form.Label>
                                                                  Booking
                                                                  Reference
                                                                  Number:
                                                            </Form.Label>
                                                            <Form.Control type="text" />
                                                      </Form.Group>
                                                      <CommonButton
                                                            title={
                                                                  'View Booking'
                                                            }
                                                      />
                                                </Form>
                                                <p className="mt-4">
                                                      Note: You just need your
                                                      TFN Reference Number and
                                                      Email ID given at the time
                                                      of booking to retrieve a
                                                      particular booking.
                                                </p>
                                          </MyBookingForm>
                                          <Col lg={7}></Col>
                                    </Col>
                              </Row>
                        </Container>
                  </MyBookingContainer>
            </>
      );
};

export default MyBooking;
