import React from 'react';
import { NoticeIcon, NoticeInfo, NoticeWidgetContainer } from './styles';

const NoticeWidget = ({ title, info, icon }) => {
      return (
            <>
                  <NoticeWidgetContainer className="d-flex align-items-center gap-4 w-100 mb-4">
                        <NoticeIcon className="d-flex align-items-center justify-content-center">
                              <img src={icon} alt={title} />
                        </NoticeIcon>
                        <NoticeInfo>
                              <h5>{title}</h5>
                              <p className="mb-0">{info}</p>
                        </NoticeInfo>
                  </NoticeWidgetContainer>
            </>
      );
};

export default NoticeWidget;
