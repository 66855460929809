import React from 'react';
import { CommonButtonContainer } from './styles';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const CommonButton = ({ link, title, func, loading, type, button }) => {
      return (
            <>
                  <CommonButtonContainer>
                        {button ? (
                              <button
                                    type={type ? type : 'submit'}
                                    className="w-100"
                              >
                                    <span>{loading ? <Spinner /> : title}</span>
                              </button>
                        ) : (
                              <Link to={link} onClick={func}>
                                    <span>{title}</span>
                              </Link>
                        )}
                  </CommonButtonContainer>
            </>
      );
};

export default CommonButton;
