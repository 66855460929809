import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../../components/common/BreadcrumbBox';
import { PackageDetailContainer, PackageDetailWrapper } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import {
      PackageAccordion,
      PackageDetailHeading,
      PackageDetailSlider
} from './components';
import EnquriryForm from '../../../components/common/EnquiryForm';
import PackagePricing from './components/PackagePricing';
import axios from 'axios';
import { useParams } from 'react-router';

const PackageDetail = () => {
      const { packageId } = useParams();
      const [loading, setLoading] = useState(false);
      const [item, setItem] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/packages/${packageId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setItem(response.data.package);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BreadcrumbBox title={item.title} />
                  <PackageDetailContainer className="mb-5">
                        <Container>
                              <Row>
                                    <Col lg={8}>
                                          <PackageDetailContainer>
                                                <PackageDetailHeading
                                                      item={item}
                                                />
                                                <PackageDetailSlider
                                                      loading={loading}
                                                      image={item.image}
                                                      images={item.images}
                                                />

                                                <PackageAccordion item={item} />
                                          </PackageDetailContainer>
                                    </Col>
                                    <Col lg={4}>
                                          <PackageDetailWrapper>
                                                <PackagePricing
                                                      price={item.price}
                                                      priceType={
                                                            item.price_type
                                                      }
                                                />
                                                <EnquriryForm
                                                      title={item.title}
                                                      slug={item.slug}
                                                />
                                          </PackageDetailWrapper>
                                    </Col>
                              </Row>
                        </Container>
                  </PackageDetailContainer>
            </>
      );
};

export default PackageDetail;
