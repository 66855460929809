import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import ToDoBox from './ToDo';
import DataList from './ToDo/DataList';
import DataShow from './ToDo/DataShow';

const PracticeBox = () => {
      const [add, setAdd] = useState();
      const handleAdd = () => setAdd('heelo');
      console.log(add);
      useEffect(() => {}, []);
      return (
            <>
                  <>
                        <Container>
                              <ToDoBox />
                              <DataShow />
                        </Container>
                        <Button onClick={handleAdd}>Add</Button>
                        {add}
                        {/* <Table striped bordered hover>
                              <thead>
                                    <tr>
                                          <th>#</th>
                                          <th>First Name</th>
                                          <th>Last Name</th>
                                          <th>Username</th>
                                    </tr>
                              </thead>
                              <tbody>
                                    <tr>
                                          <td>1</td>
                                          <td>Mark</td>
                                          <td>Otto</td>
                                          <td>@mdo</td>
                                    </tr>
                                    <tr>
                                          <td>2</td>
                                          <td>Jacob</td>
                                          <td>Thornton</td>
                                          <td>@fat</td>
                                    </tr>
                                    <tr>
                                          <td>3</td>
                                          <td colSpan={2}>Larry the Bird</td>
                                          <td>@twitter</td>
                                    </tr>
                              </tbody>
                        </Table> */}
                  </>
            </>
      );
};

export default PracticeBox;
