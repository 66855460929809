import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import {
      DestinationContent,
      DestinationItem
} from '../../frontend/home/BookingBox/styles';

export const EnquiryBannerContainer = styled(Container)`
      background-image: url('/images/img/hotellocation/hotel-banner-btm.png');
      color: #fff;
      padding: 25px;
      background-size: cover;
      height: 330px;
      border-radius: 10px;
      width: 1170px;
      margin: 35px auto 0 auto;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 3rem;

      @media screen and (max-width: 1200px) {
            width: calc(100% - 40px);
            height: auto;
            margin: 3rem 20px;
            padding: 30px 20px;
      }

      @media screen and (max-width: 600px) {
            ${DestinationContent} {
                  width: 100%;
                  padding-right: 0;
            }

            ${DestinationItem} {
                  padding: 5px;
            }
      }
`;
export const EnquiryContent = styled.div`
      h3 {
            font-size: 31px;
            margin-bottom: 1.5rem;
      }
      p {
            margin-bottom: 25px;
            width: 50%;
      }

      @media screen and (max-width: 600px) {
            h3 {
                  font-size: 20px;
                  margin-bottom: 0.5rem;
            }

            p {
                  margin-bottom: 16px;
                  width: 100% !important;
                  font-size: 14px;
            }
      }
`;
export const EnquiryButton = styled.div`
      margin-bottom: 0px;

      .spinner-border {
            height: 1.2rem !important;
            width: 1.2rem !important;
            border-width: 1px !important;
      }

      a {
            background-color: ${(props) => props.theme.primary};
            border: 1px solid ${(props) => props.theme.primary};
            border-radius: 0.25rem;
            padding: 11px 21px;
            color: ${(props) => props.theme.white};
            transition: all 0.3s ease-in;
            display: inline-block;
            width: 100%;
            text-align: center;

            &:hover {
                  opacity: 0.99;
                  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
      }

      @media screen and (max-width: 600px) {
            a {
                  padding: 5px 16px;
            }
      }
`;
