import React, { useEffect, useState } from 'react';
import CloseButton from './CloseButton';
import { BookBody, BookButton, BookContent, OffcanvasBody } from './style';
import Fade from 'react-reveal/Fade';
import DestinationList from './DestinationList';
import DestinationMonth from './DestinationMonth';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { durations, months, people } from '../../../data/bootwithexport';
import SelectedItems from './SelectedItems';
import DurationList from './DurationList';
import PeopleList from './PeopleList';
import PlanForm from './PlanForm';
import TravelerBox from '../../frontend/home/BookingBox/TravelerBox';
import { DestinationWrapper } from '../../frontend/home/BookingBox/styles';
import { DestinationMonthTitle } from './DestinationMonth/style';
import { DestinationItemTitle } from './DestinationItem/style';
import { FormButton } from '../../forms';
import { Container, Modal, Spinner } from 'react-bootstrap';

import $ from 'jquery';
import axios from 'axios';
import toast from 'react-hot-toast';

const BookWithExpert = () => {
      const [show, setShow] = useState(false);
      const [loadForm, setLoadForm] = useState(false);
      const [loading, setLoading] = useState(false);

      const handleShow = () => setShow(true);

      const [destinationSearch, setDestinationSearch] = useState('');
      const [departingSearch, setDepartingSearch] = useState('');

      const handleDestinationSearch = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/destination_countries?destination_search=${destinationSearch}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        setDestinations(response.data.countries);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            handleDestinationSearch();
      }, [destinationSearch]);

      const handleDepartingSearch = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/destination_countries?departing_search=${departingSearch}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        setDepartings(response.data.cities);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            handleDepartingSearch();
      }, [departingSearch]);

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();

      const formattedDate = `${currentYear}, ${
            currentMonth < 10 ? '0' + currentMonth : currentMonth
      }, ${currentDay < 10 ? '0' + currentDay : currentDay}`;

      const [destination, setDestination] = useState('');
      const [month, setMonth] = useState('');
      const [selectedMonth, setSelectedMonth] = useState('');
      const [selectedDate, setSelectedDate] = useState('');
      const [departing, setDeparting] = useState('');
      const [duration, setDuration] = useState('');
      const [groupSize, setGroupSize] = useState('');
      const [family, setFamily] = useState('');

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [mobileNumber, setMobileNumber] = useState('');

      const [travelers, setTravelers] = useState([
            {
                  room: '1',
                  children: '0',
                  adult: '1',
                  childrenAges: []
            }
      ]);

      const [destinations, setDestinations] = useState([]);
      const [departings, setDepartings] = useState([]);

      const loadData = async () => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/destination_countries`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setDestinations(response.data.countries);
                              setDepartings(response.data.cities);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleMonth = (item) => {
            setMonth(item.title);
            setSelectedMonth(item.slug);
      };

      const handleDate = (item) => {
            setSelectedDate(item);
      };

      const handleFormButton = () => {
            setLoadForm(true);
            setTimeout(() => {
                  handleShow();
                  setLoadForm(false);
            }, 1500);
      };

      const handleClose = () => {
            setShow(false);
            setDestination('');
            setMonth('');
            setSelectedMonth('');
            setSelectedDate('');
            setDeparting('');
            setDuration('');
            setGroupSize('');
            setFamily('');
            setName('');
            setEmail('');
            setMobileNumber('');
      };

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  destination: destination,
                  leaving_from: departing,
                  month: month,
                  date: selectedMonth,
                  departing: departing,
                  duration: duration,
                  group_size: groupSize,
                  name: name,
                  email: email,
                  mobile_number: mobileNumber,
                  travelers: travelers
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/plan_trip`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setTimeout(() => {
                                    handleClose();
                              }, 1500);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.destination) {
                                    toast.error(
                                          response.data.message.destination
                                    );
                              }
                              if (response.data.message.departing) {
                                    toast.error(
                                          response.data.message.departing
                                    );
                              }
                              if (response.data.message.month) {
                                    toast.error(response.data.message.month);
                              }
                              if (response.data.message.group_size) {
                                    toast.error(
                                          response.data.message.group_size
                                    );
                              }
                              if (response.data.message.duration) {
                                    toast.error(response.data.message.duration);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.mobile_number) {
                                    toast.error(
                                          response.data.message.mobile_number
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <BookButton variant="primary" onClick={handleFormButton}>
                        {!loadForm ? 'Plan with Aircasa' : <Spinner />}
                  </BookButton>

                  <Modal
                        show={show}
                        fullscreen={true}
                        onHide={handleClose}
                        backdrop="static"
                  >
                        <OffcanvasBody  className='position-relative'>
                              <Container className=" mt-5">
                                    <BookBody>
                                          <CloseButton
                                                handleClose={handleClose}
                                          />
                                          {destination ? (
                                                <>
                                                      <SelectedItems
                                                            setDeparting={
                                                                  setDeparting
                                                            }
                                                            setDestination={
                                                                  setDestination
                                                            }
                                                            setGroupSize={
                                                                  setGroupSize
                                                            }
                                                            setMonth={setMonth}
                                                            setSelectedDate={
                                                                  setSelectedDate
                                                            }
                                                            setSelectedMonth={
                                                                  setSelectedMonth
                                                            }
                                                            setDuration={
                                                                  setDuration
                                                            }
                                                            groupSize={
                                                                  groupSize
                                                            }
                                                            departing={
                                                                  departing
                                                            }
                                                            destination={
                                                                  destination
                                                            }
                                                            month={month}
                                                            duration={duration}
                                                            currentYear={
                                                                  currentYear
                                                            }
                                                      />
                                                      <Fade>
                                                            <BookContent>
                                                                  {!month ? (
                                                                        <DestinationMonth
                                                                              months={
                                                                                    months
                                                                              }
                                                                              handleChange={
                                                                                    handleMonth
                                                                              }
                                                                        />
                                                                  ) : selectedDate ? (
                                                                        departing ? (
                                                                              duration ? (
                                                                                    groupSize ? (
                                                                                          groupSize ===
                                                                                                'solo' ||
                                                                                          groupSize ===
                                                                                                'couple' ? (
                                                                                                <PlanForm
                                                                                                      setEmail={
                                                                                                            setEmail
                                                                                                      }
                                                                                                      setMobileNumber={
                                                                                                            setMobileNumber
                                                                                                      }
                                                                                                      setName={
                                                                                                            setName
                                                                                                      }
                                                                                                      handleForm={
                                                                                                            handleForm
                                                                                                      }
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      name={
                                                                                                            name
                                                                                                      }
                                                                                                      email={
                                                                                                            email
                                                                                                      }
                                                                                                      mobileNumber={
                                                                                                            mobileNumber
                                                                                                      }
                                                                                                />
                                                                                          ) : family ? (
                                                                                                <PlanForm
                                                                                                      setEmail={
                                                                                                            setEmail
                                                                                                      }
                                                                                                      setMobileNumber={
                                                                                                            setMobileNumber
                                                                                                      }
                                                                                                      setName={
                                                                                                            setName
                                                                                                      }
                                                                                                      loading={
                                                                                                            loading
                                                                                                      }
                                                                                                      handleForm={
                                                                                                            handleForm
                                                                                                      }
                                                                                                      name={
                                                                                                            name
                                                                                                      }
                                                                                                      email={
                                                                                                            email
                                                                                                      }
                                                                                                      mobileNumber={
                                                                                                            mobileNumber
                                                                                                      }
                                                                                                />
                                                                                          ) : (
                                                                                                <>
                                                                                                      <PeopleList
                                                                                                            people={
                                                                                                                  people
                                                                                                            }
                                                                                                            handleChange={
                                                                                                                  setGroupSize
                                                                                                            }
                                                                                                      />
                                                                                                      <DestinationMonthTitle className="mt-3">
                                                                                                            How
                                                                                                            many
                                                                                                            of
                                                                                                            you
                                                                                                            are
                                                                                                            travelling?
                                                                                                      </DestinationMonthTitle>
                                                                                                      <div className="text-muted">
                                                                                                            Number
                                                                                                            of
                                                                                                            rooms
                                                                                                      </div>
                                                                                                      <DestinationItemTitle
                                                                                                            className="col-md-9"
                                                                                                            background="#FFF"
                                                                                                            border="#ddd"
                                                                                                      >
                                                                                                            <DestinationWrapper>
                                                                                                                  <TravelerBox
                                                                                                                        data={
                                                                                                                              travelers
                                                                                                                        }
                                                                                                                        setData={
                                                                                                                              setTravelers
                                                                                                                        }
                                                                                                                        type="hidden"
                                                                                                                  />
                                                                                                            </DestinationWrapper>
                                                                                                      </DestinationItemTitle>
                                                                                                      <div
                                                                                                            className="mt-4"
                                                                                                            onClick={() =>
                                                                                                                  setFamily(
                                                                                                                        true
                                                                                                                  )
                                                                                                            }
                                                                                                      >
                                                                                                            <FormButton
                                                                                                                  title="Continue"
                                                                                                                  className="mt-4"
                                                                                                                  type="button"
                                                                                                            />
                                                                                                      </div>
                                                                                                </>
                                                                                          )
                                                                                    ) : (
                                                                                          <PeopleList
                                                                                                people={
                                                                                                      people
                                                                                                }
                                                                                                handleChange={
                                                                                                      setGroupSize
                                                                                                }
                                                                                          />
                                                                                    )
                                                                              ) : (
                                                                                    <DurationList
                                                                                          durations={
                                                                                                durations
                                                                                          }
                                                                                          setDuration={
                                                                                                setDuration
                                                                                          }
                                                                                    />
                                                                              )
                                                                        ) : (
                                                                              <DestinationList
                                                                                    destinations={
                                                                                          departings
                                                                                    }
                                                                                    destinationSearch={
                                                                                          departingSearch
                                                                                    }
                                                                                    setDestinationSearch={
                                                                                          setDepartingSearch
                                                                                    }
                                                                                    title="Where are you travelling from?"
                                                                                    setDestination={
                                                                                          setDeparting
                                                                                    }
                                                                              />
                                                                        )
                                                                  ) : (
                                                                        <DayPicker
                                                                              mode="single"
                                                                              defaultMonth={
                                                                                    new Date(
                                                                                          currentYear,
                                                                                          selectedMonth
                                                                                    )
                                                                              }
                                                                              selected={
                                                                                    selectedDate
                                                                              }
                                                                              onSelect={
                                                                                    handleDate
                                                                              }
                                                                              fromDate={
                                                                                    new Date(
                                                                                          formattedDate
                                                                                    )
                                                                              }
                                                                        />
                                                                  )}
                                                            </BookContent>
                                                      </Fade>
                                                </>
                                          ) : (
                                                <DestinationList
                                                      heading={true}
                                                      destinations={
                                                            destinations
                                                      }
                                                      setDestination={
                                                            setDestination
                                                      }
                                                      destinationSearch={
                                                            destinationSearch
                                                      }
                                                      setDestinationSearch={
                                                            setDestinationSearch
                                                      }
                                                />
                                          )}
                                    </BookBody>
                              </Container>
                        </OffcanvasBody>
                  </Modal>
            </>
      );
};

export default BookWithExpert;
