import React from 'react';
import {
      PrivacyItemContainer,
      PrivacyItemContent,
      PrivacyItemTime,
      PrivacyItemTitle
} from './styles';

const PrivacyItem = ({ title }) => {
      return (
            <>
                  <PrivacyItemContainer>
                        <PrivacyItemTitle>
                              {title ? title : 'Privacy Policy'}
                        </PrivacyItemTitle>
                        <PrivacyItemTime>
                              <span>Last updated: Dec 24, 2023</span>
                        </PrivacyItemTime>
                        <PrivacyItemContent>
                              <p>
                                    Thank you for choosing Aircassa. We
                                    appreciate your trust, and we are committed
                                    to protecting your privacy. This Privacy
                                    Policy outlines how we, Aircassa , collect,
                                    use, maintain, protect, and disclose
                                    information when you visit our website. By
                                    accessing or using the Website, you agree to
                                    the terms of this Privacy Policy. If you do
                                    not agree with our practices, please refrain
                                    from using our Website. Information We
                                    Collect At Aircassa, we collect various
                                    types of information from and about our
                                    users, including: Personal Information: We
                                    collect identifiable information such as
                                    your name, email address, postal address,
                                    and phone number, provided voluntarily by
                                    you. Non-personal Information: We also
                                    collect non-personal information about your
                                    visit, such as pages viewed, links clicked,
                                    and other actions taken within our Website.
                                    How We Use Your Information We, at Aircassa,
                                    use the information we collect for several
                                    purposes, including: To enhance your
                                    experience by presenting our Website and its
                                    contents to you. To provide information,
                                    products, or services requested by you. To
                                    fulfill any other purpose for which you
                                    provide the information. To carry out our
                                    obligations and enforce our rights arising
                                    from any contracts entered into between you
                                    and us. Disclosure of Your Information We
                                    respect your privacy. At Aircassa, we do not
                                    sell, trade, or transfer your personally
                                    identifiable information to outside parties.
                                    Trusted third parties may assist us in
                                    operating our Website, conducting business,
                                    or servicing you, as long as they agree to
                                    maintain the confidentiality of your
                                    information. We may also disclose your
                                    personal information to comply with any
                                    court order, law, or legal process,
                                    including responding to government or
                                    regulatory requests. Security Your security
                                    is important to us. At Aircassa, we have
                                    implemented measures to protect your
                                    personal information from accidental loss
                                    and unauthorized access, use, alteration,
                                    and disclosure. Changes to Our Privacy
                                    Policy We, at Aircassa, may update our
                                    Privacy Policy periodically. Material
                                    changes to how we treat your personal
                                    information will be communicated through the
                                    updated Privacy Policy posted on this page.
                                    Contact Information If you have any
                                    questions or concerns about this Privacy
                                    Policy, please reach out to us at By using
                                    the Aircassa Website, you consent to this
                                    Privacy Policy.
                              </p>
                              <h3></h3>
                        </PrivacyItemContent>
                  </PrivacyItemContainer>
            </>
      );
};

export default PrivacyItem;
