import React from 'react';

const FriendIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">diversity_3</span>
            </>
      );
};

export default FriendIcon;
