import styled from 'styled-components';
import { DropDownContainer } from '../../../../components/frontend/layout/header/DropDown/styles';

export const NavigationContainer = styled.div`
      padding: 0px 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 100;
      width: 100%;
      s .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      }

      button {
            display: none;
      }

      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
      }
      &.sticky {
            position: fixed;
            top: 0;
            width: 100%;
            transition: 0.3s linear;
            background-color: #fff;
            z-index: 99999;
      }
      @media only screen and (max-width: 1200px) {
            nav {
                  display: none;
            }
            button {
                  display: block;
            }
      }

      @media screen and (max-width: 600px) {
            padding: 0 20px;
      }
`;

export const NavigationLogo = styled.div`
      /* padding: 10px 0px; */

      img {
            width: ${(props) => props.height};
            height: auto;
      }

      @media screen and (max-width: 600px) {
            display: none;
      }
`;

export const NavLogo = styled.div`
      padding: 12px 0;
      display: none;

      @media screen and (max-width: 600px) {
            display: inline-block;

            a {
                  display: inline-block;

                  img {
                        width: ${(props) => props.width};
                        height: ${(props) => props.height || 'auto'};
                        object-fit: contain;
                  }
            }
      }
`;

export const NavigationLogoOverlay = styled.div`
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 24.5%;
      background-color: #ebf7ff05;
      -webkit-clip-path: polygon(0 0, calc(100% - 50px) 0%, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, calc(100% - 50px) 0%, 100% 100%, 0% 100%);
      z-index: -1;
`;

export const NavigationMenu = styled.ul`
      margin-bottom: 0;
      list-style: none;
      padding: 0;
      position: relative;

      li {
            display: inline-block;
            margin-right: 1.5rem;

            &:last-child {
                  margin-right: 0rem !important;
                  a {
                        padding-right: 0rem !important;
                  }
            }
            a {
                  padding: 25px 10px;
                  color: ${(props) => (props.color ? '#212121' : '#fff')};
                  font-size: 16px;
                  transition: transform 0.3s linear;
                  position: relative;

                  &:after {
                        content: '';
                        position: absolute;
                        bottom: 10px;
                        left: 0;
                        height: 2px;
                        width: 0%;
                        background: #ffffff;
                        transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
                  }

                  &:hover {
                        transition: transform 0.3s linear;

                        transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
                        &:after {
                              width: 100%;
                              transition: width 0.8s
                                    cubic-bezier(0.22, 0.61, 0.36, 1);
                        }
                  }
                  &.active {
                        color: ${(props) =>
                              props.color ? props.theme.primary : '#fff'};
                        &:after {
                              content: '';
                              position: absolute;
                              bottom: 10px;
                              left: 0px;
                              top: ${(props) => (props.color ? ' ' : '')};
                              height: ${(props) =>
                                    props.color ? '2px' : '2px'};
                              width: 100%;
                              border-radius: 10px;
                              background: ${(props) =>
                                    props.color ? props.theme.primary : '#fff'};
                        }
                  }
            }

            &:hover {
                  transition: transform 0.3s linear;
                  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);

                  ${DropDownContainer} {
                        display: block;
                        transform: translate(-50%, 0px);
                        transition: transform 0.3s linear;
                  }
            }
      }
`;
