import styled from 'styled-components';

export const WidgetBlogItem = styled.div`
      margin-bottom: 20px;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 20px;
      display: flex;
      gap: 12px;
      align-items: center;

      &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
      }
`;

export const WidgetBlogItemImage = styled.div`
      width: 100px;
      height: 100px;

      a {
            display: inline-block;
            height: 100%;
            width: 100%;

            img {
                  width: 100%;
                  height: 100%;
                  border-radius: 10px;
                  object-fit: cover;
            }
      }

      @media screen and (max-width: 600px) {
            height: auto;
      }
`;

export const WidgetBlogItemInfo = styled.div`
      width: calc(100% - 112px);

      a {
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
            color: #2b2b2b;
      }

      span {
            display: inline-block;
            color: #999999;
            font-size: 13px;
      }

      @media screen and (max-width: 600px) {
            a {
                  font-size: 14px;
            }
      }
`;
