import styled from 'styled-components';

export const WidgetItem = styled.div`
      background-color: ${(props) => props.theme.white};
      border-radius: 20px;
      box-shadow: 0 0 60px 0 rgba(6, 30, 98, 0.08);
      margin-bottom: 30px;
`;

export const WidgetHeader = styled.div`
      border-bottom: 1px solid #e5e5e5;
`;

export const WidgetItemTitle = styled.div`
      font-size: 18px;
      font-weight: 600;
      padding: 12px 20px;
`;

export const WidgetItemBody = styled.div`
      padding: 20px;

      ul {
            padding: 0;
            margin: 0;
            text-decoration: none;
            list-style: none;

            li {
                  margin-bottom: 10px;

                  &:last-child {
                        margin-bottom: 0;
                  }

                  a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #2b2b2b;

                        i {
                              color: ${(props) => props.theme.primary};
                              margin-right: 8px;
                        }

                        div:first-child {
                              display: flex;
                              align-items: center;
                        }

                        div:last-child {
                              font-size: 12px;
                              border-radius: 10px;
                              background-color: #f6f7fb;
                              width: 24px;
                              height: 24px;
                              display: inline-flex;
                              justify-content: center;
                              align-items: center;
                              transition: all 0.2s ease;
                              color: #2b2b2b;
                        }

                        &:hover {
                              color: ${(props) => props.theme.primary};
                              div:last-child {
                                    background-color: ${(props) =>
                                          props.theme.primary};
                                    color: ${(props) => props.theme.white};
                              }
                        }
                  }
            }
      }
`;
