import styled from 'styled-components';

export const BreadcrumbContainer = styled.div`
      padding: 280px 0 50px;
      background-size: cover;
      /* background-position: center center; */
      background-repeat: no-repeat;
      min-height: 220px;
      position: relative;

      a {
            color: ${(props) => props.theme.primary};
      }

      .container {
            height: 100%;
      }

      @media only screen and (max-width: 600px) {
            min-height: 160px;
            padding: 100px 0 20px;
      }
`;

export const BreadcrumbOutlay = styled.div`
      &::before {
            /* background: linear-gradient(
                  to top,
                  rgba(0, 0, 0, 1) 0%,
                  rgba(0, 0, 0, 0) 60%
            );
            background: -webkit-linear-gradient(
                  to top,
                  rgba(0, 0, 0, 1) 0%,
                  rgba(0, 0, 0, 0) 60%
            );
            background: -moz-linear-gradient(
                  to top,
                  rgba(0, 0, 0, 1) 0%,
                  rgba(0, 0, 0, 0) 60%
            );
            background: -ms-linear-gradient(
                  to top,
                  rgba(0, 0, 0, 1) 0%,
                  rgba(0, 0, 0, 0) 60%
            ); */
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
      }

      &::after {
            /* background-color: rgba(0, 0, 0, 0.5); */
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
      }
`;

export const BreadcrumbContent = styled.div`
      height: 100%;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
            color: ${(props) => props.theme.white};
      }

      @media only screen and (max-width: 600px) {
            h1 {
                  font-size: 20px;
            }
      }
`;
