import styled, { keyframes } from 'styled-components';

export const SelectedContainer = styled.div`
      cursor: pointer;
      min-width: 150px;
`;

export const SelectedItemContent = styled.div`
      border-radius: 5px;
      gap: 10px;
      padding: 6px 20px;
      border: 1px solid #ddd;
      background-color: ${(props) => props.theme.white};
      display: flex;
      align-items: center;
`;

export const SelectedItemIcon = styled.div`
      height: 24px;
      svg {
            height: 16px;
            width: 16px;
      }
`;
export const SelectedTitle = styled.div`
      font-size: 14px;
      min-width: fit-content;
`;

export const SelectedItemOuter = styled.div`
      height: 4px;
      background-color: #ddd;
      margin-top: ${(props) => (props.margin ? props.margin : '5px')};
`;

const animationMove = keyframes`
      0% {
            width: 0%;
      }
      100% {
            width: 100%;
      }
`;

export const SelectedItemInner = styled.div`
      height: 4px;
      background-color: ${(props) => props.theme.primary};
      margin-top: 5px;
      animation: ${animationMove} 1s linear forwards;
`;
