import React from 'react';
import { Link } from 'react-router-dom';

const WidgetCategoryItem = ({ category }) => {
      return (
            <>
                  <li>
                        <Link to={`/blogs/${category.slug}/category`}>
                              <div>
                                    <i className="bx bx-chevron-right-circle"></i>
                                    <span>{category.title}</span>
                              </div>
                              <div>{category.blogs}</div>
                        </Link>
                  </li>
            </>
      );
};

export default WidgetCategoryItem;
