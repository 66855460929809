export const durations = [
      {
            title: '1 nights'
      },
      {
            title: '2 nights'
      },
      {
            title: '3 nights'
      },
      {
            title: '4 nights'
      },
      {
            title: '5 - 6 nights'
      },
      {
            title: '7 - 9 nights'
      },
      {
            title: '10 - 13 nights'
      },
      {
            title: '14 - 17 nights'
      },
      {
            title: '18 - 20 nights'
      },
      {
            title: '21 - 24 nights'
      }
];
