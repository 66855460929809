import React, { useEffect, useState } from 'react';
import {
      AdditionalCol,
      AdditionalContainer,
      AdditionalContent,
      AdditionalHeading,
      AdditionalImage,
      AdditionalImagePin,
      AdditionalItem,
      AdditionalRating,
      AdditionalWrapper
} from './styles';
import SubTitle from '../HomeTitle/SubTitle';
import MainTitle from '../HomeTitle/MainTitle';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { RatingIcon } from '../../../../icons';

const AdditionalBox = () => {
      const [testimonials, setTestimonials] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/testimonials`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTestimonials(response.data.testimonials);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <AdditionalContainer>
                        <AdditionalHeading className="text-center">
                              <SubTitle
                                    subtitle={'Enjoy with your love'}
                                    left="-12px"
                                    right="-12px"
                                    center="100%"
                              />
                              <MainTitle
                                    title={'Our Customer Experience with us'}
                                    center="100%"
                              />
                        </AdditionalHeading>
                        <AdditionalWrapper>
                              <Container>
                                    <Row className="g-5">
                                          {testimonials?.length > 0 &&
                                                testimonials
                                                      ?.slice(0, 3)
                                                      .map(
                                                            (
                                                                  experience,
                                                                  index
                                                            ) => (
                                                                  <AdditionalCol
                                                                        lg={4}
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <AdditionalItem>
                                                                              <AdditionalImagePin>
                                                                                    <img
                                                                                          src="/images/img/additional/pin1.png"
                                                                                          alt=""
                                                                                          className="img-fluid"
                                                                                    />
                                                                              </AdditionalImagePin>
                                                                              <AdditionalImage>
                                                                                    <Link
                                                                                          to={`${
                                                                                                experience.link ||
                                                                                                '/'
                                                                                          }`}
                                                                                    >
                                                                                          <img
                                                                                                src={
                                                                                                      experience.image
                                                                                                }
                                                                                                alt={
                                                                                                      experience.name
                                                                                                }
                                                                                                className="img-fluid"
                                                                                          />
                                                                                    </Link>
                                                                              </AdditionalImage>
                                                                              <AdditionalContent>
                                                                                    <Link>
                                                                                          <h3>
                                                                                                {
                                                                                                      experience.package_name
                                                                                                }
                                                                                          </h3>
                                                                                          <h5>
                                                                                                {
                                                                                                      experience.name
                                                                                                }
                                                                                          </h5>
                                                                                    </Link>
                                                                                    <p>
                                                                                          "{' '}
                                                                                          {
                                                                                                experience.message
                                                                                          }{' '}
                                                                                          "
                                                                                    </p>
                                                                                    <AdditionalRating>
                                                                                          {experience.rating ===
                                                                                                5 && (
                                                                                                <>
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                </>
                                                                                          )}
                                                                                          {experience.rating ===
                                                                                                4 && (
                                                                                                <>
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                </>
                                                                                          )}
                                                                                          {experience.rating ===
                                                                                                3 && (
                                                                                                <>
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                </>
                                                                                          )}
                                                                                          {experience.rating ===
                                                                                                2 && (
                                                                                                <>
                                                                                                      <RatingIcon />
                                                                                                      <RatingIcon />
                                                                                                </>
                                                                                          )}
                                                                                          {experience.rating ===
                                                                                                1 && (
                                                                                                <>
                                                                                                      <RatingIcon />
                                                                                                </>
                                                                                          )}
                                                                                    </AdditionalRating>
                                                                              </AdditionalContent>
                                                                        </AdditionalItem>
                                                                  </AdditionalCol>
                                                            )
                                                      )}
                                    </Row>
                              </Container>
                        </AdditionalWrapper>
                  </AdditionalContainer>
            </>
      );
};

export default AdditionalBox;
