import styled from 'styled-components';

export const NoticeWidgetContainer = styled.div`
      border-radius: 30px 5px 5px 5px;
      padding: 15px 15px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border: 0;
      position: relative;
      box-shadow: 0 0 5px rgb(0 0 0 / 10%);
      width: 49%;
      background: #fff url(/images/img/mybooking/side-c.svg);
      background-repeat: no-repeat;
      background-position: -20% 60%;
      h5 {
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
      }
      p {
            font-size: 13px;
      }
`;

export const NoticeIcon = styled.div`
      width: 105px;
      margin-right: 10px;
      img {
            width: 50px;
      }
`;
export const NoticeInfo = styled.div``;
