import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const DropDownContainer = styled.div`
      position: absolute;
      top: 58px;
      left: 50%;
      display: none;
      transform: translate(-50%, 20px);
      z-index: 100;
      background: ${(props) => props.theme.white};
      padding: 20px;
      font-size: 13px;
      border-top: 2px solid ${(props) => props.theme.primary};
      border-radius: 0px 0px 5px 5px;
      transition: transform 0.3s linear;
      color: ${(props) => props.theme.primary};
      width: 100%;

      li {
            display: block;
            a {
                  color: #212121 !important;
            }
            span {
                  font-size: 12px;
                  color: ${(props) => props.theme.primary};
            }

            .material-symbols-outlined {
                  font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 0,
                        'opsz' 24;
            }
      }
`;
export const DropDownList = styled.ul`
      min-width: 420px;
      padding-left: 0px;
`;

export const DropDownImage = styled.div`
      border-radius: 10px;
      height: ${(props) => props.width || '132px'};
      width: 100%;
      overflow: hidden;
      margin-bottom: 10px;

      img {
            width: 100%;
            height: ${(props) => props.width || '132px'};
            border-radius: 10px;
            object-fit: cover;
            transition: all 0.3s ease-in;
      }
`;

export const DropdownItem = styled(Col)`
      border-left: ${(props) => props.border};
      padding-left: ${(props) => props.padding};
`;

export const DropdownSubCategory = styled.h6`
      margin-bottom: 10px;

      a {
            color: ${(props) => props.theme.primary} !important;
            display: inline-block;
            padding: 0 !important;
            text-align: center;
            transition: all 0.3s ease-in;
            overflow: hidden;

            &:hover {
                  color: ${(props) => props.theme.secondary} !important;
                  &:after {
                        width: 0% !important;
                  }
                  img {
                        transform: scale(1.1);
                        border-radius: 10px;
                  }
            }
      }
`;
export const DropdownSubCategoryList = styled.ul`
      padding: 0;
`;

export const SubCategoryTitle = styled.div`
      margin-bottom: 5px;

      a {
            color: ${(props) => props.theme.primary} !important;
            display: flex;
            padding: 0 !important;
            text-align: center;
            gap: 8px;
            transition: all 0.3s ease-in !important;

            svg {
                  height: 18px;
                  fill: ${(props) => props.theme.secondary};
            }

            &:hover {
                  color: ${(props) => props.theme.secondary} !important;
                  margin-left: 10px;
                  transition: all 0.3s ease-in;

                  &::after {
                        background: transparent !important;
                        content: none;
                  }
            }
      }
`;
