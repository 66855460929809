import React from 'react';
import { ApplicationSection } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import ApplicationHeading from './components/ApplicationHeading';
import { applications } from '../../../../data/application.List';
import ApplicationItem from './components/ApplicationItem';
import ApplicationContent from './components/ApplicationContent';

const DomesticDestination = () => {
      return (
            <>
                  <ApplicationSection>
                        <Container>
                              <Row className="g-4">
                                    <Col lg={3}>
                                          <ApplicationHeading />
                                    </Col>
                                    {applications?.length &&
                                          applications?.map(
                                                (application, index) => (
                                                      <Col
                                                            lg={3}
                                                            key={index}
                                                            data-aos="fade-right"
                                                            data-aos-easing="linear"
                                                            data-aos-duration="1500"
                                                      >
                                                            <ApplicationItem
                                                                  title={
                                                                        application.title
                                                                  }
                                                                  image={
                                                                        application.image
                                                                  }
                                                            />
                                                      </Col>
                                                )
                                          )}
                                    <Col lg={3}>
                                          <ApplicationContent />
                                    </Col>
                              </Row>
                        </Container>
                  </ApplicationSection>
            </>
      );
};

export default DomesticDestination;
