import React, { useState } from 'react';
import {
      EnquiryBannerContainer,
      EnquiryButton,
      EnquiryContent
} from './styles';
import { Link } from 'react-router-dom';
import { Offcanvas, Spinner } from 'react-bootstrap';
import {
      BookingWrapper,
      DestinationContent
} from '../../frontend/home/BookingBox/styles';
import DestinationItemBox from '../../frontend/home/BookingBox/DestinationItemBox';
import { EnquiryForm } from '../../frontend/hotels';

const EnquiryBanner = ({
      from,
      to,
      description,
      setFrom,
      setDestination,
      setCheckin,
      setCheckout,
      setName,
      setEmail,
      setMobileNumber,
      setMessage,
      loading,
      handleForm,
      show,
      setShow,
      fromValue,
      destination,
      checkin,
      checkout,
      travelers,
      setTravelers,
      flightType,
      setFlightType,
      types
}) => {
      const [loadingModal, setLoadingModal] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => {
            setLoadingModal(true);
            setTimeout(() => {
                  setLoadingModal(false);
                  setShow(true);
            }, 1500);
      };

      return (
            <>
                  <EnquiryBannerContainer>
                        <EnquiryContent>
                              <h3>Get Cheapest Deal at Aircasa</h3>
                              <p>{description}</p>
                        </EnquiryContent>
                        <BookingWrapper customBackground={'transparent'}>
                              {from ? (
                                    <DestinationItemBox
                                          width="20%"
                                          title="From"
                                          value={fromValue}
                                          handleChange={setFrom}
                                    />
                              ) : (
                                    <DestinationItemBox
                                          width="20%"
                                          value={destination}
                                          handleChange={setDestination}
                                          title="Where do you want to stay"
                                    />
                              )}
                              {to && (
                                    <DestinationItemBox
                                          width="20%"
                                          title="To"
                                          value={destination}
                                          handleChange={setDestination}
                                    />
                              )}
                              <DestinationItemBox
                                    width="15%"
                                    title={to ? 'Depart on' : 'Check In'}
                                    type="date"
                                    value={checkin}
                                    handleChange={setCheckin}
                              />
                              <DestinationItemBox
                                    width="15%"
                                    value={checkout}
                                    title={to ? 'Return on' : 'Check Out'}
                                    handleChange={setCheckout}
                                    type="date"
                              />
                              <DestinationItemBox
                                    width="15%"
                                    title={to ? 'Travellers' : 'Rooms & Guests'}
                                    name="travelers"
                                    value={travelers}
                                    type="number"
                                    data={travelers}
                                    setData={setTravelers}
                              />
                              {to && (
                                    <DestinationItemBox
                                          width="15%"
                                          name="flight-type"
                                          selectTitle="Select class"
                                          value={flightType}
                                          handleChange={setFlightType}
                                          title="Class"
                                          type="select"
                                          options={types}
                                    />
                              )}
                              <DestinationContent width="15%" className="mt-4">
                                    <EnquiryButton>
                                          <Link to={'#'} onClick={handleShow}>
                                                {loadingModal ? (
                                                      <Spinner />
                                                ) : (
                                                      'Enquiry Now'
                                                )}
                                          </Link>
                                    </EnquiryButton>
                              </DestinationContent>
                        </BookingWrapper>
                  </EnquiryBannerContainer>
                  <Offcanvas show={show} onHide={handleClose} placement="end">
                        <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                    <h3 className="mb-0">Enquiry Form</h3>
                              </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                              <EnquiryForm
                                    setEmail={setEmail}
                                    setMessage={setMessage}
                                    setName={setName}
                                    loading={loading}
                                    handleForm={handleForm}
                                    setMobileNumber={setMobileNumber}
                              />
                        </Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};

export default EnquiryBanner;
