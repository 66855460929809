import React from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { FormButtonContainer } from './style';

const FormButton = ({ classes, title, type, loading }) => {
      return (
            <>
                  <Col lg={classes ? classes : 6}>
                        <FormButtonContainer type={type ? type : 'submit'}>
                              {loading ? (
                                    <>
                                          <Spinner />
                                    </>
                              ) : (
                                    title
                              )}
                        </FormButtonContainer>
                  </Col>
            </>
      );
};

export default FormButton;
