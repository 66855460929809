import styled from 'styled-components';

// application sector css
export const ApplicationSection = styled.section`
      padding: 80px 0px 140px;
      background-image: url('/images/img/application/background.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      @media screen and (max-width: 600px) {
            padding: 40px 0 70px;
      }
`;
