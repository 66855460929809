import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// Define keyframes
const bobFloat = keyframes`
  100% {
    /* Define final styles */
    transform: translateY(-8px);
  }
`;

const bob = keyframes`
  0% {
    transform: translateY(-8px);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(-8px);
  }
`;

export const MediaItem = styled(Link)`
      display: inline-block;
      background-color: #eaf7ff;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s all ease-in;

      i {
            color: #212529;
            font-size: 20px;
      }

      svg {
            fill: #212529;
            height: 20px;
            width: 20px;
      }

      &:hover {
            animation-name: ${bobFloat}, ${bob};
            animation-duration: 0.3s, 1.5s;
            animation-delay: 0s, 0.3s;
            animation-timing-function: ease-out, ease-in-out;
            animation-iteration-count: 1, infinite;
            animation-fill-mode: forwards;
            animation-direction: normal, alternate;
            background-color: ${(props) => props.theme.primary};

            i {
                  color: ${(props) => props.theme.white};
            }
      }
`;
