import React from 'react';
import { IconBox } from './style';

const VehicleIcon = () => {
      return (
            <IconBox>
                  <svg
                        version="1.1"
                        id="Icons"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 32 32"
                        style={{ enableBackground: 'new 0 0 32 32' }}
                        xmlSpace="preserve"
                  >
                        <path
                              className="st0"
                              d="M27,23H5c-1.1,0-2-0.9-2-2v-4c0-2.2,1.8-4,4-4h18c2.2,0,4,1.8,4,4v4C29,22.1,28.1,23,27,23z"
                        ></path>
                        <path
                              className="st0"
                              d="M26,13H6l2-4.6C8.6,7,10,6,11.6,6h8.7c1.6,0,3,1,3.7,2.4L26,13z"
                        ></path>
                        <path
                              className="st0"
                              d="M10,23H4v2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2V23z"
                        ></path>
                        <path
                              className="st0"
                              d="M28,23h-6v2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2V23z"
                        ></path>
                        <line
                              className="st0"
                              x1="3"
                              y1="12"
                              x2="6.4"
                              y2="12"
                        ></line>
                        <line
                              className="st0"
                              x1="26"
                              y1="12"
                              x2="29"
                              y2="12"
                        ></line>
                        <path
                              className="st0"
                              d="M21,23H11l1.4-2.9c0.3-0.7,1-1.1,1.8-1.1h3.5c0.8,0,1.5,0.4,1.8,1.1L21,23z"
                        ></path>
                        <line className="st0" x1="6" y1="18" x2="9" y2="18"></line>
                        <line
                              className="st0"
                              x1="23"
                              y1="18"
                              x2="26"
                              y2="18"
                        ></line>
                  </svg>
            </IconBox>
      );
};

export default VehicleIcon;
