import React from 'react';
import { WidgetContainer } from './styles';
import WidgetBox from '../WidgetBox';
import WidgetCategoryItem from '../WidgetCategoryItem';
import WidgetBlogBox from '../WidgetBlogBox';
import WidgetSearch from '../WidgetSearch';

const BlogWidget = ({ categories, blogs }) => {
      return (
            <>
                  <WidgetContainer>
                        {/* <WidgetSearch /> */}
                        <WidgetBox title="Blog Categories">
                              <ul>
                                    {categories?.map((category, index) => (
                                          <WidgetCategoryItem
                                                category={category}
                                                key={index}
                                          />
                                    ))}
                              </ul>
                        </WidgetBox>
                        <WidgetBox title="Recent Blog">
                              {blogs?.map((blog, index) => (
                                    <WidgetBlogBox blog={blog} key={index} />
                              ))}
                        </WidgetBox>
                  </WidgetContainer>
            </>
      );
};

export default BlogWidget;
