import styled, { keyframes } from 'styled-components';

export const FlyingAnimation = keyframes`
0% {
    transform: translateY(0);
}

100% {
    transform: translateY(-10px);
}
`;
export const MyBookingContainer = styled.section`
      position: relative;
      padding: 60px 0px;
      margin-bottom: 0px !important;
      background-image: url(https://walkerton.modeltheme.com/wp-content/uploads/2023/10/map-bg.png?id=65085) !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: contain !important;
`;
export const MyBookingForm = styled.div`
      padding: 31px 30px;
      -webkit-backdrop-filter: saturate(180%) blur(20px);
      backdrop-filter: saturate(180%) blur(20px);
      background-color: #ffffff4d;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      label {
            color: #1d315e;
            font-size: 15px;
      }
      .form-control:focus {
            box-shadow: none;
            border-color: ${(props) => props.theme.primary};
      }
      a {
            display: block;
            text-align: center;
            padding: 11px 35px;
            font-size: 15px;
      }
      p {
            font-size: 14px;
            font-weight: 500;
            color: ${(props) => props.theme.primary};
      }
`;

export const MyBookingImage = styled.div`
      position: absolute;
      top: 0;
      left: 0;
      left: 10%;
      top: 60px;

      -webkit-animation: ${FlyingAnimation} 1s infinite alternate;
      animation: ${FlyingAnimation} 1s infinite alternate;
      img {
            width: 200px;
            transform: rotate(339deg);
            ${'' /* height: 100px; */}
      }
`;
