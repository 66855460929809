import styled from 'styled-components';

export const DestinationMonthContainer = styled.div`
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
`;

export const DestinationMonthTitle = styled.div`
      margin-bottom: 10px;
`;

export const DestinationMonthIcon = styled.div`
      border: 1px solid ${(props) => props.theme.primary};
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: auto;
      margin-bottom: 10px;

      span {
            font-size: 32px;
      }
`;

export const DestinationMonthItem = styled.div`
      border: 1px solid #ddd;
      padding: 20px;
      min-width: ${(props) => (props.width ? props.width : '120px')};
      text-align: center;
      border-radius: 5px;
      background-color: ${(props) => props.theme.white};
      transition: all 0.3s ease-in;
      cursor: pointer;

      &:hover {
            background-color: ${(props) => props.theme.primary};
            color: ${(props) => props.theme.white};

            ${DestinationMonthIcon} {
                  border-color: ${(props) => props.theme.white};
            }
      }
`;
