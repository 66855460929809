import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MoreButtonContainer = styled(Link)`
   background-color: #224082;
   padding: 11px 15px;
   color: #ffffff;
   border-radius: 7px;
   font-size: 15px;
`;
