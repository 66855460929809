import React from 'react';
import { Dot, Shape, SubTitleWrapper } from './styles';

const SubTitle = ({ subtitle, left, right, center, size }) => {
      return (
            <>
                  <SubTitleWrapper center={center} size={size}>
                        {left ? (
                              <Shape>
                                    <Dot left={left}></Dot>
                              </Shape>
                        ) : (
                              ''
                        )}
                        <span>{subtitle}</span>
                        {right ? (
                              <Shape>
                                    <Dot right={right}></Dot>
                              </Shape>
                        ) : (
                              ''
                        )}
                  </SubTitleWrapper>
            </>
      );
};

export default SubTitle;
