import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { PackageSliderContainer } from './styles';
import PackageItem from '../PackageItem';
import axios from 'axios';

const PackageSlider = () => {
      const [loading, setLoading] = useState(false);
      const [packages, setPackages] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/packages`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPackages(response.data.packages);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <PackageSliderContainer>
                        <Swiper
                              slidesPerView={1}
                              spaceBetween={10}
                              pagination={false}
                              autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false
                              }}
                              breakpoints={{
                                    640: {
                                          slidesPerView: 2,
                                          spaceBetween: 20
                                    },
                                    768: {
                                          slidesPerView: 4,
                                          spaceBetween: 20
                                    },
                                    1024: {
                                          slidesPerView: 4,
                                          spaceBetween: 20
                                    }
                              }}
                              modules={[Pagination, Autoplay]}
                              className="mySwiper"
                        >
                              {packages?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                          <PackageItem item={item} />
                                    </SwiperSlide>
                              ))}{' '}
                        </Swiper>
                  </PackageSliderContainer>
            </>
      );
};

export default PackageSlider;
