import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { EnquiryContent } from '../../../common/EnquiryBanner/styles';
import { ImageLayout, ImagelayoutContainer } from '../ThreeImageLayout/style';

const FiveImageLayout = ({ item }) => {
      return (
            <>
                  <ImagelayoutContainer>
                        <Row>
                              <Col lg={12}>
                                    <EnquiryContent className="text-center mb-3">
                                          <h3>{item.title}</h3>
                                    </EnquiryContent>
                              </Col>
                              <Col lg={6}>
                                    <ImageLayout margin={'20px'} height="404px">
                                          <img
                                                src={item.first_image}
                                                alt={item.title}
                                          />
                                    </ImageLayout>
                              </Col>
                              <Col lg={6}>
                                    <Row className="g-4">
                                          <Col lg={6}>
                                                <ImageLayout height="190px">
                                                      <img
                                                            src={
                                                                  item.second_image
                                                            }
                                                            alt={item.title}
                                                      />
                                                </ImageLayout>
                                          </Col>
                                          <Col lg={6}>
                                                <ImageLayout height="190px">
                                                      <img
                                                            src={
                                                                  item.third_image
                                                            }
                                                            alt={item.title}
                                                      />
                                                </ImageLayout>
                                          </Col>
                                          <Col lg={6}>
                                                <ImageLayout height="190px">
                                                      <img
                                                            src={
                                                                  item.fourth_image
                                                            }
                                                            alt={item.title}
                                                      />
                                                </ImageLayout>
                                          </Col>
                                          <Col lg={6}>
                                                <ImageLayout height="190px">
                                                      <img
                                                            src={
                                                                  item.fifth_image
                                                            }
                                                            alt={item.title}
                                                      />
                                                </ImageLayout>
                                          </Col>
                                    </Row>
                              </Col>
                        </Row>
                  </ImagelayoutContainer>
            </>
      );
};

export default FiveImageLayout;
