import React from 'react';
import { PrivacyPageContainer } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import { PrivacyItem, PrivacyNavbar } from './components';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';

const PrivacyPage = () => {
      return (
            <>
                  <BreadcrumbBox title={'Privacy Policy'} />
                  <PrivacyPageContainer>
                        <Container>
                              <Row className="g-0">
                                    <Col lg={2}>
                                          <PrivacyNavbar />
                                    </Col>
                                    <Col lg={10}>
                                          <PrivacyItem />
                                    </Col>
                              </Row>
                        </Container>
                  </PrivacyPageContainer>
            </>
      );
};

export default PrivacyPage;
