import React, { useEffect, useState } from 'react';
import { BlogContainer } from '../blogs/styles';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Container, Row } from 'react-bootstrap';
import BlogWidget from '../../components/frontend/blogs/BlogWidget';
import {
      BlogDetail,
      BlogDetailCategory,
      BlogDetailItem
} from '../../components/frontend/blogs/BlogBox/styles';
import { BlogDetailBody, BlogDetailImage } from './styles';
import { useParams } from 'react-router';
import axios from 'axios';

const BlogDetailPage = () => {
      const { blogId } = useParams();
      const [loading, setLoading] = useState(false);
      const [blog, setBlog] = useState([]);
      const [categories, setCategories] = useState([]);
      const [blogs, setBlogs] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blogs/${blogId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlog(response.data.blog);
                              setCategories(response.data.categories);
                              setBlogs(response.data.blogs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BreadcrumbBox title={blog.title} />
                  <BlogContainer>
                        <Container>
                              <Row>
                                    <Col lg={8}>
                                          <BlogDetail>
                                                <BlogDetailCategory>
                                                      {blog.category}
                                                </BlogDetailCategory>
                                                <BlogDetailItem>
                                                      <i className="bx bx-calendar"></i>
                                                      <span>{blog.date}</span>
                                                </BlogDetailItem>
                                                <BlogDetailItem>
                                                      <i className="bx bx-edit-alt"></i>
                                                      By <span> Admin</span>
                                                </BlogDetailItem>
                                          </BlogDetail>
                                          <BlogDetailImage>
                                                <img
                                                      src={blog.image}
                                                      alt={blog.title}
                                                />
                                          </BlogDetailImage>
                                          <BlogDetailBody>
                                                <div
                                                      dangerouslySetInnerHTML={{
                                                            __html: blog.description
                                                      }}
                                                ></div>
                                          </BlogDetailBody>
                                    </Col>
                                    <Col lg={4}>
                                          <BlogWidget
                                                categories={categories}
                                                blogs={blogs}
                                          />
                                    </Col>
                              </Row>
                        </Container>
                  </BlogContainer>
            </>
      );
};

export default BlogDetailPage;
