import styled from 'styled-components';

export const IconBox = styled.span`
      svg {
            height: 32px;
            width: 32px;
      }

      .st0 {
            fill: none;
            stroke: #5191fa;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
      }
      .st1 {
            fill: none;
            stroke: #5191fa;
            stroke-width: 2;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
      }
`;
