import React from 'react';
import { ButtonClose } from './style';

const CloseButton = ({ handleClose }) => {
      return (
            <>
                  <ButtonClose
                        onClick={handleClose}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                  ></ButtonClose>
            </>
      );
};

export default CloseButton;
