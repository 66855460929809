import styled from 'styled-components';

export const BlogItemContainer = styled.div``;

export const BlogItemDetail = styled.div`
      font-size: 13px;

      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24;
      }
`;
export const BlogItemDate = styled.div`
      background: ${(props) => props.theme.primary};
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 0px;
      right: 0;
      width: 80px;
      border-radius: 8px 0 0;
      padding: 15px 20px 30px;
      line-height: 16px;
      z-index: 11;
      font-size: 14px;
      text-transform: uppercase;
      -webkit-transition: all 0.35s;
      transition: all 0.35s;
      letter-spacing: 0;
`;

export const BlogItemDetailContent = styled.div`
      position: relative;
`;

export const BlogItemWrapper = styled.div`
      position: relative;
      &:after {
            content: '';
            width: 100%;
            height: 20px;
            border-radius: 20px 20px 0 0;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 11;
            background: #fff;
      }
`;
