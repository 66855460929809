import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { DestinationContainer } from '../destination_details/styles';
import { Col, Container, Row } from 'react-bootstrap';
import ApplicationItem from '../../components/frontend/home/DomesticDestinationBox/components/ApplicationItem';

const DestinationCategory = () => {
      const { categoryId } = useParams();

      const [loading, setLoading] = useState(false);
      const [destinations, setDestinations] = useState([]);
      const [category, setCategory] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/destinations/${categoryId}/category`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategory(response.data.category);
                              setDestinations(response.data.destinations);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [categoryId]);

      return (
            <>
                  <BreadcrumbBox title={`${category.title} Destination`} />
                  <DestinationContainer>
                        <Container>
                              <Row className="g-4">
                                    {destinations?.length > 0 ? (
                                          destinations?.map((item, index) => (
                                                <Col lg={4} key={index}>
                                                      <ApplicationItem
                                                            title={item.title}
                                                            image={item.image}
                                                            link={`/destinations/${item.slug}`}
                                                      />
                                                </Col>
                                          ))
                                    ) : (
                                          <h3>No destinations found</h3>
                                    )}
                              </Row>
                        </Container>
                  </DestinationContainer>
            </>
      );
};

export default DestinationCategory;
