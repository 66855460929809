import React from 'react';
import {
      DestinationContent,
      DestinationIcon,
      DestinationItem,
      DestinationTitle,
      DestinationWrapper
} from '../styles';
import { Form } from 'react-bootstrap';
import TravelerBox from '../TravelerBox';

const DestinationItemBox = ({
      title,
      icon,
      value,
      width,
      type,
      options,
      name,
      handleChange,
      data,
      setData,
      selectTitle
}) => {
      const currentDate = new Date().toISOString().split('T')[0];
      return (
            <>
                  <DestinationContent width={width}>
                        <DestinationTitle>{title}</DestinationTitle>
                        <DestinationItem
                              className={`${type === 'select' ? 'p-0' : ''}`}
                        >
                              {icon && (
                                    <DestinationIcon>{icon}</DestinationIcon>
                              )}
                              <DestinationWrapper>
                                    {type === 'select' ? (
                                          <Form.Select
                                                style={{
                                                      paddingTop: '9px',
                                                      paddingBottom: '9px'
                                                }}
                                                onChange={(event) =>
                                                      handleChange(
                                                            event.target.value
                                                      )
                                                }
                                                defaultValue={value}
                                          >
                                                <option value="">
                                                      {type === 'select'
                                                            ? selectTitle
                                                            : 'Select One'}
                                                </option>
                                                {options?.length > 0 ? (
                                                      options?.map(
                                                            (item, index) => (
                                                                  <option
                                                                        value={
                                                                              item.id
                                                                        }
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        {
                                                                              item.title
                                                                        }
                                                                  </option>
                                                            )
                                                      )
                                                ) : (
                                                      <>
                                                            <option value="">
                                                                  No data found
                                                            </option>
                                                      </>
                                                )}
                                          </Form.Select>
                                    ) : type === 'number' ? (
                                          <TravelerBox
                                                type={'hidden'}
                                                data={data}
                                                setData={setData}
                                          />
                                    ) : (
                                          <Form.Control
                                                type={type ? type : 'text'}
                                                min={currentDate}
                                                name={name}
                                                value={value}
                                                onChange={(event) =>
                                                      handleChange(
                                                            event.target.value
                                                      )
                                                }
                                          />
                                    )}
                              </DestinationWrapper>
                        </DestinationItem>
                  </DestinationContent>
            </>
      );
};

export default DestinationItemBox;
