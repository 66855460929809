import styled from 'styled-components';

export const PackageAccordionContainer = styled.div`
      .accordion-item {
            margin-bottom: 20px;
            border-left: 5px solid ${(props) => props.theme.primary};
      }
      .accordion-item:not(:first-of-type) {
            border-top: 1px solid #dee2e6 !important;
            border-top-left-radius: 5px;
      }
      .accordion-button:focus {
            z-index: 3;
            border-color: var(--bs-accordion-btn-focus-border-color);
            outline: 0;
            box-shadow: none;
      }
      .accordion-button:not(.collapsed) {
            color: var(--bs-accordion-active-color);
            background-color: transparent;
            box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
                  var(--bs-accordion-border-color);
      }
`;

export const PackageAccordionHeading = styled.h3`
      font-size: 24px;
`;
