import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const BookingTitle = styled.div`
      margin-top: 30px;
      text-align: center;
`;
export const BookingContainer = styled.div`
      margin: 2rem 0;
      position: relative;
      z-index: 11;
`;
export const BookingWrapper = styled.div`
      display: flex;
      flex-wrap: wrap;

      background: ${(props) =>
            props.customBackground
                  ? props.customBackground
                  : props.theme.primary};
      border-radius: 0.25rem;
      padding: ${(props) => (props.customBackground ? '' : '1.5rem')};
      position: relative;
      z-index: 6;

      @media screen and (max-width: 600px) {
            row-gap: 15px;
      }
`;
export const BookingHeader = styled.div`
      background-color: ${(props) => props.theme.primary};
      display: inline-block;
      color: ${(props) => props.theme.white};
      text-align: center;
      font-size: 15px;
      border-radius: 0.25rem 0.25rem 0 0;
      text-transform: uppercase;
      padding: 12px 20px;
      height: 40px;
`;
export const DestinationContent = styled.div`
      width: ${(props) => props.width};
      padding-right: 10px;
      margin: auto 0 0;

      @media screen and (max-width: 600px) {
            width: 50%;

            &:nth-child(even) {
                  padding-right: 0;
            }
      }
`;
export const DestinationTitle = styled.div`
      display: block;
      margin-bottom: 5px;
      color: ${(props) => props.theme.white};
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0;
      box-shadow: none;
      line-height: 18px;
`;
export const DestinationItem = styled.div`
      display: flex;
      align-items: center;
      border-radius: 0.25rem;
      background-color: ${(props) =>
            props.color ? props.color : props.theme.white};
      /* height: 100%; */
      padding: 10px;

      .form-control {
            padding: 0;

            &:focus {
                  box-shadow: none;
            }
      }
`;
export const DestinationIcon = styled.div`
      width: ${(props) => props.width};
      color: #777;
      padding-right: 10px;
      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
      }
      svg {
            width: 19px;
            display: inline-block;
            font-size: inherit;
            height: 18px;
            overflow: visible;
            vertical-align: -0.125em;
            color: #777;
      }
`;
export const DestinationWrapper = styled.div`
      width: 100%;
      input {
            outline: 0;
            border: 0;
            width: 100%;
            &:focusvisible {
                  outline: 0;
            }
      }

      button {
            background-color: transparent;
            border: 0;

            .spinner-border {
                  font-size: 16px;
                  height: 16px !important;
                  width: 16px !important;
                  margin-left: 10px;
            }
      }
`;
export const FormWrapper = styled.div`
      position: relative;
`;
export const FormInfo = styled.div`
      position: relative;
      span {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            overflow: hidden;
            color: ${(props) => props.theme.black};
      }
      &:hover {
            cursor: pointer;
      }
      &:before {
            background-color: var(--bs-body-bg);
            background-image: url(/images/svg/download.svg);
            background-repeat: no-repeat;
            background-position: right 0.75rem center;
            background-size: 16px 12px;
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            height: 20px;
            width: 27px;
      }
`;

export const FormAdditional = styled.div`
      position: absolute;
      z-index: 15001;
      top: 42px;
      left: -35px;
      opacity: 0;
      display: none;
      min-width: 240px;
      border: 1px solid #eef0f2;
      background-color: #fff;
      color: #333;
      font-size: 13px;
      font-weight: normal;
      box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.25);
      border-radius: 3px;
      &:after {
            background: #ffffff;
            -webkit-clip-path: polygon(0 100%, 49% 0, 100% 100%);
            clip-path: polygon(0 100%, 49% 0, 100% 100%);
            content: '';
            height: 15px;
            left: 100px;
            position: absolute;
            top: -9px;
            width: 26px;
            z-index: 10;
      }
      &.show {
            display: block;
            opacity: 1;
      }
`;
export const FormAdditionalHeader = styled.div``;
export const FormAdditionalTitle = styled.div`
      margin-bottom: 5px;
`;
export const FormAdditionalCounter = styled.div`
      display: table;
`;

export const FormAdditionalCounterIcon = styled.div`
      width: 24px;
      height: 24px;
      line-height: 24px;
      border: 1px solid #ccc;
      border-color: #e0e0e0;
      border-radius: 4px 0 0 4px;
      line-height: 32px;
      background: #fafafa;
      display: inline-flex;
      text-align: center;
      cursor: pointer;
      -webkit-user-select: none;
      vertical-align: middle;
      &.add {
            border-radius: 0px 4px 4px 0px;
      }
`;
export const FormAdditionalCounterInput = styled.div`
      display: inline-block;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      height: 24px;
      input {
            width: 40px !important;
            height: 19px;
            font-size: 12px;
            text-align: center;
            display: inline-flex;
      }
`;

export const AdultChildSection = styled.div`
      border-top: 1px dashed rgba(33, 37, 41, 0.3);
`;
export const AdultChildTitle = styled.div`
      margin-bottom: 9px;
`;
export const AdultSection = styled.div``;
export const ChildSection = styled.div``;
