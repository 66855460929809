import React from 'react';
import {
      WidgetHeader,
      WidgetItem,
      WidgetItemBody,
      WidgetItemTitle
} from './styles';

const WidgetBox = ({ title, children }) => {
      return (
            <>
                  <WidgetItem>
                        <WidgetHeader>
                              <WidgetItemTitle>{title}</WidgetItemTitle>
                        </WidgetHeader>
                        <WidgetItemBody>{children}</WidgetItemBody>
                  </WidgetItem>
            </>
      );
};

export default WidgetBox;
