import styled from 'styled-components';

export const PopupContainer = styled.div``;
export const PopupTitle = styled.h5``;
export const PopupIcon = styled.div``;
export const PopupIconItem = styled.div`
      img {
            width: 65px;
            height: auto;
      }
`;
