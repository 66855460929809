import React from 'react';

const LinkedinIcon = () => {
      return (
            <>
                  <i className="bx bxl-linkedin-square"></i>
            </>
      );
};

export default LinkedinIcon;
