import styled from 'styled-components';

export const PackageContainer = styled.div`
      position: relative;
      z-index: 11;
      margin-bottom: 5rem;
`;
export const PackageHeading = styled.div`
      text-align: center;
      margin-bottom: 3rem;

      @media screen and (max-width: 600px) {
            margin-bottom: 1.2rem;
      }
`;
