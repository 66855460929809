import { React, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import {
      FreeMode,
      Navigation,
      Thumbs,
      EffectFade,
      Autoplay
} from 'swiper/modules';
import {
      BannerContainer,
      BannerContent,
      BannerContentTitle,
      BannerImage,
      BannerSection,
      BannerShape
} from './styles';
import CommonButton from '../../../common/CommonButton';
import { Link } from 'react-router-dom';
import axios from 'axios';

const BannerBox = () => {
      const [thumbsSwiper, setThumbsSwiper] = useState(null);
      const [loading, setLoading] = useState(false);
      const [banners, setBanners] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/banners`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBanners(response.data.banners);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {!loading && (
                        <BannerSection>
                              <Swiper
                                    style={{
                                          '--swiper-navigation-color': '#fff',
                                          '--swiper-pagination-color': '#fff'
                                    }}
                                    autoplay={{
                                          delay: 8000,
                                          disableOnInteraction: false
                                    }}
                                    effect={'fade'}
                                    spaceBetween={10}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    modules={[
                                          FreeMode,
                                          Navigation,
                                          Thumbs,
                                          EffectFade,
                                          Autoplay
                                    ]}
                                    loop={true}
                                    className="mySwiper2"
                              >
                                    {banners?.map((banner, index) => (
                                          <SwiperSlide key={index}>
                                                <BannerContainer>
                                                      <BannerImage>
                                                            <Link
                                                                  to={`${banner.link}`}
                                                            >
                                                                  <img
                                                                        src={
                                                                              banner.image
                                                                        }
                                                                        alt={
                                                                              banner.title
                                                                        }
                                                                        className="img-fluid"
                                                                  />
                                                            </Link>
                                                      </BannerImage>
                                                      <BannerContent>
                                                            <BannerContentTitle>
                                                                  <span>
                                                                        {
                                                                              banner.subtitle
                                                                        }
                                                                  </span>
                                                                  <h1>
                                                                        {
                                                                              banner.slogan_first
                                                                        }
                                                                        {banner.slogan_second && (
                                                                              <>
                                                                                    <br />
                                                                                    {
                                                                                          banner.slogan_second
                                                                                    }
                                                                              </>
                                                                        )}
                                                                  </h1>

                                                                  <p>
                                                                        {
                                                                              banner.summary
                                                                        }
                                                                  </p>
                                                            </BannerContentTitle>
                                                            {banner.button_name && (
                                                                  <CommonButton
                                                                        link={
                                                                              banner.link
                                                                        }
                                                                        title={
                                                                              banner.button_name
                                                                        }
                                                                  />
                                                            )}
                                                      </BannerContent>
                                                </BannerContainer>
                                          </SwiperSlide>
                                    ))}
                              </Swiper>
                              <BannerShape>
                                    <img
                                          src="/images/img/banners/slider-mask.png"
                                          alt=""
                                          className="img-fluid"
                                    />
                              </BannerShape>
                        </BannerSection>
                  )}
            </>
      );
};

export default BannerBox;
