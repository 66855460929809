import styled from 'styled-components';

export const HeaderContainer = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   z-index: 100;
   width: 100%;

   @media screen and (max-width: 600px) {
      position: static;
   }
`;
export const HeaderTop = styled.div`
   margin: 0px 32px;
   /* border-bottom: 1px solid ${(props) => props.theme.white}; */
   line-height: 1;
   position: relative;
   z-index: 10;
   ${
      '' /* &:before {
            content: '';
            height: 100%;
            width: 50%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: ${(props) => props.theme.headerBackground};
            -webkit-clip-path: polygon(
                  0 0,
                  100% 0,
                  calc(100% - 40px) 100%,
                  0% 100%
            );
            clip-path: polygon(0 0, 100% 0, calc(100% - 40px) 100%, 0% 100%);
            z-index: -1;
      } */
   }
   a {
      color: ${(props) => props.theme.white};
      line-height: 1.5;
   }
`;
export const HeaderInfoItem = styled.div``;
export const HeaderTopContainer = styled.div``;
export const HeaderInfo = styled.div`
   font-size: 13px;
   ${'' /* padding: 10px 0px; */}
   font-weight: 300;
   i {
      font-size: 17px;
   }
   svg {
      fill: ${(props) => props.theme.white};
      width: 17px;
      height: 17px;
   }
`;
export const HeaderInfoContent = styled.div`
   padding: 10px 0px;
   font-size: 13px;
   font-weight: 300;
`;
export const HeaderInfoIcon = styled.div`
   font-size: 17px;
   color: #fff;
`;
