import React from 'react';
import { PackageDetailContainer } from '../../styles';
import {
      PackageDetailContent,
      PackageDetailIcon,
      PackageDetailInfo,
      PackageDetailItem,
      PackageDetailLocation,
      PackageDetailTitle
} from './styles';
import LocationIcon from '../../../../../icons/LocationIcon/LocationIcon';
import { Col, Row } from 'react-bootstrap';
import {
      FootIcon,
      GroupIcon,
      MessageIcon,
      PackageTimeIcon,
      VehicleIcon
} from '../../../../../icons';

const PackageDetailHeading = ({ item }) => {
      const packageHeadings = [
            {
                  id: 1,
                  title: 'Duration',
                  icon: <PackageTimeIcon />,
                  content: item.duration
            },
            {
                  id: 2,
                  title: 'Hotel Category',
                  icon: <FootIcon />,
                  content: item.hotel_category
            },
            {
                  id: 3,
                  title: 'Vehicle',
                  icon: <VehicleIcon />,
                  content: item.vehicle
            },
            {
                  id: 4,
                  title: 'No. of Traveller',
                  icon: <MessageIcon />,
                  content: item.group_size
            }
      ];
      return (
            <>
                  <PackageDetailContainer>
                        <PackageDetailTitle>{item.title}</PackageDetailTitle>
                        <PackageDetailLocation className="d-flex align-items-center gap-2">
                              <LocationIcon />
                              {`${item.location}, ${item.destination}`}
                        </PackageDetailLocation>
                        <hr />
                        <PackageDetailContent>
                              {packageHeadings?.map((data, index) => (
                                    <PackageDetailItem key={index}>
                                          <PackageDetailIcon>
                                                {data.icon}
                                          </PackageDetailIcon>
                                          <PackageDetailInfo>
                                                <h5>{data.title}</h5>
                                                <p className="mb-0">
                                                      {data.content}
                                                </p>
                                          </PackageDetailInfo>
                                    </PackageDetailItem>
                              ))}
                        </PackageDetailContent>
                  </PackageDetailContainer>
            </>
      );
};

export default PackageDetailHeading;
