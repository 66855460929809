import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const AdditionalContainer = styled.section`
      padding: 0px 0px 60px;

      @media screen and (max-width: 600px) {
            padding: 40px 20px;
      }
`;
export const AdditionalHeading = styled.div``;
export const AdditionalWrapper = styled.div`
      background-image: url(https://demo2.pavothemes.com/triply/wp-content/uploads/2020/11/h1_shape-4.png);
      ${'' /* background-position: 100% 55%; */}
      background-repeat: no-repeat;
      margin-top: 100px;

      @media screen and (max-width: 600px) {
            margin-top: 40px;

            .row {
                  --bs-gutter-y: 0;
                  --bs-gutter-x: 1.5rem;
            }
      }
`;
export const AdditionalCol = styled(Col)`
      &:nth-child(2) {
            transform: translateY(-45px);
      }
      &:last-child(2) {
            transform: translateY(62px);
      }

      @media screen and (max-width: 600px) {
            margin-bottom: 30px;
            padding: 0;

            &:nth-child(2) {
                  transform: translateY(0);
            }

            &:last-child {
                  margin-bottom: 0;
            }
      }
`;

export const AdditionalImage = styled.div`
      padding: 40px 20px 0px 20px;
      overflow: hidden;

      a {
            -webkit-mask-image: url(/images/img/additional/thumb-mask-image.png);
            mask-image: url(/images/img/additional/thumb-mask-image.png);
            -webkit-mask-position: bottom right;
            mask-position: bottom right;
            -webkit-mask-size: cover;
            mask-size: cover;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            width: 100%;
      }
      img {
            display: block;
            float: none;
            transform: scale(1);
            height: auto;
            transition: transform 0.28s ease;
            object-fit: cover;
      }
`;

export const AdditionalContent = styled.div`
      padding: 30px 30px;
      h3 {
            color: ${(props) => props.theme.primary};

            font-size: 19px;
            font-weight: 600;
      }
      h5 {
            font-size: 17px;
            color: #212121;

            margin-bottom: 15px;
      }
      p {
            font-size: 15px;
            color: #555;
            text-align: left;
      }
      @media screen and (max-width: 600px) {
            padding: 30px 20px;
      }
`;

export const AdditionalImagePin = styled.div`
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;

      img {
            width: 30px;
            height: 30px;
      }
`;

export const AdditionalItem = styled.div`
      position: relative;
      background: ${(props) => props.theme.white};
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      overflow: hidden;
      &:hover {
            ${AdditionalImage} {
                  img {
                        transform: scale(1.1);
                        transition: transform 0.28s ease;
                  }
            }
            ${AdditionalContent} {
                  a {
                        h3 {
                              color: ${(props) => props.theme.primary};
                        }
                  }
            }
      }
`;

export const AdditionalRating = styled.div`
      display: flex;
      gap: 10px;
      align-items: center;
      color: #ff9529;
      i {
            color: #ff9529;
      }
`;
