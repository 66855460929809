import React from 'react';
import { SocialMediaLinkItem } from './styles';
import { Link } from 'react-router-dom';

const SocialMediaLink = ({ link, icon, color }) => {
      return (
            <>
                  <SocialMediaLinkItem color={color || ''}>
                        <Link to={link}>{icon}</Link>
                  </SocialMediaLinkItem>
            </>
      );
};

export default SocialMediaLink;
