import styled from 'styled-components';

export const PackageSliderContainer = styled.div`
      margin: 0 8px;
      .mySwiper {
            padding: 20px;
            margin: -20px;
      }

      .swiper-slide {
            height: auto;
      }

      @media screen and (max-width: 600px) {
            margin-bottom: 1rem;
      }
`;
