import styled, { css, keyframes } from 'styled-components';
import { CommonButtonContainer } from '../../../common/CommonButton/styles';

export const BannerContainer = styled.div`
      position: relative;
      overflow: clip;
      ${
            '' /* &:after {
            content: '';
            mask-image: url(/images/img/banners/slider-mask.png);
            -webkit-mask-image: url(images/img/banners/slider-mask.png);
            mask-position: center center;
            -webkit-mask-position: center center;
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            mask-size: contain;
            -webkit-mask-size: contain;
            background-color: ${(props) => props.theme.white};
            height: 160px;
            bottom: -17px;
            z-index: 1;
            position: absolute;
            left: 0;
            right: 0;
      } */
      }
`;

export const BannerShape = styled.div`
      position: absolute;
      bottom: -35px;
      left: 0px;
      z-index: 5;

      @media screen and (max-width: 568px) {
            bottom: -10px;
      }
`;
export const SliderAnimation = keyframes`
0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}
`;
export const BannerImage = styled.div`
      position: relative;
      &:before {
            content: '';
            height: 0%;
            width: 70%;
            background-image: -webkit-gradient(
                  linear,
                  left top,
                  right top,
                  from(#b4eabb),
                  color-stop(17.06%, #b2e3c8),
                  color-stop(38.29%, #b2dde0),
                  color-stop(52.45%, rgba(178, 240, 244, 0.85)),
                  color-stop(64.97%, rgba(170, 236, 250, 0.7)),
                  color-stop(77.13%, rgba(172, 229, 240, 0.6)),
                  color-stop(88.14%, rgba(173, 226, 236, 0.440266)),
                  to(rgba(176, 216, 223, 0))
            );
            background-image: linear-gradient(
                  90deg,
                  #ebf5ffba 0%,
                  #ebf7ff87 17.06%,
                  #ebf7ffa1 38.29%,
                  rgb(178 228 244 / 34%) 52.45%,
                  rgb(170 236 250 / 44%) 64.97%,
                  rgb(172 229 240 / 0%) 77.13%,
                  rgb(173 226 236 / 0%) 88.14%,
                  rgba(176, 216, 223, 0) 95%
            );
            background-image: linear-gradient(
                  90deg,
                  #ebf5ffdb 0%,
                  #ebf7ffb5 17.06%,
                  #ebf7ffd1 38.29%,
                  rgb(178 228 244 / 77%) 52.45%,
                  rgba(170, 236, 250, 0.7) 64.97%,
                  rgba(172, 229, 240, 0.6) 77.13%,
                  rgba(173, 226, 236, 0.440266) 88.14%,
                  rgba(176, 216, 223, 0) 100%
            );
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
      }
      img {
            width: 100%;
            height: 100vh;
      }

      @media screen and (max-width: 600px) {
            height: 306px;
            img {
                  height: 100%;
            }
      }
`;
export const BannerContent = styled.div`
      position: absolute;
      top: 55%;
      left: 8%;
      z-index: 1000;
      transform: translateY(-50%);
      color: ${(props) => props.theme.white};
`;

export const BannerContentImage = styled.div``;
export const BannerContentAnimation = css`
      opacity: 0;
      transform: translateY(20px);
      transition: opacity 0.5s ease, transform 0.5s ease;
`;
export const SlideInUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    transform: translateY(0);
  }
`;

export const SlideInUp = css`
      opacity: 0;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
      -webkit-animation-name: ${SlideInUpAnimation};
      animation-name: ${SlideInUpAnimation};
`;
export const BannerContentTitle = styled.div``;

export const BannerSection = styled.div`
      position: relative;
      ${BannerContentTitle} {
            h1 {
                  font-size: 45px;
                  margin-top: 15px;
                  margin-bottom: 25px;
                  font-family: var(--playfair__font);
                  width: 100%;
                  line-height: 1.5;
            }
            p {
                  font-family: 'Jost', Sans-serif;
                  font-weight: 300;
                  font-size: 18px;
                  margin-bottom: 25px;
            }
            span {
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 2;
                  margin-bottom: 1.5rem;
                  font-family: var(--dancing__font);
            }
      }
      ${CommonButtonContainer} {
      }
      .swiper-slide-active {
            ${BannerImage} {
                  @media only screen and (min-width: 992px) {
                        animation: ${SliderAnimation} 25s ease-out infinite;
                  }
            }
            ${BannerContentTitle} {
                  h1 {
                        ${SlideInUp}
                        opacity: 1;
                        animation-delay: 0.4s;
                  }
                  span {
                        ${SlideInUp}
                        opacity: 1;
                        animation-delay: 0.3s;
                  }
                  p {
                        ${SlideInUp}
                        opacity: 1;
                        animation-delay: 0.5s;
                  }
            }
            ${CommonButtonContainer} {
                  ${SlideInUp}
                  opacity: 1;
                  animation-delay: 0.6s;
            }
      }

      @media screen and (max-width: 600px) {
            ${BannerContentTitle} {
                  h1 {
                        font-size: 1.8rem;
                        margin-top: 0px;
                        margin-bottom: 0px;
                  }

                  p {
                        margin-bottom: 20px;
                  }

                  span {
                        font-size: 16px;
                        line-height: 1;
                  }
            }
      }
`;
