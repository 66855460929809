import React from 'react';
import {
      DestinationMonthContainer,
      DestinationMonthIcon,
      DestinationMonthItem,
      DestinationMonthTitle
} from '../DestinationMonth/style';

const PeopleList = ({ people, handleChange }) => {
      return (
            <>
                  <DestinationMonthTitle>
                        Who is travelling with you?
                  </DestinationMonthTitle>
                  <DestinationMonthContainer>
                        {people?.map((item, index) => (
                              <>
                                    <DestinationMonthItem
                                          key={index}
                                          width="140px"
                                          onClick={() =>
                                                handleChange(item.title)
                                          }
                                    >
                                          {item.icon && (
                                                <DestinationMonthIcon>
                                                      {item.icon}
                                                </DestinationMonthIcon>
                                          )}
                                          {item.title}
                                    </DestinationMonthItem>
                              </>
                        ))}
                  </DestinationMonthContainer>
            </>
      );
};

export default PeopleList;
