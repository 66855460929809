import React from 'react';

const QuestionIcon = () => {
      return (
       <>
        
                  <span className="material-symbols-outlined">live_help</span>
            </>
      );
};

export default QuestionIcon;
