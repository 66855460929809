import styled from 'styled-components';

export const AboutContainer = styled.section`
      padding: 50px 0px;
`;
export const AboutImageContainer = styled.div`
      position: relative;
      margin-right: 50px;
      padding-bottom: 45px;
      display: flex;

      @media screen and (max-width: 600px) {
            margin-right: 0;
            padding-bottom: 50px;
      }
`;
export const AboutImage = styled.div`
      border-radius: 20px;
      img {
            border-radius: 20px;
      }
      &:nth-child(2) {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: ${(props) => props.theme.white};
            padding: 10px 0 0 10px;
            border-radius: 20px;
      }

      @media screen and (max-width: 600px) {
            &:nth-child(2) {
                  width: 180px;
            }
            img {
                  width: 100%;
            }
      }
`;

export const AboutShape = styled.div`
      position: absolute;
      left: -120px;
      bottom: -30px;
      z-index: -1;

      @media screen and (max-width: 600px) {
            img {
                  width: 100%;
            }
      }
`;

export const AboutContent = styled.div`
      p {
            color: ${(props) => props.theme.paragraphColor};
      }
`;
export const AboutTitle = styled.div``;
export const AboutDesc = styled.p``;
export const AboutFeatures = styled.div`
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-bottom: 1rem;

      @media screen and (max-width: 600px) {
            flex-direction: column;
      }
`;
export const AboutFeaturesItem = styled.div`
      display: flex;
      align-items: start;
      gap: 1.5rem;
`;

export const AboutFeaturesIcon = styled.div`
      padding-top: 10px;
      svg {
            width: 45px;
            height: 45px;
            fill: ${(props) => props.theme.primary};
      }
`;
export const AboutFeaturesContent = styled.div`
      h3 {
            font-size: 23px;
            font-family: var(--playfair__font);
            font-weight: 600;
      }
`;
