import styled from 'styled-components';

export const PrivacyItemContainer = styled.div`
      background-color: ${(props) => props.theme.white};
      padding: 60px 55px;

      border: 1px solid #d0d2d3;
      border-radius: 7px;

      @media screen and (max-width: 600px) {
            padding: 20px;
      }
`;
export const PrivacyItemTitle = styled.h2`
      font-size: 30px;
      font-weight: 500;
`;
export const PrivacyItemContent = styled.div`
      p {
            text-align: justify;
      }
`;

export const PrivacyItemTime = styled.div`
      font-size: 16px;
      color: ${(props) => props.theme.primary};
      font-weight: 600;
      margin-bottom: 20px;
`;
