import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from '../frontend/home';
import About from '../frontend/about';
import Packages from '../frontend/packages';
import PackageDetail from '../frontend/packages/PackageDetail';
import ContactPage from '../frontend/contact';
import BlogPage from '../frontend/blogs';
import FaqPage from '../frontend/faqs';
import BlogDetailPage from '../frontend/blog_details';
import PrivacyPage from '../frontend/privacy';
import Practice from '../components/frontend/practice';
import PracticeBox from '../components/frontend/practice';
import { ExperienceShow } from '../frontend/experiences';
import DestinationDetail from '../frontend/destination_details';
import HotelPage from '../frontend/hotel';
import FlightPage from '../frontend/flight';
import MyBooking from '../frontend/mybooking';
import TermsAndCondition from '../frontend/terms';
import ReferralPolicy from '../frontend/referral';
import DestinationCategory from '../frontend/destination_categories';
import BlogCategory from '../frontend/blog_categories';
import ExperienceList from '../frontend/experiences/ExperienceList';
import Destination from '../frontend/destinations';

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route exact path="/" element={<Home />} />

                        <Route path="/about-us" element={<About />} />

                        {/* Package */}
                        <Route path="/packages" element={<Packages />} />
                        <Route
                              path="/packages/:packageId"
                              element={<PackageDetail />}
                        />

                        {/* Destination */}
                        <Route path="/destinations" element={<Destination />} />
                        <Route
                              path="/destinations/:destinationId"
                              element={<DestinationDetail />}
                        />
                        <Route
                              path="/destinations/:categoryId/category"
                              element={<DestinationCategory />}
                        />

                        {/* Blog Page */}
                        <Route path="/blogs" element={<BlogPage />} />
                        <Route
                              path="/blogs/:blogId"
                              element={<BlogDetailPage />}
                        />
                        <Route
                              path="/blogs/:categoryId/category"
                              element={<BlogCategory />}
                        />

                        {/* Experience */}
                        <Route
                              path="/experiences"
                              element={<ExperienceList />}
                        />
                        <Route
                              path="/experiences/:experienceId"
                              element={<ExperienceShow />}
                        />

                        {/* Faq Page */}
                        <Route path="/faqs" element={<FaqPage />} />

                        <Route path="/contact-us" element={<ContactPage />} />
                        <Route
                              path="/privacy-policy"
                              element={<PrivacyPage />}
                        />
                        <Route path="/practice" element={<PracticeBox />} />

                        {/* hotel page */}
                        <Route path="/hotels" element={<HotelPage />} />

                        {/* flights page */}
                        <Route path="/flights" element={<FlightPage />} />

                        {/* my booking page */}
                        <Route path="/my-booking" element={<MyBooking />} />

                        {/* Terms & Condition */}
                        <Route
                              path="/terms-of-service"
                              element={<TermsAndCondition />}
                        />

                        <Route
                              path="/referral-policy"
                              element={<ReferralPolicy />}
                        />
                  </Routes>
            </>
      );
};

export default PublicRouter;
