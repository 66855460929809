import React, { useContext } from 'react';
import { VideoContainer } from './styles';
import LogoContext from '../../../stores/LogoContext';

const VideoBox = () => {
      const generalCtx = useContext(LogoContext);

      return (
            <>
                  <VideoContainer className="d-flex align-items-center justify-content-center w-100">
                        <iframe
                              src={`${generalCtx.setting.youtube_video}?controls=0&showinfo=0&rel=0&autoplay=1&mute=true&loop=1&iv_load_policy=3&list=${generalCtx.setting.youtube_playlist}`}
                              title="Welcome To Aircasa"
                              frameborder="0"
                              allowfullscreen
                        ></iframe>
                  </VideoContainer>
            </>
      );
};

export default VideoBox;
