import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { BlogContainer } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import BlogBox from '../../components/frontend/blogs/BlogBox';
import BlogWidget from '../../components/frontend/blogs/BlogWidget';
import axios from 'axios';

const BlogPage = () => {
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`, {
                        headers: {
                              apikey: process.env.REACT_APP_API_KEY
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs(response.data.blogs);
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BreadcrumbBox title="Blogs" />

                  <BlogContainer>
                        <Container>
                              <Row>
                                    <Col lg={8}>
                                          <Row className="g-4">
                                                {blogs?.map((blog, index) => (
                                                      <Col lg={6} key={index}>
                                                            <BlogBox
                                                                  blog={blog}
                                                            />
                                                      </Col>
                                                ))}
                                          </Row>
                                    </Col>
                                    <Col lg={4}>
                                          <BlogWidget
                                                blogs={blogs}
                                                categories={categories}
                                          />
                                    </Col>
                              </Row>
                        </Container>
                  </BlogContainer>
            </>
      );
};

export default BlogPage;
