import React from 'react';
import { Col, Form } from 'react-bootstrap';

const FormInput = ({ classes, placeholder, handleChange,value }) => {
      return (
            <>
                  <Col lg={classes ? classes : 6}>
                        <Form.Control
                              placeholder={placeholder}
                              onChange={(event) =>
                                    handleChange(event.target.value)
                              }
                              value={value}
                        />
                  </Col>
            </>
      );
};

export default FormInput;
