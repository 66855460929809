import React from 'react';

import { Link } from 'react-router-dom';
import {
      ApplicationContentCard,
      ApplicationDescription,
      ApplicationSubTitle
} from './styles';
import CommonButton from '../../../../common/CommonButton';

const ApplicationContent = () => {
      return (
            <>
                  <ApplicationContentCard>
                        <ApplicationSubTitle>
                              Witness the Beauty of Places
                        </ApplicationSubTitle>
                        <CommonButton
                              link="/destinations"
                              title={'Explore More'}
                        />
                  </ApplicationContentCard>
            </>
      );
};

export default ApplicationContent;
