import React from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { DestinationContainer } from '../destination_details/styles';
import AbroadSection from '../../components/frontend/home/AbroadSection';
import DomesticBox from '../../components/frontend/home/DomesticBox';

const Destination = () => {
      return (
            <>
                  <BreadcrumbBox
                        // image={'/images/img/breadcrumb/aircasa banner.png'}
                        title="Explore Beautiful Places Arround The World"
                  />
                  <DestinationContainer padding="0px">
                        <AbroadSection mainTitle={true} />
                        <DomesticBox mainTitle={true} />
                  </DestinationContainer>
            </>
      );
};

export default Destination;
