import React, { useEffect, useRef } from 'react';
import Mmenu from 'mmenu-js';
import 'mmenu-js/dist/mmenu.css';
import { Link } from 'react-router-dom';

const MobileMenuItem = ({ setShow, loading, categories, services }) => {
      const menuRef = useRef(null);

      useEffect(() => {
            if (!menuRef.current) {
                  menuRef.current = new Mmenu('#menu', {
                        offCanvas: {
                              use: false
                        },
                        counters: {
                              add: true
                        },
                        setSelected: {
                              hover: true
                        },
                        navbars: [
                              {
                                    position: 'top',
                                    content: ['searchfield']
                              }
                        ]
                  });
            }
      }, []);

      return (
            <>
                  <nav id="menu">
                        <ul>
                              <li>
                                    <Link onClick={() => setShow()} to="/">
                                          Home
                                    </Link>
                              </li>
                              <li>
                                    <span>Destination</span>
                                    {!loading ? (
                                          categories.length > 0 ? (
                                                <ul>
                                                      {categories?.map(
                                                            (
                                                                  category,
                                                                  index
                                                            ) => (
                                                                  <li
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to="/"
                                                                              className="main__category"
                                                                        >
                                                                              {
                                                                                    category.title
                                                                              }
                                                                        </Link>
                                                                        {category
                                                                              ?.destinations
                                                                              ?.length >
                                                                              0 && (
                                                                              <ul>
                                                                                    {category?.destinations?.map(
                                                                                          (
                                                                                                item,
                                                                                                key
                                                                                          ) => (
                                                                                                <li
                                                                                                      key={
                                                                                                            key
                                                                                                      }
                                                                                                >
                                                                                                      <Link
                                                                                                            onClick={() =>
                                                                                                                  setShow()
                                                                                                            }
                                                                                                            to={`/destinations/${item.slug}`}
                                                                                                      >
                                                                                                            <i className="bx bx-chevron-right"></i>
                                                                                                            {
                                                                                                                  item.title
                                                                                                            }
                                                                                                      </Link>
                                                                                                </li>
                                                                                          )
                                                                                    )}
                                                                              </ul>
                                                                        )}
                                                                  </li>
                                                            )
                                                      )}
                                                </ul>
                                          ) : null
                                    ) : null}
                              </li>
                              <li>
                                    <span>Experiences</span>
                                    <ul>
                                          {!loading
                                                ? services?.length > 0 &&
                                                  services?.map(
                                                        (item, index) => (
                                                              <li key={index}>
                                                                    <Link
                                                                          onClick={() =>
                                                                                setShow()
                                                                          }
                                                                          to={`/experiences/${item.slug}`}
                                                                    >
                                                                          {
                                                                                item.title
                                                                          }
                                                                    </Link>
                                                              </li>
                                                        )
                                                  )
                                                : null}
                                    </ul>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/hotels"
                                    >
                                          Hotels
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/flights"
                                    >
                                          Flights
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/my-booking"
                                    >
                                          My Booking
                                    </Link>
                              </li>
                        </ul>
                  </nav>
            </>
      );
};

export default MobileMenuItem;
