import React from 'react';
import { DestinationMonthContainer, DestinationMonthItem, DestinationMonthTitle } from '../DestinationMonth/style';

const DurationList = ({ durations, setDuration }) => {
      return (
            <>
                  <DestinationMonthTitle>What is the duration of your holiday?</DestinationMonthTitle>
                  <DestinationMonthContainer>
                        {durations?.map((item, index) => (
                              <>
                                    <DestinationMonthItem
                                          key={index}
                                          onClick={() =>
                                                setDuration(item.title)
                                          }
                                    >
                                          {item.title}
                                    </DestinationMonthItem>
                              </>
                        ))}
                  </DestinationMonthContainer>
            </>
      );
};

export default DurationList;
