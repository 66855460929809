import React from 'react';
import {
      AdditionalBox,
      BannerBox,
      BookingBox,
      PackageBox,
      PopularDestination
} from '../../components/frontend/home';
import DomesticDestination from '../../components/frontend/home/DomesticDestinationBox';
import AbroadSection from '../../components/frontend/home/AbroadSection';
import DomesticBox from '../../components/frontend/home/DomesticBox';
import VideoBox from '../../components/frontend/VideoBox';

const Home = () => {
      return (
            <>
                  <BannerBox />
                  <BookingBox />
                  <VideoBox />
                  <PackageBox />
                  <PopularDestination />
                  <AbroadSection />
                  <DomesticBox />
                  <DomesticDestination />
                  <AdditionalBox />
            </>
      );
};

export default Home;
