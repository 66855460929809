import styled from 'styled-components';

export const WidgetSearchBox = styled.div`
      background-color: ${(props) => props.theme.primary};
      padding: 10px 20px;
      border-radius: 5px;
      margin-bottom: 30px;

      form {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .form-control {
                  background-color: transparent;
                  color: ${(props) => props.theme.white};
                  border: 0;

                  &::placeholder {
                        color: ${(props) => props.theme.white};
                  }

                  &:focus {
                        box-shadow: none;
                  }
            }

            button {
                  background-color: transparent;
                  color: ${(props) => props.theme.white};
                  border: 0;
                  font-size: 24px;
            }
      }
`;
