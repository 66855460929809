// App.js
import React, { useState } from 'react';
import TodoForm from './ToDoForm';
import TodoList from './ToDoList';
;

const ToDoBox = () => {
      const [todos, setTodos] = useState([]);

      const handleAddTodo = (newTodo) => {
            setTodos([...todos, newTodo]);
      };

      const handleDeleteTodo = (id) => {
            const updatedTodos = todos.filter((todo) => todo.id !== id);
            setTodos(updatedTodos);
      };

      return (
            <div>
                  <h1>Todo App</h1>
                  <TodoForm onAdd={handleAddTodo} />
                  <TodoList todos={todos} onDelete={handleDeleteTodo} />
            </div>
      );
};

export default ToDoBox;
