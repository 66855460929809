import styled from 'styled-components';

export const VideoContainer = styled.div`
      overflow: hidden;
      position: relative;
      padding: 3rem 0;
      padding-bottom: 10rem;
      margin: 5rem 0;
      top: 0;
      left: 0;
      pointer-events: none;
      height: 580px;

      iframe {
            position: absolute;
            width: 100%;
            height: 300%;
            top: -100%;

            video {
                  width: 100%;
                  height: 100%;
            }
      }
      @media screen and (max-width: 1400px) {
            height: 540px;
      }
      @media screen and (max-width: 1300px) {
            height: 500px;
      }
      @media screen and (max-width: 1200px) {
            height: 460px;
      }
      @media screen and (max-width: 1100px) {
            height: 400px;
      }
      @media screen and (max-width: 1000px) {
            height: 400px;
      }
      @media screen and (max-width: 900px) {
            height: 330px;
      }
      @media screen and (max-width: 800px) {
            height: 290px;
      }
      @media screen and (max-width: 700px) {
            height: 250px;
      }
      @media screen and (max-width: 600px) {
            height: 240px;
            padding: 0;
      }
      @media screen and (max-width: 568px) {
            height: 160px;
            padding: 0;
      }
      @media screen and (max-width: 400px) {
            height: 160px;
      }
`;
