import React from 'react';
import {
      AvailabelHoteContainer,
      AvailabelHoteTitle,
      AvailabelHotelContent,
      AvailabelHotelItem
} from '../AvailabelHotel/styles';
import { Card, Col } from 'react-bootstrap';
import { HotelChainContainer, HotelChainItem } from './styles';

const hotels = [
      {
            id: 1,
            image: '/images/img/hotellogo/1.png'
      },
      {
            id: 2,
            image: '/images/img/hotellogo/2.png'
      },
      {
            id: 3,
            image: '/images/img/hotellogo/3.png'
      },
      {
            id: 4,
            image: '/images/img/hotellogo/4.png'
      },
      {
            id: 5,
            image: '/images/img/hotellogo/5.png'
      },
      {
            id: 6,
            image: '/images/img/hotellogo/6.png'
      },
      {
            id: 7,
            image: '/images/img/hotellogo/7.png'
      },
      {
            id: 8,
            image: '/images/img/hotellogo/8.png'
      },
      {
            id: 9,
            image: '/images/img/hotellogo/9.png'
      },
      {
            id: 9,
            image: '/images/img/hotellogo/10.png'
      }
];
const HotelChains = ({ title }) => {
      return (
            <>
                  <HotelChainContainer>
                        <AvailabelHoteContainer className="pt-0">
                              <AvailabelHoteTitle className="text-center mb-4">
                                    <h2>{title}</h2>
                              </AvailabelHoteTitle>
                              <AvailabelHotelContent>
                                    <AvailabelHotelItem>
                                          <HotelChainContainer>
                                                {hotels.map((item, index) => (
                                                      <HotelChainItem lg={1}>
                                                            <Card.Img
                                                                  variant="top"
                                                                  src={
                                                                        item.image
                                                                  }
                                                                  alt={
                                                                        item.title
                                                                  }
                                                            />
                                                      </HotelChainItem>
                                                ))}
                                          </HotelChainContainer>
                                    </AvailabelHotelItem>
                              </AvailabelHotelContent>
                        </AvailabelHoteContainer>
                  </HotelChainContainer>
            </>
      );
};

export default HotelChains;
