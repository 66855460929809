import React, { useContext } from 'react';
import BreadcrumbBox from '../../../components/common/BreadcrumbBox';
import { DestinationContainer } from '../../destination_details/styles';
import { Col, Container, Row } from 'react-bootstrap';
import LogoContext from '../../../stores/LogoContext';
import {
      DropDownImage,
      DropdownSubCategory
} from '../../../components/frontend/layout/header/DropDown/styles';
import { Link } from 'react-router-dom';

const ExperienceList = () => {
      const logoCtx = useContext(LogoContext);
      return (
            <>
                  <BreadcrumbBox title="Experience the Adventure" />
                  <DestinationContainer>
                        <Container>
                              <Row className="g-4">
                                    {logoCtx.experiences?.length > 0 &&
                                          logoCtx.experiences.map(
                                                (item, index) => (
                                                      <Col lg={3} key={index}>
                                                            <DropdownSubCategory>
                                                                  <Link
                                                                        to={`/experiences/${item.slug}`}
                                                                  >
                                                                        {item.image && (
                                                                              <DropDownImage width="100%">
                                                                                    <img
                                                                                          src={
                                                                                                item.image
                                                                                          }
                                                                                          alt={
                                                                                                item.title
                                                                                          }
                                                                                    />
                                                                              </DropDownImage>
                                                                        )}
                                                                        {
                                                                              item.title
                                                                        }
                                                                  </Link>
                                                            </DropdownSubCategory>
                                                      </Col>
                                                )
                                          )}
                              </Row>
                        </Container>
                  </DestinationContainer>
            </>
      );
};

export default ExperienceList;
