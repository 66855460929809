import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const PackageDetailContainer = styled.div`
      ${'' /* padding: 40px 0px; */}
      margin-top: 30px;
`;
export const PackageDetailContent = styled.div``;

export const PackageDetailWrapper = styled.div`
      position: sticky;
      top: 115px;
`;
