import React from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import {
      AboutBox,
      AchievementBox,
      FeaturesBox
} from '../../components/frontend/home';
import { AboutContainer } from './styles';

const About = () => {
      return (
            <>
                  <BreadcrumbBox title="About Us" />
                  <AboutContainer>
                        <AboutBox />
                        {/* <AchievementBox /> */}
                        <FeaturesBox />
                  </AboutContainer>
            </>
      );
};

export default About;
