import React from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { PrivacyItem, PrivacyNavbar } from '../privacy/components';
import { Col, Container, Row } from 'react-bootstrap';
import { PrivacyPageContainer } from '../privacy/styles';

const TermsAndCondition = () => {
      return (
            <>
                  <BreadcrumbBox title={'Terms of Service'} />
                  <PrivacyPageContainer>
                        <Container>
                              <Row className="g-0">
                                    <Col lg={2}>
                                          <PrivacyNavbar />
                                    </Col>
                                    <Col lg={10}>
                                          <PrivacyItem title="Terms of Service" />
                                    </Col>
                              </Row>
                        </Container>
                  </PrivacyPageContainer>
            </>
      );
};

export default TermsAndCondition;
