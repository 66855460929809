import { Col, Container } from 'react-bootstrap';
import { styled } from 'styled-components';
import { SocialMediaLinkItem } from '../../../components/SocialMedia/styles';

export const FooterContainer = styled.footer`
      position: relative;
      background-size: initial;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-color: rgba(0, 0, 0, 0);
      background-image: url('/images/img/breadcrumb/footer-bg.png');
      background-attachment: scroll;
      background-color: #f9f9f9 !important;
`;

export const FooterBody = styled(Container)`
      padding: 70px 0px 50px;

      @media only screen and (max-width: 568px) {
            padding-left: 20px;
            padding-right: 20px;

            .g-5 {
                  --bs-gutter-y: 0rem;
                  --bs-gutter-x: 0rem;
            }

            .mobile__footer {
                  width: 100%;
                  flex: auto;
            }
      }
      ${SocialMediaLinkItem} {
            a {
                  i {
                        color: ${(props) => props.theme.primary};
                  }
            }
      }
`;

export const FooterItem = styled(Col)``;

export const FooterHeading = styled.div`
      margin-bottom: 1rem;

      h3,
      h4 {
            color: ${(props) => props.theme.primary};
            font-size: 20px;

            img {
                  width: 150px;
            }
      }
`;

export const FooterContent = styled.div`
      margin-bottom: ${(props) => props.margin && '1.5rem'};
      p {
            color: #666;
            text-align: justify;
            font-size: 14px;
            font-family: 'Nunito Sans', sans-serif;
      }

      a {
            color: #666;
            transition: all 0.3s ease-in;

            &:hover {
                  color: ${(props) => props.theme.secondary};
            }
      }

      ul {
            padding: 0;

            li {
                  list-style-type: none;
                  margin-bottom: 10px;

                  a {
                        color: #666;
                        font-family: 'Rajdhani', sans-serif;
                        transition: all 0.3s ease-in;

                        &:hover {
                              color: ${(props) => props.theme.secondary};
                              margin-left: 10px;
                        }
                  }
            }
      }
`;

export const FooterContact = styled.div`
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.625rem;
      color: #666;

      .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 200, 'GRAD' 0, 'opsz' 24;
      }
      span {
            &:first-child {
                  color: ${(props) => props.theme.white};
            }

            color: #a5a5a5;
      }
`;

export const FooterCredential = styled.div`
      background-color: #00294d;
      font-size: 14px;
      font-family: 'Rajdhani', sans-serif;

      @media only screen and (max-width: 568px) {
            padding-left: 10px;
            padding-right: 10px;
      }
`;

export const FooterCredentialContainer = styled(Container)`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
      color: ${(props) => props.theme.white};

      a,
      span {
            color: ${(props) => props.theme.secondary};
      }

      @media only screen and (max-width: 568px) {
            flex-direction: column;
            justify-content: left;
            align-items: start;
      }
`;

export const FooterImage = styled.div`
      background-size: initial;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-color: rgba(0, 0, 0, 0);
      background-image: url('/images/img/breadcrumb/footer-bg.png');
      background-attachment: scroll;
      min-width: 1482px;
      left: 0px;
      width: 1481px;
      background-attachment: scroll !important;
      position: absolute;
      width: 100%;
      height: 100%;
      background-position: center center;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 0;
`;
export const FooterAbout = styled.div`
      background: ${(props) => props.theme.primary};
      display: inline-block;
      padding: 5px 20px;
      border-radius: 7px;
      margin-bottom: 10px;
      a {
            color: ${(props) => props.theme.white};
            text-align: center;
            font-size: 14px;
      }
`;
