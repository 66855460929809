import { Offcanvas } from 'react-bootstrap';
import styled from 'styled-components';

export const OffcanvasContent = styled(Offcanvas)`
      width: 60% !important;

      @media screen and (max-width: 600px) {
            width: 100% !important;
      }
`;

export const OffcanvasBody = styled(Offcanvas.Body)`
      padding: 0;
      position: relative;

      &::before {
            background-image: url('/images/aircasa.png');
            content: '';
            position: absolute;
            inset: 0px;
            z-index: 0;
            background-repeat: repeat;
            background-size: contain;
            background-color: #22408211;
      }
`;

export const BookBody = styled.div`
      z-index: 1;
      position: sticky;
      padding: 0 2rem;

      .form-control:focus {
            box-shadow: none;
      }
`;

export const BookContent = styled.div`
      padding-top: 10px;
`;

export const BookImage = styled.img`
      position: absolute;
      object-fit: contain;
      width: 200px;
      right: 42px;
      top: -80px;
`;

export const BookButton = styled.button`
      padding: 10px 20px;
      cursor: pointer;
      width: 170px;
      border: 0;
      color: ${(props) => props.theme.white};
      border-radius: 10px;
      background-color: ${(props) => props.theme.primary};
      transition: all 0.3s ease-in-out;
      position: relative;
      overflow: hidden;
      z-index: 999;
      /* background: linear-gradient(254.63deg, #224082 7.98%, rgba(7, 14, 28, 0.92) 114.37%); */

      .spinner-border {
            width: 20px !important;
            height: 20px !important;
      }

      &:before {
            content: '';
            position: absolute;
            height: 100%;
            left: 60%;
            top: 50%;
            width: 600%;
            background-color: ${(props) => props.theme.secondary};
            -webkit-transform: translateX(-50%) translateY(-50%) rotate(60deg);
            transform: translateX(-50%) translateY(-50%) rotate(60deg);
            z-index: -1;
            -webkit-transition: 0.7s ease;
            transition: 0.7s ease;
      }

      &:hover {
            opacity: 0.9;

            &:before {
                  height: 560%;
            }
      }

      @media screen and (max-width: 600px) {
            padding: 8px 16px;
            border-radius: 5px;
            font-size: 16px;
      }
`;

export const BookTitle = styled.div`
      font-size: 32px;
      text-align: center;
      padding: 20px 0 10px;
      /* font-family: 'Great Vibes', cursive; */
      font-weight: 600;
      /* line-height: 24px; */
      display: flex;
      align-items: center;
      justify-content: center;

      div {
            position: relative;
            background-color: white;
            width: fit-content;
            padding: 0px 20px;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      }

      span {
            color: ${(props) => props.theme.primary};
            /* font-family: 'Great Vibes', cursive; */
            font-weight: 600;
      }
`;

export const BookSearchContainer = styled.div`
      display: flex;
      align-items: center;
      gap: 10px;
      border: 1px solid #33333322;
      border-radius: 10px;
      padding: 10px 20px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      margin-bottom: 30px;

      i {
            font-size: 22px;
      }
`;

export const BookSearch = styled.input`
      width: 100%;
      border: 0;
      background-color: transparent;
      &:focus-visible {
            outline: none;
      }
      &:focus {
            border: 0;
      }
`;

export const DestinationItemContainer = styled.div`
      margin-top: 20px;
`;
