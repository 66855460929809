import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { BlogContainer } from '../blogs/styles';
import { Col, Container, Row } from 'react-bootstrap';
import BlogBox from '../../components/frontend/blogs/BlogBox';
import BlogWidget from '../../components/frontend/blogs/BlogWidget';
import axios from 'axios';

const BlogCategory = () => {
      const { categoryId } = useParams();

      const [loading, setLoading] = useState(false);
      const [category, setCategory] = useState([]);
      const [blogs, setBlogs] = useState([]);
      const [categories, setCategories] = useState([]);
      const [relatedBlogs, setRelatedBlogs] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blogs/categories/${categoryId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs(response.data.blogs);
                              setCategory(response.data.category);
                              setCategories(response.data.categories);
                              setRelatedBlogs(response.data.relatedBlogs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [categoryId]);

      return (
            <>
                  <BreadcrumbBox title={`Blogs by ${category.title}`} />
                  <BlogContainer>
                        <Container>
                              <Row>
                                    <Col lg={8}>
                                          <Row className="g-4">
                                                {blogs?.length > 0 ? (
                                                      blogs?.map(
                                                            (blog, index) => (
                                                                  <Col
                                                                        lg={6}
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <BlogBox
                                                                              blog={
                                                                                    blog
                                                                              }
                                                                        />
                                                                  </Col>
                                                            )
                                                      )
                                                ) : (
                                                      <h3>No blogs found</h3>
                                                )}
                                          </Row>
                                    </Col>
                                    <Col lg={4}>
                                          <BlogWidget
                                                blogs={relatedBlogs}
                                                categories={categories}
                                          />
                                    </Col>
                              </Row>
                        </Container>
                  </BlogContainer>
            </>
      );
};

export default BlogCategory;
