import React from 'react';
import {
      BlogDetail,
      BlogDetailCategory,
      BlogDetailItem,
      BlogInfo,
      BlogInfoLink,
      BlogInfoSummary,
      BlogInfoTitle,
      BlogItem,
      BlogItemImage
} from './styles';
import { Link } from 'react-router-dom';

const BlogBox = ({ blog }) => {
      return (
            <>
                  <BlogItem>
                        <BlogItemImage>
                              <img src={blog.image} alt={blog.width} />
                        </BlogItemImage>
                        <BlogDetail>
                              <BlogDetailCategory>
                                    {blog.category}
                              </BlogDetailCategory>
                              <BlogDetailItem>
                                    <i className="bx bx-calendar"></i>
                                    <span>{blog.date}</span>
                              </BlogDetailItem>
                              <BlogDetailItem>
                                    <i className="bx bx-edit-alt"></i>
                                    By <span> Admin</span>
                              </BlogDetailItem>
                        </BlogDetail>
                        <BlogInfo>
                              <BlogInfoTitle>
                                    <Link to={`/blogs/${blog.slug}`}>
                                          {blog.title}
                                    </Link>
                              </BlogInfoTitle>
                              <BlogInfoSummary>{blog.summary}</BlogInfoSummary>
                              <BlogInfoLink to={`/blogs/${blog.slug}`}>
                                    Read More
                                    <span className="material-symbols-outlined">
                                          east
                                    </span>
                              </BlogInfoLink>
                        </BlogInfo>
                  </BlogItem>
            </>
      );
};

export default BlogBox;
