import React from 'react';
import { MoreButtonContainer } from './styles';

const MoreButton = ({ slug, title }) => {
   return (
      <MoreButtonContainer to={slug}>
         <span>{title}</span>
      </MoreButtonContainer>
   );
};

export default MoreButton;
