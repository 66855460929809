import React from 'react';
import {
      ContactDetail,
      ContactDetailIcon,
      ContactDetailInfo,
      ContactDetailParagraph,
      ContactDetailTitle
} from './style';
import { Col } from 'react-bootstrap';

const ContactDetailItem = ({ icon, title, description }) => {
      return (
            <>
                  <Col lg={4}>
                        <ContactDetail>
                              <ContactDetailIcon>
                                    <i className={icon || ''}></i>
                              </ContactDetailIcon>
                              <ContactDetailInfo>
                                    <ContactDetailTitle>
                                          {title}
                                    </ContactDetailTitle>
                                    <ContactDetailParagraph className="text-muted">
                                          {description}
                                    </ContactDetailParagraph>
                              </ContactDetailInfo>
                        </ContactDetail>
                  </Col>
            </>
      );
};

export default ContactDetailItem;
