import styled from 'styled-components';

export const PackagePricingContainer = styled.div`
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      margin-top: 10px;
      padding: 20px;
      margin-bottom: 10px;
      span {
            font-size: 13px;
            text-transform: uppercase;
            color: #999;
            line-height: 3;
      }
      h5 {
            font-size: 24px;
            font-weight: bold;
            color: #333;
      }
      p {
            font-size: 13px;
            color: #888;
      }
`;
