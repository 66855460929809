import React from 'react';
import { DestinationItemTitle } from './style';

const DestinationItem = ({ title, handleChange }) => {
      return (
            <>
                  <DestinationItemTitle onClick={() => handleChange(title)}>
                        {title}
                  </DestinationItemTitle>
            </>
      );
};

export default DestinationItem;
