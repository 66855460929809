import React from 'react';
import { PackageContainer, PackageHeading } from './styles';
import { Container } from 'react-bootstrap';
import SubTitle from '../HomeTitle/SubTitle';
import MainTitle from '../HomeTitle/MainTitle';
import { PackageSlider } from './components';

const PackageBox = () => {
      return (
            <>
                  <PackageContainer>
                        <Container>
                              <PackageHeading>
                                    <SubTitle
                                          subtitle={'Our Packages'}
                                          center="center"
                                          left="-12px"
                                          right="-12px"
                                    />
                                    <MainTitle
                                          title={'Discover Our Best Packages'}
                                          center="100%"
                                    />
                              </PackageHeading>
                              <PackageSlider />
                        </Container>
                  </PackageContainer>
            </>
      );
};

export default PackageBox;
