import styled from 'styled-components';

export const CommonButtonContainer = styled.div`
      a,
      button {
            position: relative;
            display: inline-block;
            padding: 14px 35px;
            background: #224082;
            color: #ffffff;
            border-radius: 7px;
            overflow: hidden;
            border: 0;

            &:before {
                  content: '';
                  position: absolute;
                  height: 0%;
                  left: 50%;
                  top: 50%;
                  width: 600%;
                  background-color: ${(props) => props.theme.secondary};
                  -webkit-transform: translateX(-50%) translateY(-50%)
                        rotate(60deg);
                  transform: translateX(-50%) translateY(-50%) rotate(60deg);
                  z-index: 1;
                  -webkit-transition: 0.7s ease;
                  transition: 0.7s ease;
            }
            &:hover {
                  &:before {
                        height: 560%;
                  }
            }
            span {
                  position: relative;
                  z-index: 2;
            }
      }

      .spinner-border {
            height: 1.2rem !important;
            width: 1.2rem !important;
            border-width: 1px !important;
      }

      @media screen and (max-width: 600px) {
            a,
            button {
                  padding: 8px 20px;
                  color: ${(props) => props.theme.white};
            }
      }
`;
