export const applications = [
      {
            id: '1',
            title: 'Morocco',
            image: '/images/img/domesticdestination/image-7.jpg'
      },
      {
            id: '2',
            title: 'Tanzania',
            image: '/images/img/domesticdestination/image-8.jpg'
      },
      {
            id: '3',
            title: 'Japan',
            image: '/images/img/domesticdestination/image-9.jpg'
      },
      {
            id: '4',
            title: 'Thailand',
            image: '/images/img/domesticdestination/image-10.jpg'
      },
      {
            id: '5',
            title: 'Italy',
            image: '/images/img/domesticdestination/image-11.jpg'
      }
];

export const domesticdestinations = [
      {
            id: '1',
            title: 'Gujrat',
            image: '/images/img/domestic/gujrat.jpg'
      },
      {
            id: '2',
            title: 'Sikkim',
            image: '/images/img/domestic/sikkim.jpg'
      },
      {
            id: '3',
            title: 'kerala',
            image: '/images/img/domestic/kerala.jpg'
      },
      {
            id: '4',
            title: 'Delhi',
            image: '/images/img/domestic/delhi.jpg'
      },
      {
            id: '5',
            title: 'Sikkim',
            image: '/images/img/domestic/sikkim.jpg'
      }
];
