import React, { useState } from 'react';
import {
      BookingContainer,
      BookingTitle,
      BookingWrapper,
      DestinationContent,
      DestinationItem,
      DestinationWrapper
} from './styles';
import { Container, Modal, Row, Spinner } from 'react-bootstrap';
import { AirplaneLanding, AirplaneTakeOff } from '../../../../icons';

import DestinationItemBox from './DestinationItemBox';
import { durations } from '../../../../data/durations';
import MainTitle from '../HomeTitle/MainTitle';
import { ContactForm } from '../../../../frontend/contact/style';
import { FormButton, FormInput, FormTextarea } from '../../../forms';

import $ from 'jquery';
import axios from 'axios';
import toast from 'react-hot-toast';

const BookingBox = () => {
      const [loading, setLoading] = useState(false);
      const [loadForm, setLoadForm] = useState(false);

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [leavingFrom, setLeavingFrom] = useState('');
      const [destination, setDestination] = useState('');
      const [depart, setDepart] = useState('');
      const [duration, setDuration] = useState('');

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [query, setQuery] = useState('');

      const [travelers, setTravelers] = useState([
            {
                  room: '1',
                  children: '0',
                  adult: '1',
                  childrenAges: []
            }
      ]);

      const handleFormButton = () => {
            setLoadForm(true);
            setTimeout(() => {
                  handleShow();
                  setLoadForm(false);
            }, 1500);
      };

      const handleForm = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  leaving_from: leavingFrom,
                  destination: destination,
                  depart: depart,
                  duration: duration,
                  travelers: travelers,
                  name: name,
                  email: email,
                  mobile_number: phoneNumber,
                  query: query
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customize-your-trip`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              $('form').trigger('reset');
                              setLeavingFrom('');
                              setDestination('');
                              setDepart('');
                              setDuration('Select One');
                              setTravelers([
                                    {
                                          room: '1',
                                          children: '0',
                                          adult: '1',
                                          childrenAges: []
                                    }
                              ]);
                              setTimeout(() => {
                                    toast.success(response.data.message);
                                    setShow(false);
                              }, 1500);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <BookingContainer>
                        <BookingTitle>
                              <MainTitle
                                    title={'Customize Your Trip'}
                                    center="100%"
                                    left="-12px"
                                    right="-12px"
                              />
                        </BookingTitle>
                        <Container>
                              <BookingWrapper>
                                    <DestinationItemBox
                                          width="20%"
                                          name="from"
                                          value={leavingFrom}
                                          title="Leaving from"
                                          handleChange={setLeavingFrom}
                                          icon={<AirplaneTakeOff />}
                                    />
                                    <DestinationItemBox
                                          width="20%"
                                          name="to"
                                          value={destination}
                                          title="Destination"
                                          handleChange={setDestination}
                                          icon={<AirplaneLanding />}
                                    />
                                    <DestinationItemBox
                                          width="15%"
                                          name="depart"
                                          value={depart}
                                          handleChange={setDepart}
                                          title="Depart"
                                          type="date"
                                    />
                                    <DestinationItemBox
                                          width="15%"
                                          name="duration"
                                          value={duration}
                                          handleChange={setDuration}
                                          title="Duration"
                                          type="select"
                                          options={durations}
                                    />
                                    <DestinationItemBox
                                          width="15%"
                                          title="Travelers"
                                          name="travelers"
                                          value={travelers}
                                          type="number"
                                          data={travelers}
                                          setData={setTravelers}
                                    />
                                    <DestinationContent width="15%">
                                          <DestinationItem color="#000">
                                                <DestinationWrapper className="d-flex align-items-center justify-content-center">
                                                      <button
                                                            className="text-center text-white w-100"
                                                            onClick={
                                                                  handleFormButton
                                                            }
                                                      >
                                                            Submit
                                                            {loadForm && (
                                                                  <Spinner />
                                                            )}
                                                      </button>
                                                </DestinationWrapper>
                                          </DestinationItem>
                                    </DestinationContent>
                              </BookingWrapper>
                        </Container>
                  </BookingContainer>
                  <Modal show={show} onHide={handleClose} size="lg">
                        <Modal.Header closeButton>
                              <Modal.Title>Enquiry Form</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <ContactForm onSubmit={handleForm}>
                                    <Row className="g-4">
                                          <FormInput
                                                placeholder="Your Name"
                                                classes={12}
                                                handleChange={setName}
                                          />
                                          <FormInput
                                                placeholder="Email Address"
                                                classes={6}
                                                handleChange={setEmail}
                                          />
                                          <FormInput
                                                placeholder="Mobile (Whatsapp No.)"
                                                handleChange={setPhoneNumber}
                                          />
                                          <FormTextarea
                                                placeholder="Your Query"
                                                classes={12}
                                                handleChange={setQuery}
                                          />
                                          <FormButton
                                                classes={12}
                                                title="Send Message"
                                                loading={loading}
                                          />
                                    </Row>
                              </ContactForm>
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default BookingBox;
