import React from 'react';
import { Card } from 'react-bootstrap';
import { ApplicationItemContainer } from './styles';

const ApplicationItem = ({ title, image, link }) => {
      return (
            <>
                  <ApplicationItemContainer to={link}>
                        <Card>
                              <Card.Body>
                                    <Card.Title>{title}</Card.Title>
                              </Card.Body>
                              <Card.Img
                                    src={image}
                                    alt={title}
                                    className="img-fluid"
                              />
                        </Card>
                  </ApplicationItemContainer>
            </>
      );
};

export default ApplicationItem;
